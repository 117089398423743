import React from "react";
import { useNavigate } from "react-router-dom";
import { useTipJarLocalState } from "./state/local-state/useTipjarLocalState";
import BackButton from "../globals/BackButton";
import { OctagonAlert } from "lucide-react";
const EntityJarReport = () => {
  const { activeTipJarReport } = useTipJarLocalState();
  const navigate = useNavigate();

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-KE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(new Date(dateString));
  };

  const getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ""}${lastName?.[0] || ""}`.toUpperCase();
  };

  // Calculate total amount across all distributions
  const totalAmount = activeTipJarReport?.reduce(
    (acc, report) => acc + (report.total_distribution_amount || 0),
    0
  );

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white p-4">
      {/* Header */}
      <div className="mb-6">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
        </div>
      </div>

      {/* Title */}
      <h1 className="text-2xl font-semibold text-[#6CCBD1] mb-4">
        {activeTipJarReport?.[0]?.entity_jar?.name || "TipJAR"}
      </h1>

      {/* Total Amount Card */}
      <div className="bg-[#E9F6FE] rounded-lg p-4 mb-6 flex flex-col items-center">
        <p className="text-gray-600 text-sm mb-1">Total tips disbursed:</p>
        <p className="text-[#6CCBD1] text-2xl font-bold">
          KSH {formatAmount(totalAmount)}
        </p>
      </div>

      {/* Distribution List Grouped by Date */}
      <div className="space-y-6">
        {activeTipJarReport && activeTipJarReport.length > 0 ? (
          <>
            {activeTipJarReport?.map((report, reportIndex) => (
              <div key={reportIndex} className="space-y-3">
                {/* Date and Time Header */}
                <div className="flex justify-between items-baseline">
                  <h3 className="font-medium text-gray-800">
                    {formatDate(report.created_at)}
                  </h3>
                  <span className="text-sm text-[#6CCBD1]">
                    KSH {formatAmount(report.total_distribution_amount)}
                  </span>
                </div>

                {/* Members List */}
                <div className="space-y-3">
                  {report.distribution_matrix.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-4 border rounded-lg"
                      style={{ borderColor: "#F5F5F5" }}
                    >
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-[#6CCBD1] text-white flex items-center justify-center mr-3">
                          {getInitials(
                            item.user.first_name,
                            item.user.last_name
                          )}
                        </div>
                        <div>
                          <p className="font-medium">
                            {`${item.user.first_name} ${item.user.last_name}`}
                          </p>
                          <p className="text-gray-500 text-sm">
                            {`${item.group_points} points - KES ${formatAmount(
                              item.amount
                            )}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="w-full h-[300px] flex flex-col items-center justify-center gap-3">
            <OctagonAlert size={28} color="#6CCBD1" />
            <p className="text-[#6CCBD1] text-lg font-semibold">No data yet</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EntityJarReport;
