import React from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, Outlet } from "react-router-dom";

import loader from "../../assets/Icons/Registration/loader.svg";
import logo from "../../assets/Logos/shukran_logo.svg";
import { resetActions } from "../../state/reducers/reset/reset.actions";
import { forgotPassword } from "../../state/reducers/user/user.thunk";

const Login = () => {
  const userData = useSelector((state) => state.account);

  const resetState = useSelector((state) => state.reset);

  //Form state react hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // navigate function
  let navigate = useNavigate();
  let dispatch = useDispatch();

  // set method that runs when the submit is hit
  const handleFormSubmit = (data) => {
    let phoneNumber = data["phoneNumber"].substring(0);

    let params = {
      phone: `${phoneNumber}`,
    };
    dispatch(forgotPassword(params));
    dispatch(resetActions.setRecoveryPhone(phoneNumber));
    navigate("/password/verify");
  };

  return (
    <div className="flex flex-col text-black m-1 px-6 w-full">
      <img src={logo} alt="Shukran Logo" className="size mx-auto my-6" />
      <h2 className="text-xl mb-2 text-center"> Recover my account </h2>
      <form className="flex flex-col" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex flex-col mt-5">
          <label className="text-grey mt-2 text-sm italic">
            Enter phone number used to register{" "}
          </label>
          <input
            className="border-solid border-b-2 focus:border-b-[#50A2A7] placeholder:text-xl text-black outline-none h-12 w-full"
            type="text"
            placeholder="0700000000"
            {...register("phoneNumber", {
              required: true,
              maxLength: 10,
              pattern:
                /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
            })}
          />
          {errors.phoneNumber && (
            <p className="text-red-600 text-sm mt-2">
              Please cross-check the phone number
            </p>
          )}
        </div>
        {resetState.loading && (
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-10 mx-auto text-sm mt-1`}
          />
        )}
        <div className="flex  flex-col mt-10 ">
          <button
            className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-10 w-full"
            type="submit"
          >
            RECOVER PASSWORD
          </button>
          <div className="footer-reg flex flex-col mt-4">
            <p className="text-xs">
              <Link to="/login" className="underline italic">
                Sign in instead?
              </Link>
              <Outlet />
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
