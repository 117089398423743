import React, { useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import chevron from "../../assets/Icons/tipjar/chevron.svg";
import "./members.css";
import {
  getTipjarGroups,
  getTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import {
  useGetJars,
  useGetEntities,
} from "../TipGroups/state/server-state/useTipGroupsServerState";
import { ChevronRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useTipJarLocalState } from "./state/local-state/useTipjarLocalState";
import { useEffect } from "react";
import BackButton from "../globals/BackButton";
import userStore from "../../store/user.store";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
import { useMutation } from "@tanstack/react-query";
import { BASE_URL } from "../../constants/network";
import { postJson } from "../../utils/fetch.utils";
import { useFetchEntityMembers } from "./state/server-state/useTipJarServerState";
import { useFetchDepartments } from "../Groups/state/server-state/useDepartmentServerState";
import { OctagonIcon } from "lucide-react";
import AddButton from "../globals/AddButton";
import GroupTipJarIcon from "../../assets/Images/group-tipjar-icon.png";
export default function TipjarHome() {
  const [defaultJAR, setDefaultJAR] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };
  const { data, isLoading, error } = useGetEntities();

  if (data === 403) {
    navigate("/login/");
  }

  const {
    setActiveEntityJAR,
    activeEntityJAR,
    setActiveEntity,
    activeEntity,
    setDepartmentList,
    entityDepartments,
    setActiveDepartment,
    entityMembers,
    setEntityMembers,
  } = useTipJarLocalState();

  const [activeDepartments, setActiveDepartments] = useState([]);

  const {
    data: entityJars,
    isLoading: isLoadingEntityJars,
    error: fetchEntityJarError,
    refetch,
  } = useGetJars(activeEntity?.id);

  const {
    data: members,
    isLoading: isLoadingEntityMembers,
    error: entityMembersError,
  } = useFetchEntityMembers(activeEntity?.id);

  useEffect(() => {
    if (members && members.length > 0) {
      setEntityMembers(members);
    }
  }, [members, setEntityMembers, isLoadingEntityMembers]);

  const {
    data: departments,
    isLoading: isLoadingDepartents,
    error: departentError,
  } = useFetchDepartments(activeEntity?.id);

  useEffect(() => {
    if (departments && departments.length > 0) {
      const filtered = departments.filter((el) => el.active);
      setActiveDepartments(filtered);
      setDepartmentList(departments);
    }
  }, [departments, setDepartmentList]);

  const tipjar = useSelector((state) => state.tipjar);

  useEffect(() => {
    if (activeEntity) {
      refetch(activeEntity?.id);
      const defaultJAR = activeEntity.entity_jar || null;
      if (defaultJAR) {
        setDefaultJAR(defaultJAR);
      }
    }
  }, [activeEntity]);

  const { data: tipJarInfo, mutate: tipJarInfocreateMutation } = useMutation({
    mutationKey: "tipjar-creation-details",
    mutationFn: async () => {
      try {
        const resp = await postJson(BASE_URL + "api/v1/tipjar/");
        return resp;
      } catch (error) {
        return error;
      }
    },
  });

  const userAccount = useSelector((state) => state.account);
  function getCount(group) {
    let countof = entityMembers?.filter((memb) => {
      if (memb?.group?.id === group?.id) {
        return true;
      } else {
        return false;
      }
    });
    return countof.length;
  }

  useEffect(() => {
    dispatch(getTipjarMembers({}, userAccount?.userDetails?.token));
  }, [dispatch, userAccount?.userDetails?.token]);

  useEffect(() => {
    if (tipjar?.id) {
      dispatch(getTipjarGroups(tipjar?.id, userAccount?.userDetails?.token));
    }
  }, [dispatch, tipJarInfo?.id, tipjar?.id, userAccount?.userDetails?.token]);

  const styles = {
    backgroundColor: {
      backgroundColor:
        (entityMembers.length < 0 && entityDepartments.length) < 0 &&
        "lightgray",
    },
  };

  const handleEntityJarClick = (entityJar) => {
    setActiveEntityJAR(entityJar);
    navigate("/tipjar/tipjar-group/");
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
        </div>
      </div>

      <div className=" mt-6 w-full" style={{ width: "100%" }}>
        <h2 className="text-3xl font-semibold text-primary-pwa">
          Welcome to TipJAR
        </h2>
        <p className="text-md font-medium text-gray-400">
          Manage members and choose how tips are divided across individuals
        </p>

        <div className="mt-6">
          {entityMembers?.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h5 className="font-semibold">
                {` Individuals (${entityMembers?.length})`}
              </h5>
              <div
                className="flex flex-row bg-[#64C8CE] align-center justify-center rounded cursor-pointer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px",
                }}
                onClick={() => navigate("/tipjar/members/")}
              >
                <p
                  className="mr-2"
                  style={{ fontSize: "14px", color: "white" }}
                >
                  Manage All
                </p>
                <ChevronRight className="w-4 h-4 text-white" color="white" />
              </div>
            </div>
          ) : (
            <h5 className="font-semibold">Individuals </h5>
          )}
          <div className="mt-6 empty h-[250px] overflow-y-scroll">
            {entityMembers?.length > 0 ? (
              <>
                {entityMembers?.map((member, index) => (
                  <>
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        className="border-2 border-[#F5F5F5] w-full h-65 mb-3"
                        style={{
                          height: "65px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          className="w-1/2 px-2"
                        >
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "15px",
                              backgroundColor: "#50A2A7 ",
                            }}
                            className="flex items-center justify-center text-white text-sm"
                          >
                            {`${member?.user?.first_name[0]}${member?.user?.last_name[0]}`}
                          </div>
                          <div className="ml-2">
                            <p
                              style={{ color: "#404B5A", fontSize: "14px" }}
                              className="font-semibold"
                            >
                              {member?.user?.first_name !== "N/A"
                                ? `${member?.user?.first_name} ${member?.user?.last_name}`
                                : member?.user?.phone}
                            </p>
                            <p style={{ color: "#929FB1", fontSize: "10px" }}>
                              {member?.user?.shukran_id &&
                                member?.user?.shukran_id}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          className="w-1/2 px-2"
                        ></div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <>
                {!isLoadingEntityMembers && (
                  <AddButton
                    title="Add Individual"
                    onClick={() => navigate("/tipjar/members")}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="mt-6">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h4 className="font-semibold">
              {`Departments (${activeDepartments?.length || 0})`}{" "}
            </h4>
            <div
              className="flex flex-row bg-[#64C8CE] align-center justify-center rounded  cursor-pointer"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "4px",
              }}
              onClick={() => navigate("/tipjar/groups/")}
            >
              <p className="mr-2" style={{ fontSize: "14px", color: "white" }}>
                Manage All
              </p>

              <ChevronRight className="w-4 h-4 text-white" color="white" />
            </div>
          </div>

          <div className="mt-6 empty">
            {isLoadingDepartents && (
              <div className="w-full flex flex-col gap-2">
                <Skeleton className="w-full h-[90px] rounded" />
                <Skeleton className="w-full h-[90px] rounded" />
                <Skeleton className="w-full h-[90px] rounded" />
              </div>
            )}
            {activeDepartments && departments?.length > 0 ? (
              <div className="flex flex-col w-full h-[250px] overflow-y-scroll">
                {activeDepartments?.map((department, index) => (
                  <div
                    key={department?.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      className="border-2 border-[#F5F5F5] w-full h-65 mb-3"
                      style={{
                        height: "65px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        className="w-1/2 px-2"
                      >
                        <div className="w-[30px] h-[30px] rounded-full bg-gradient-to-b from-yellow-300 to-teal-300 mr-3"></div>
                        <div className="ml-2">
                          <p
                            style={{ color: "#404B5A", fontSize: "14px" }}
                            className="font-semibold"
                          >
                            {department?.name}
                          </p>
                          <p style={{ color: "#929FB1", fontSize: "12px" }}>
                            {department?.members?.length > 0
                              ? `${
                                  department?.members?.length > 1
                                    ? `${department?.members?.length} members`
                                    : `${department?.members?.length} member`
                                }`
                              : "No members"}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                        className="w-1/2 px-2 cursor-pointer"
                      >
                        <p
                          style={{ fontSize: "12px" }}
                          className="text-sm text-white bg-accent px-2 py-1  rounded"
                          onClick={() => {
                            setActiveDepartment(department);
                            navigate(
                              `/tipjar/groups/edit?group=${department?.id}`
                            );
                          }}
                        >
                          Manage
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                {!isLoadingDepartents && (
                  <AddButton
                    title="Create Department"
                    onClick={() => navigate("/tipjar/groups")}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {/* This is the new groups part */}
        <div className="flex items-center justify-between w-full mt-6">
          <h5 className="font-semibold">
            Tip JARS ({entityJars?.data?.data?.length || 0})
          </h5>
          <div
            className="flex flex-row bg-[#64C8CE] align-center justify-center rounded cursor-pointer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "4px",
            }}
            onClick={() => navigate("/tipjar/create-entity-jar/")}
          >
            <p className="mr-2" style={{ fontSize: "14px", color: "white" }}>
              New
            </p>
            <ChevronRight className="w-4 h-4 text-white" color="white" />
          </div>
        </div>

        {isLoadingEntityJars ? (
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="w-full h-[90px] rounded" />
            <Skeleton className="w-full h-[90px] rounded" />
            <Skeleton className="w-full h-[90px] rounded" />
          </div>
        ) : (
          <>
            {!entityJars || entityJars?.data?.data?.length <= 0 ? (
              <div className="flex w-full p-2 items-center justify-center mt-6">
                <AddButton
                  title="Create TipJAR"
                  onClick={() => navigate("/tipjar/create-entity-jar/")}
                />
              </div>
            ) : (
              <>
                <div className="flex w-full flex-col mt-6">
                  <>
                    {entityJars?.data?.data?.map((entityJar, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                          onClick={() => handleEntityJarClick(entityJar)}
                        >
                          <div
                            className="border-2 border-[#F5F5F5] w-full h-65 mb-3"
                            style={{
                              height: "65px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "6px",
                              }}
                              className="w-full px-2"
                            >
                              <div
                                className="w-10 h-10 rounded-full flex items-center justify-center p-1"
                                style={{ backgroundColor: "#472884" }}
                              >
                                <img src={GroupTipJarIcon} alt="icon" />
                              </div>
                              <div className="ml-2 w-full">
                                <p
                                  style={{ color: "#404B5A", fontSize: "14px" }}
                                  className="font-semibold"
                                >
                                  {entityJar?.name}
                                </p>
                                <p
                                  style={{ color: "#929FB1", fontSize: "10px" }}
                                >
                                  {entityJar?.shukran_id &&
                                    entityJar?.shukran_id}
                                </p>
                              </div>
                            </div>

                            {entityJar?.id === defaultJAR?.id && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                                className="w-fit px-2"
                              >
                                <p
                                  style={{ fontSize: "12px" }}
                                  className="text-sm text-white bg-accent px-2 py-1  rounded"
                                >
                                  Main
                                </p>
                              </div>
                            )}
                            {entityJar?.active === false && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                                className="w-fit px-2 "
                              >
                                <p
                                  style={{ fontSize: "12px" }}
                                  className="text-sm text-white  px-2 py-1 bg-red-500 rounded"
                                >
                                  Inactive
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="mb-4 mt-6 hidden">
        <button
          className={`rounded-full py-3 bg-${
            entityMembers?.length > 0 && entityDepartments?.length > 0
              ? "accent"
              : "[#666]"
          } px-3 text-white text-m mx-auto mt-4 w-full`}
          onClick={() => navigate("/tipjar/amount")}
          type="button"
          style={styles.backgroundColor}
        >
          Send Tips
        </button>
      </div>
    </div>
  );
}
