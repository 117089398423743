import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ShukranLogoSacco from "../../assets/Logos/shukran-logo-sacco.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import eightOfTen from "../../assets/Illustrations/sacco/seven_of_ten.svg";
import threeOfFour from "../../assets/Illustrations/sacco/three_of_four.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserData,
  postActivateSacco,
} from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";

const SeventhKinInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({});

  const saccoState = useSelector((state) => state.sacco);
  const userAccount = useSelector((state) => state.account);
  const balanceState = useSelector((state) => {
    return state.balance;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    dispatch(getUserData(params, userAccount.userDetails.token));

    return () => {};
  }, []);

  useEffect(() => {
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    if (balanceState.errorMessage === 403 && !originState) {
      navigate("/");
    }

    return () => {};
  }, [balanceState]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (saccoState.saccoDetails) {
      setValue("guardianPrimaryNumber", saccoState.saccoDetails.guardian_phone);
      setValue(
        "guardianNationalId",
        saccoState.saccoDetails.guardian_national_id
      );
      setValue(
        "guardianFirstName",
        saccoState.saccoDetails.guardian_name !== "null"
          ? saccoState.saccoDetails.guardian_name?.split(" ")[0]
          : ""
      );
      setValue(
        "guardianMiddleName",
        saccoState.saccoDetails.guardian_mn
          ? saccoState.saccoDetails.guardian_mn
          : ""
      );
      setValue(
        "guardianLastName",
        saccoState.saccoDetails.guardian_name?.split(" ")[1]
          ? saccoState.saccoDetails.guardian_name?.split(" ")[1]
          : ""
      );
    }
    return () => {};
  }, []);

  const handleSkip = () => {
    let payload = {
      guardian_name: "N/A",
      guardian_phone: "N/A",
      guardian_national_id: "N/A",
    };
    // let payload = {
    //   guardian_name:
    //     userAccount.loginData?.first_name +
    //     " " +
    //     userAccount.loginData?.last_name,
    //   guardian_phone: userAccount.loginData?.phone,
    //   guardian_national_id: saccoState.saccoDetails.national_id_passport,
    // };
    dispatch(saccoActions.updateSaccoDetails(payload));
    navigate("/ninthForm");
  };

  const handleFirstPersonalInfo = (data) => {
    let payload = {
      guardian_name: data.guardianFirstName + " " + data.guardianLastName,
      guardian_mn: data.guardianMiddleName,
      guardian_phone: data.guardianPrimaryNumber,
      guardian_national_id: data.guardianNationalId,
    };
    dispatch(saccoActions.updateSaccoDetails(payload));
    navigate("/ninthForm");
  };

  const handleSavePartially = (data) => {
    let formDetails = saccoState.saccoDetails;
    let payload = {
      ...formDetails,
      guardian_name: data.guardianFirstName + " " + data.guardianLastName,
      guardian_mn: data.guardianMiddleName,
      guardian_phone: data.guardianPrimaryNumber,
      guardian_national_id: data.guardianNationalId,
    };
    let payloadData = new FormData();
    for (const key in payload) {
      if (
        key === "kra_pin_photo" ||
        key === "passport_photo" ||
        key === "id_passport_photo" ||
        key === "e_signature_photo" ||
        key === "id_passport_back_photo"
      ) {
        // payloadData.append(key, payload[key][0]?.file);
      } else if (
        key.includes("primary_") ||
        key.includes("dob") ||
        key === "guardian_mn" ||
        key === "user_count" ||
        key === "is_under_eighteen"
      ) {
      } else {
        payloadData.append(key, payload[key]);
      }
    }

    dispatch(postActivateSacco(payloadData, userAccount.userDetails.token));
  };

  const SaccoActivationError = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateError(""));
        }}
      >
        X
      </button>
      {saccoState.errorMessage && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {typeof saccoState.errorMessage === "XMLHttpRequest" && (
        <p className="justify-self-center	text-base text-center py-2">
          CORS error
        </p>
      )}
      {saccoState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage.msg}
        </p>
      )}
      {saccoState.errorMessage.status === 400 && (
        <p className="justify-self-center	text-base text-center py-2">
          {Object.entries(saccoState.errorMessage.data.data)[0]}
        </p>
      )}
      {saccoState.errorMessage.status === 500 && (
        <p className="justify-self-center	text-base text-center py-2">
          System downtime. Try again in few moments
        </p>
      )}
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back To Dashboard
      </button>
    </div>
  );

  const SuccessActivation = () => (
    <div className="my-auto px-4 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          navigate("/");
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-28" />
      <p className="justify-self-center	text-lg text-center py-2">
        {saccoState.results.success ? "Submission Successful!" : ""}
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.results.success
          ? "Your activation details have been partially submitted"
          : ""}
      </p>
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          navigate("/");
        }}
      >
        Home
      </button>
    </div>
  );

  return (
    <div
      className={`
        ${saccoState.isLoading ? "relative" : ""}
      ${saccoState.errorMessage ? "relative" : ""}
    ${isLoading ? "relative" : ""} w-screen flex flex-col h-screen`}
    >
      <div
        className={`
         ${saccoState.isLoading ? "blur-sm" : ""}
          ${saccoState.errorMessage ? "blur-sm" : ""}
          ${isLoading ? "blur-sm" : ""}
          mx-auto h-full w-full text-black
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6 flex flex-row items-center justify-between">
          <img
            src={ShukranLogoSacco}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={threeOfFour}
            alt="three of four"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-4 h-full bg-white rounded-t-3xl px-6">
          <h2 className="text-2xl text-primary-green">
            Tell us about your next of Kin
          </h2>
          <p className="text-base my-1">
            Please provide a guardian if the nominee(s) is/are below 18 years
          </p>
          <form
            className="flex flex-col justify-between h-4/5 mt-5"
            onSubmit={handleSubmit(handleFirstPersonalInfo)}
          >
            <div>
              <div className="flex flex-row mt-1">
                <div className="w-1/2 mr-2 ">
                  <label className="mt-2 text-label text-xs">First Name</label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="Joe"
                    autoFocus
                    {...register("guardianFirstName", {
                      required: true,
                    })}
                  />
                  {errors.guardianFirstName && (
                    <p className="text-red-600 text-sm">First Name required</p>
                  )}
                </div>
                <div className="w-1/2">
                  <label className="mt-2 text-label text-xs">
                    Middle Name{" "}
                  </label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="Doe"
                    {...register("guardianMiddleName", {
                      required: true,
                    })}
                  />
                  {errors.guardianMiddleName && (
                    <p className="text-red-600 text-sm">Middle Name required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">Last Name </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="Doe"
                  {...register("guardianLastName")}
                />
                {errors.guardianLastName && (
                  <p className="text-red-600 text-sm	">Last Name required</p>
                )}
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Primary Number{" "}
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="number"
                  placeholder="0700000000"
                  {...register("guardianPrimaryNumber", {
                    maxLength: 10,
                    // pattern:
                    //   /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                  })}
                />
                {errors.guardianPrimaryNumber && (
                  <p className="text-red-600 text-sm">
                    Ensure you have entered in a safaricom line
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">National ID</label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="number"
                  placeholder="3103XXX"
                  {...register("guardianNationalId", {
                    pattern: /^[0-9]{7,8}$|^[A-Z]{1,2}[0-9]{6,8}$/,
                  })}
                />
                {errors.guardianNationalId && (
                  <p className="text-red-600 text-sm">
                    Please enter national ID
                  </p>
                )}
              </div>
              <div className="flex flex-row-reverse mt-3">
                <div
                  className="flex flex-row items-center justify-center bg-primary-green rounded-md py-2 px-2 w-1/2 cursor-pointer"
                  onClick={() => handleSkip()}
                >
                  <span className="text-white text-sm">SKIP</span>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between my-2 pt-3">
              <div
                className={`flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <button type="submit" className="flex flex-row items-center">
                <span className="mr-2">Next</span>
                <img
                  src={ArrowButtonNext}
                  className="w-12 h-12"
                  alt="Next Page"
                  type="button"
                />
              </button>
            </div>
            {/* <button
              className="rounded-full py-2 mb-4 bg-primary-green text-white text-base w-full"
              type="button"
              onClick={handleSubmit(handleSavePartially)}
            >
              SAVE AND CONTINUE LATER
            </button> */}
          </form>
        </div>
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SaccoActivationError />
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SuccessActivation />
      </div>
      <div
        className={`
          ${saccoState.isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="bg-white py-5 w-4/5 rounded-md drop-shadow-2xl flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-20 mx-auto text-sm mt-1 z-10`}
          />
          <span>Processing submitted details</span>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default SeventhKinInfo;
