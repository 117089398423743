import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import logo from "../../assets/Logos/shukran-logo-dark.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import twoOfTen from "../../assets/Illustrations/sacco/two_of_ten.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

const SecondPersonalInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({});

  const navigate = useNavigate();
  const saccoState = useSelector((state) => state.sacco);

  const handleBack = () => {
    navigate(-1);
  };

  const formatUser = (details) => {
    if (details.date_of_birth) {
      return {
        ...details,
        primary_dob_day: details.date_of_birth.split("-")[2],
        primary_dob_month: details.date_of_birth.split("-")[1],
        primary_dob_year: details.date_of_birth.split("-")[0],
      };
    } else {
      return details;
    }
  };

  useEffect(() => {
    if (saccoState.saccoDetails) {
      let formattedUser = formatUser(saccoState.saccoDetails);
      setValue("gender", saccoState.saccoDetails.gender);
      setValue("dobDay", formattedUser.primary_dob_day);
      setValue("dobYear", formattedUser.primary_dob_year);
      setValue("dobMonth", formattedUser.primary_dob_month);
      setValue("kra", saccoState.saccoDetails.kra_pin);
      setValue("country", saccoState.saccoDetails.country);
      setValue("city", saccoState.saccoDetails.county_city);
      setValue("postalAdd", saccoState.saccoDetails.postal_address);
      setValue("postalCode", saccoState.saccoDetails.postal_code);
      setValue("physicalAdd", saccoState.saccoDetails.physical_address);
    }
    return () => {};
  }, []);

  const handleFirstPersonalInfo = (data) => {

    let payload = {
      // marital_status: data.marital,
      country: data.country,
      county_city: data.city,
      postal_address: data.postalAdd?data.postalAdd:'N/A',
      postal_code: data.postalCode?data.postalCode:'N/A',
      physical_address: data.physicalAdd,
      gender: data.gender,
      date_of_birth: data.dobYear + "-" + data.dobMonth + "-" + data.dobDay,
      primary_dob_month: data.dobMonth,
      primary_dob_year: data.dobYear,
      primary_dob_day: data.dobDay,
    };
    dispatch(saccoActions.updateSaccoDetails(payload));
    navigate("/thirdForm");
  };
  return (
    <div
      className={`${isLoading ? "relative" : ""} w-full flex flex-col h-screen`}
    >
      <div
        className={`

          ${isLoading ? "blur-sm" : ""}
          mx-auto h-full w-full text-black
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6 flex flex-row items-center justify-between">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={twoOfTen}
            alt="two of ten"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-4 h-full bg-white rounded-t-3xl px-6">
          <h2 className="text-2xl text-primary-green">
            Tell Us About Yourself
          </h2>
          <p className="text-base my-1">
            This details will be used for your SACCO account and linked to your
            Shukran account.
          </p>
          <form
            className="flex flex-col justify-between h-4/5 mt-5"
            onSubmit={handleSubmit(handleFirstPersonalInfo)}
          >
            <div className="flex flex-col mt-1">
              <label className="mt-2 text-label text-xs">Gender *</label>
              <select
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full py-2 pr-20 text-black outline-none"
                {...register("gender", {
                  required: true,
                })}
                placeholder="Male"
              >
                <option value="female" className="w-10/12">
                  Female
                </option>
                <option value="male" className="w-10/12">
                  Male
                </option>
                <option value="other" className="w-10/12">
                  Other
                </option>
              </select>
              {errors.gender && (
                <p className="text-red-600 text-sm">Please select a gender</p>
              )}
            </div>
            <div className="flex flex-col mt-1">
              <label className="mt-2 text-label text-xs">Date of Birth</label>
              <div className="flex flex-row items-start justify-between">
                <div className="flex flex-col w-1/3">
                  <label className="text-label text-xs my-1">Month *</label>
                  <select
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base p-2 text-black outline-none"
                    {...register("dobMonth", {
                      required: true,
                    })}
                    placeholder="April"
                  >
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                  {errors["dobMonth"] && (
                    <p className="text-red-600 text-sm">
                      Please select a month
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-1/4">
                  <label className="text-label text-xs my-1">Day *</label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base p-2 text-black outline-none"
                    type="number"
                    placeholder="12"
                    {...register("dobDay", {
                      required: true,
                      maxLength: 2,
                      min: 1,
                      max: 31,
                    })}
                  />
                  {errors["dobDay"] && (
                    <p className="text-red-600 text-sm">
                      Ensure you have entered in a date
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-1/4">
                  <label className="text-label text-xs my-1">Year *</label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base p-2 text-black outline-none"
                    type="number"
                    placeholder="1990"
                    {...register("dobYear", {
                      required: true,
                      maxLength: 4,
                      min: 1963,
                      max: 2004,
                    })}
                  />
                  {errors["dobYear"] && (
                    <p className="text-red-600 text-sm">
                      Ensure you have entered in a valid Year
                    </p>
                  )}
                </div>
              </div>
              {errors.dob && (
                <p className="text-red-600 text-sm">select birth date</p>
              )}
            </div>
            {/* <div className="flex flex-col mt-2">
              <label className="mt-2 text-label text-xs">
                Marital Status *
              </label>
              <select
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                {...register("marital", {
                  required: true,
                })}
                placeholder="Single"
              >
                <option value="married">Married</option>
                <option value="single">Single</option>
              </select>
              {errors.marital && (
                <p className="text-red-600 text-sm">
                  Please select a marital status
                </p>
              )}
            </div> */}
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-label text-xs">
                Physical Address *
              </label>
              <input
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                type="text"
                placeholder="Kikoli Rd"
                {...register("physicalAdd", {
                  required: true,
                })}
              />
              {errors.physicalAdd && (
                <p className="text-red-600 text-sm">Enter a physical Address</p>
              )}
            </div>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-label text-xs">
                Country of Residence *
              </label>
              <input
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                type="text"
                placeholder="jd@gmail.com"
                {...register("country", {
                  required: true,
                })}
              />
              {errors.country && (
                <p className="text-red-600 text-sm">
                  Enter country of Residence
                </p>
              )}
            </div>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-label text-xs">County / City *</label>
              <input
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                type="text"
                placeholder="Nairobi"
                {...register("city", {
                  required: true,
                })}
              />
              {errors.city && (
                <p className="text-red-600 text-sm">Enter a City value</p>
              )}
            </div>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-label text-xs">
                Postal Address
              </label>
              <input
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                type="number"
                placeholder="14646"
                {...register("postalAdd", {
                  required: false,
                })}
              />
              {errors.postalAdd && (
                <p className="text-red-600 text-sm">Enter a postal address</p>
              )}
            </div>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-label text-xs">Postal Code</label>
              <input
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                type="number"
                placeholder="00100"
                {...register("postalCode", {
                  required: false,
                })}
              />
              {errors.postalCode && (
                <p className="text-red-600 text-sm">
                  Enter a postal code address
                </p>
              )}
            </div>
            <div className="flex flex-row justify-between py-3">
              <div
                className={`flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <button type="submit" className="flex flex-row items-center">
                <span className="mr-2">Next</span>
                <img
                  src={ArrowButtonNext}
                  className="w-12 h-12"
                  alt="Next Page"
                  type="button"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default SecondPersonalInfo;
