import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import { depositActions } from "../../state/reducers/deposit/deposit.actions";

import DashBoardHeader from "../Dashboard/DashBoardHeader";
import loader from "../../assets/Icons/Registration/loader.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import cross from "../../assets/Icons/Rating/not_found.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import dropDownIcon from "../../assets/Icons/Dashboard/dropdown_icon.svg";

import actions from "../../state/index";
import { getFromSessionStorage } from "../../Services/Storage";
import { loadBalanceResults } from "../../state/reducers/user/user.thunk";

export default function DepositAmount() {
  const depositState = useSelector((state) => state.deposit);

  const depositData = useSelector((state) => state.account.depositedAmount);

  const userData = useSelector((state) => state.account.userDetails);
  const balanceData = useSelector((state) => state.balance);
  const dispatch = useDispatch();

  const { updateDepositedAmount } = bindActionCreators(actions, dispatch);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("amount", depositData?.amount);

    return () => {};
  }, []);

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    // fetchData();

    dispatch(loadBalanceResults(params, userData.token));
  }, [dispatch, userData.token]);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (balanceData.errorMessage === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (balanceData.errorMessage === 403 && !isLoggedIn) {
      navigate("/registration");
    }
    return () => {};
  }, [balanceData, navigate]);

  const handleDeposit = (data) => {
    let payload = {
      shukran_id: userData.shukranId,
      amount: Number(data.amount),
      phone: userData.phoneNumber,
      token: userData.token,
    };
    updateDepositedAmount(payload);
    navigate("/confirmDeposit");
    // dispatch(depositAmount(payload));
  };

  const [openDropdown, setOpenDropdown] = useState(false);

  const deposit = {
    label: "Topup From",
    title: "How much would you like to deposit ?",
  };

  const DepositForm = () => (
    <form
      onSubmit={handleSubmit(handleDeposit)}
      className="bg-white rounded-t-3xl px-8"
    >
      <div className="flex flex-col">
        <h1 className="mt-11 text-base text-black pb-2">{deposit.title}</h1>
        <div className="flex flex-col mt-7 h-14 mb-2">
          <label className="text-subtle text-sm">Amount</label>
          <input
            type="number"
            autoFocus
            className="border-solid border-b-2 border-b-[#ddedee]-600 outline-none w-full text-base py-2 text-grey"
            {...register("amount", {
              required: true,
              validate: {
                checkZero: (val) => val > 0 || "Amount has to be larger than 0",
              },
            })}
          />
        </div>
        {errors.amount?.message && (
          <div className="p-3 mt-2 text-center rounded-md bg-red-300">
            <span className="text-white text-base font-bold">
              {errors.amount.message}
            </span>
          </div>
        )}
        <div className="flex flex-col h-20 mt-7">
          <label className="pb-2 text-sm text-subtle">{deposit.label}</label>
          <div className="flex flex-col">
            <div
              onClick={() => setOpenDropdown(!openDropdown)}
              className="flex flex-row justify-between items-center bg-white mt-3 rounded-full px-4 h-12 focus:outline-primary-pwa outline outline-offset-2 outline-gray-200 outline-1"
            >
              <span className="text-primary text-base">MPESA</span>
              <img src={dropDownIcon} alt="down-arrow" className="block" />
            </div>
            <div
              className={`
            ${openDropdown ? "" : "hidden"}
            flex flex-col mt-2 rounded-lg text-base z-10
            bg-white border-gray-200 border-2
            `}
            >
              <span
                className="px-4 py-2"
                onClick={() => setOpenDropdown(!openDropdown)}
              >
                MPESA
              </span>
            </div>
          </div>
        </div>
      </div>
      <button
        className="rounded-full bg-accent text-white text-xl
        mt-10 py-4 w-full h-12 flex justify-center items-center"
        type="submit"
      >
        NEXT
      </button>
    </form>
  );

  const DepositTransactionCard = () => (
    <div className="  my-auto p-7 shadow bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(depositActions.depositLoadStart({}));
          dispatch(depositActions.checkTransactionError(""));
          // addShukranId({})
          navigate("/");
        }}
      >
        X
      </button>
      {depositState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-10 mx-auto text-sm mt-1`}
        />
      )}
      {depositState.results.success && !depositState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-10" />
      )}
      {(depositState.errorTransactionStatusMessage ||
        depositState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-20" />
      )}
      {depositState.results.success && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.results.msg}
        </p>
      )}
      {depositState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`${depositState.errorMessage.error ? "relative" : ""}
      ${depositState.isLoading ? "relative" : ""}
      ${depositState.results.success ? "relative" : ""}
      ${depositState.errorTransactionStatusMessage ? "relative" : ""}
      w-full flex flex-col`}
    >
      <div
        className={`
       ${depositState.errorMessage.error ? "blur-sm" : ""}
       ${depositState.results.success ? "blur-sm" : ""}
       ${depositState.isLoading ? "blur-sm" : ""}
       ${depositState.errorTransactionStatusMessage ? "blur-sm" : ""}
      flex flex-col w-full bg-imag-dash`}
      >
        <DashBoardHeader navigateLink={""} />
        <DepositForm />
      </div>
    </div>
  );
}
