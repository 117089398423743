import React from "react";
import BackButton from "../globals/BackButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TransactionCard from "../Card/TransactionCard";
import moment from "moment";

function TransactionMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactions = useSelector((state) => {
    // show transactions from latest to earliest
    let stateArr = state?.transactions?.transactions?.results
      ? state.transactions?.transactions?.results?.slice(0, 10)
      : 0;

    const appendAdditionOperator = (amount, currency) => {
      return `+ ${currency} ` + amount;
    };

    const appendSubtractionOperator = (amount, currency) => {
      return `- ${currency} ` + amount;
    };
    const parseType = (type) => {
      let newType = type?.split("_");
      newType = newType.map((type, idx) => {
        return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
      });
      return newType.join(" ");
    };

    let arr = stateArr
      ? stateArr.map((trans) => {
          let dataFormat = {
            id: trans.id,
            amount: trans.amount,
            type: trans.type,
            date: moment(trans.created_at).format("D/M/Y"),
            status: trans.status,
            sender_name: trans.receiver_first_name,
            sender_id: trans.receiver_shukran_id,
            tip_type: trans.tip_type,
            currency: trans.currency,
            exchange_rate: trans.exchange_rate,
          };

          if (trans.type === "Tip") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount, trans.currency),
              sender_name: trans.sender_first_name,
              receiver_shukran_id: trans.receiver_shukran_id,
              currency: trans?.currency,
              exchange_rate: trans?.exchange_rate,
            };
            return dataFormat;
          }
          if (trans.type === "tip_match") {
            dataFormat = {
              ...dataFormat,
              amount: appendAdditionOperator(trans.amount, trans.currency),
              sender_name: trans.sender_first_name,
              receiver_shukran_id: trans.receiver_shukran_id,
              currency: trans.currency,
              exchange_rate: trans.exchange_rate,
            };
            return dataFormat;
          }

          if (trans.type === "sacco_tip") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount, trans.currency),
              sender_name: trans.sender_first_name,
              sender_id: trans.recipient_id,
              currency: trans.currency,
            };
            return dataFormat;
          }

          if (
            trans.type === "pay_membership_fee" ||
            trans.type === "pay_share_capital" ||
            trans.type === "contribution"
          ) {
            dataFormat = {
              ...dataFormat,
              sender_name: parseType(trans.type),
            };
            return dataFormat;
          }
          if (trans.type === "access_transfer") {
            dataFormat = {
              ...dataFormat,
              sender_name: parseType(trans.type),
              amount: appendSubtractionOperator(trans.amount),
            };
            return dataFormat;
          }
          if (trans.type === "sacco_transfer") {
            dataFormat = {
              ...dataFormat,
              sender_name: parseType(trans.type),
              amount: appendAdditionOperator(trans.amount),
            };
            return dataFormat;
          }
          if (trans.type === "Credit") {
            dataFormat = {
              ...dataFormat,
              amount: appendAdditionOperator(trans.amount),
              sender_name: "Deposit",
            };
            return dataFormat;
          }

          if (trans.type === "Debit") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount),
              sender_name: "Withdrawal",
            };
            return dataFormat;
          }

          return dataFormat;
        })
      : [];
    const ids = arr.map((o) => o.id);
    arr = arr.filter(({ id }, index) => !ids.includes(id, index + 1));
    arr.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
    return arr;
  });

  return (
    <div className="w-full overflow-scroll p-3  h-screen bg-[white]">
      <div className="p-2">
        <div>
          <BackButton
            onPress={() => {
              navigate(-1);
            }}
          />

          <div className="mt-4">
            {transactions &&
              transactions?.map((transaction) => (
                <TransactionCard
                  key={transaction.id}
                  transaction={transaction}
                  isHistory={false}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionMain;
