import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ShukranLogoSacco from "../../assets/Logos/shukran-logo-sacco.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  anonActivateSacco,
  postActivateSacco,
} from "../../state/reducers/user/user.thunk";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

const TenthDisclaimer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const saccoState = useSelector((state) => state.sacco);
  const userAccount = useSelector((state) => state.account);
  const userInfo = useSelector((state) => state.balance.userInfo);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (saccoState.errorMessage && userInfo.sacco_stage === "new") {
      dispatch(saccoActions.getBalanceError(""));
    }
    return () => {};
  }, [saccoState.errorMessage, userInfo, dispatch]);

  const handleSubmitName = (data) => {
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    let formDetails = saccoState.saccoDetails;
    formDetails = {
      ...formDetails,
      nominees: JSON.stringify(formDetails.nominees),
      kyc_stage: 9,
      date_of_birth: formDetails.nominees[0].date_of_birth,
    };

    let payloadData = new FormData();
    for (const key in formDetails) {
      if (
        key === "kra_pin_photo" ||
        key === "passport_photo" ||
        key === "id_passport_photo" ||
        key === "e_signature_photo" ||
        key === "id_passport_back_photo"
      ) {
        payloadData.append(key, formDetails[key][0]?.file);
      } else if (
        key.includes("primary_") ||
        key.includes("dob") ||
        key === "guardian_mn" ||
        key === "user_count" ||
        key === "is_under_eighteen"
      ) {
      } else {
        payloadData.append(key, formDetails[key]);
      }
    }

    if (originState) {
      dispatch(anonActivateSacco(payloadData));
    } else {
      dispatch(postActivateSacco(payloadData, userAccount.userDetails.token));
    }
  };

  const SaccoActivationError = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateError(""));
        }}
      >
        X
      </button>
      {saccoState?.errorMessage && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {typeof saccoState.errorMessage === "XMLHttpRequest" && (
        <p className="justify-self-center	text-base text-center py-2">
          CORS error
        </p>
      )}
      {saccoState.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage?.msg}
        </p>
      )}
      {saccoState.errorMessage?.status === 400 && (
        <p className="justify-self-center	text-base text-center py-2">
          {Object.entries(saccoState.errorMessage?.data?.data)[0]}
        </p>
      )}
      {saccoState.errorMessage.status === 500 && (
        <p className="justify-self-center	text-base text-center py-2">
          System downtime. Try again in few moments
        </p>
      )}
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          let originState = localStorage.getItem("origin")
            ? localStorage.getItem("origin")
            : "false";
          originState = JSON.parse(originState);
          if (originState) {
            navigate("/registration");
          } else {
            navigate("/");
          }
        }}
      >
        Go Back To Dashboard
      </button>
    </div>
  );

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    if (saccoState.errorMessage.status === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (saccoState.errorMessage.status === 403 && !isLoggedIn) {
      navigate("/splashScreen");
    }
    return () => {};
  }, [saccoState, navigate]);

  // Component - Sub components
  const SuccessActivation = () => (
    <div className="my-auto px-4 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          let originState = localStorage.getItem("origin")
            ? localStorage.getItem("origin")
            : "false";
          originState = JSON.parse(originState);
          if (originState) {
            navigate("/registration");
          } else {
            navigate("/");
          }
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-28" />
      <p className="justify-self-center	text-lg text-center py-2">
        {saccoState.results.success ? "Submission Successful!" : ""}
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.results.success
          ? "Your activation has been sent for approval. You will be contact in 2 to 5 business days"
          : ""}
      </p>
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          let originState = localStorage.getItem("origin")
            ? localStorage.getItem("origin")
            : "false";
          originState = JSON.parse(originState);
          if (originState) {
            navigate("/registration");
          } else {
            navigate("/");
          }
        }}
      >
        Home
      </button>
    </div>
  );

  return (
    <div
      className={`
      ${saccoState.isLoading ? "relative" : ""}
      ${saccoState.errorMessage ? "relative" : ""}
      w-full flex flex-col h-screen`}
    >
      <div
        className={`
        ${saccoState.isLoading ? "blur-sm" : ""}
        ${saccoState.errorMessage ? "blur-sm" : ""}
          ${isLoading ? "blur-sm" : ""}
          mx-auto h-full w-full text-black
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6">
          <img
            src={ShukranLogoSacco}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-4 h-full bg-white rounded-t-3xl px-6 pb-10">
          <form
            className="flex flex-col justify-between h-fit mt-5"
            onSubmit={handleSubmit(handleSubmitName)}
          >
            <div className="flex flex-col">
              <h2 className="text-primary-green text-2xl">
                Submit Signature Document
              </h2>
              <span className="text-base mt-1">
                I declare that all the particulars given by me are true. I
                confirm that I have read the terms and conditions governing the
                opening, operating and closure of membership of Shukran Savings
                and Credit Cooperative Society and agree to be bound by them. I
                further unequivocally consent that my personal data, collected
                in connection with such terms and conditions , may from time to
                time be used and disclosed for such lawful purposes and to such
                persons as may be in accordance with the Shukran Sacco’s
                prevailing Privacy Policy, and the relevant laws, as amended
                from time to time. For detailed Terms and Conditions, visit (
                <a href="/">Data Privacy url</a>)
              </span>
              <span className="mt-4 mb-4">
                Enter your full name to agree to the above
              </span>
              <div className="flex flex-col">
                <label className="mt-2 text-label text-xs">Name</label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="Joey"
                  {...register("disclaimerName", {
                    required: true,
                  })}
                />
                {errors.disclaimerName && (
                  <p className="text-red-600 text-sm">Enter a name please</p>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between my-2 pt-3">
              <div
                className={`flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <></>
              {/* <button type="submit" className="flex flex-row items-center">
                <span className="mr-2">Next</span>
                <img
                  src={ArrowButtonNext}
                  className="w-12 h-12"
                  alt="Next Page"
                  type="button"
                />
              </button> */}
            </div>
            <button
              className="rounded-full py-2 mb-4 bg-primary-green text-white text-base w-full"
              type="submit"
            >
              SAVE AND COMPLETE
            </button>
          </form>
        </div>
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SaccoActivationError />
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SuccessActivation />
      </div>
      <div
        className={`
          ${saccoState.isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="bg-white py-5 w-4/5 rounded-md drop-shadow-2xl flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-20 mx-auto text-sm mt-1 z-10`}
          />
          <span>Processing submitted details</span>
        </div>
      </div>
    </div>
  );
};

export default TenthDisclaimer;
