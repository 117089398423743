import {
  useQuery,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";
import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../utils/constructApiUrl";
import useAxios from "../../shared/useAxios";

const searchShukranMembers = async (
  $http: AxiosInstance,
  options: any
): Promise<any> => {
  const { searchQuery } = options;

  const apiUrl = constructApiUrl(`/accounts/auth/search`, {
    shukran_id: searchQuery || "",
  });
  try {
    const response = await $http.get(apiUrl);

    return response;
  } catch (error) {
    throw new Error("Search members failed");
  }
};

export const useSearchShukranMembers = (options: { searchQuery: string }) => {
  const $http = useAxios();
  return useQuery<AxiosResponse<any, any>, unknown>({
    queryKey: ["SEARCH SHUKRAN MEMBERS", options],
    queryFn: () => searchShukranMembers($http, options),
  });
};
