import React from "react";
import { Card } from "../../components-shadcn/ui/card";
import { ChevronRight } from "lucide-react";

const TipOption = ({
  icon,
  title,
  image,
  subtitle,
  strokeColor,
  fillColor,
  handleClick,
  entityJAR,
  type,
}) => {
  return (
    <>
      {type === "entity" ? (
        <Card
          className="flex items-center justify-between p-3"
          style={{ backgroundColor: fillColor, borderColor: strokeColor }}
          onClick={() => handleClick(type, null)}
        >
          <div className="flex items-center">
            <div
              className="w-10 h-10 rounded-full flex items-center justify-center mr-3"
              style={{ backgroundColor: strokeColor }}
            >
              {/* {icon} */}
              <img src={image} alt="icon" />
            </div>
            <div>
              <h4 className="font-medium text-sm">{title}</h4>
              <p className={`text-${[strokeColor]} font-semibold text-lg`}>
                {subtitle}
              </p>
            </div>
          </div>
          <ChevronRight size={24} className="text-gray-400" />
        </Card>
      ) : (
        <Card
          className="flex items-center justify-between p-3"
          style={{ backgroundColor: fillColor, borderColor: strokeColor }}
          onClick={() => handleClick(type, entityJAR)}
        >
          <div className="flex items-center">
            <div
              className="w-10 h-10 rounded-full flex items-center justify-center mr-3"
              style={{ backgroundColor: strokeColor }}
            >
              {/* {icon} */}
              <img src={image} alt="icon" />
            </div>
            <div>
              <h4 className="font-medium text-sm">{title}</h4>
              <p className={`text-${[strokeColor]} font-semibold text-lg`}>
                {subtitle}
              </p>
            </div>
          </div>

          <ChevronRight size={24} className="text-gray-400" />
        </Card>
      )}
    </>
  );
};

export default TipOption;
