import React, { useEffect, useState } from "react";
import { Input } from "../../../components-shadcn/ui/input";
import { Button } from "../../../components-shadcn/ui/button";
import { Card, CardContent } from "../../../components-shadcn/ui/card";
import { CircleUserRound, CirclePlus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../globals/BackButton";
import loader from "../../../assets/Icons/Registration/loader.svg";
import { toast } from "sonner";
import { useCreateTipJarGroup } from "../state/server-state/useTipJarServerState";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import { useGetMainTipJarInfo } from "../state/server-state/useTipJarServerState";
import { Skeleton } from "../../../components-shadcn/ui/skeleton";
import { useGetEntities } from "../../TipGroups/state/server-state/useTipGroupsServerState";
import { useGetJars } from "../../TipGroups/state/server-state/useTipGroupsServerState";

const CreateTipJarGroups = () => {
  const [groupName, setGroupName] = useState("");

  const {
    setActiveEntity,
    setMainTipJar,
    activeEntity,
    mainTipJar,
    setActiveEntityJAR,
    activeEntityJAR,
    entityDepartments,
  } = useTipJarLocalState();
  const { data, isLoading, error } = useGetEntities();
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const createTipJarGroups = useCreateTipJarGroup();
  const isCreatingTipJarGroup = createTipJarGroups.isLoading;

  const {
    data: entityJars,
    isLoading: isLoadingEntityJars,
    error: fetchEntityJarError,
    refetch,
  } = useGetJars(activeEntity?.id);

  useEffect(() => {
    if (data) {
      const karisia = data?.data?.results?.find(
        (el) => el.name === "Karisia Restaurant"
      );
      setActiveEntity(karisia);
    }
  }, [data, setActiveEntity]);

  const {
    data: mainTipJARInfo,
    isLoading: isLoadingMainTipJARInfo,
    error: mainTipJarInfo,
  } = useGetMainTipJarInfo();

  useEffect(() => {
    if (mainTipJARInfo) {
      setMainTipJar(mainTipJARInfo);
    }
  }, [mainTipJARInfo, setMainTipJar]);

  useEffect(() => {
    if (mainTipJARInfo) {
      const lowerCaseText = searchTerm.toLocaleLowerCase();
      const filteresMembers = mainTipJARInfo?.data?.data?.members?.filter(
        (el) => {
          return (
            el.user.first_name.toLowerCase().includes(lowerCaseText) ||
            el.user.last_name.toLowerCase().includes(lowerCaseText)
          );
        }
      );
      setMembers(filteresMembers);
    }
  }, [mainTipJARInfo, searchTerm]);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handleCreateGroup = async (payload) => {
    try {
      const response = await createTipJarGroups.mutateAsync(payload);

      toast.success("Group create succesfully");
      navigate("/tipjar/tipjar-group/");
    } catch (error) {
      toast.error("Error creating group");
    }
  };

  const handleNavigateToTipJarGroup = () => {
    if (!groupName || groupName.length <= 0) {
      toast.error(`Enter group name`);
      return;
    }
    if (!mainTipJARInfo) {
      toast.error(`Tip jar information not found`);
      return;
    }

    //JAR IDS GOTTEN FROM ENITY JARS LIST
    const payload = {
      name: groupName,
      jar_id: activeEntityJAR.id,
      disbursement_points: 10,
    };
    handleCreateGroup(payload);
  };

  return (
    <div
      className={` ${
        isCreatingTipJarGroup
          ? "relative blur-sm"
          : "flex flex-col px-3 w-full h-screen px-2 "
      }`}
    >
      <div className="w-full p-0">
        <BackButton
          onPress={() => {
            handleBack();
          }}
        />
      </div>

      <h1 className="text-2xl font-bold mb-2 mt-6">New group</h1>
      <p className="text-gray-500 mb-4">
        Create a unique group to manage events and activities
      </p>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Group name
          </label>
          <Input
            placeholder="Enter team name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className="h-[55px] border-2 border-[#6CCBD1] rounded-md p-2 w-full text-gray-400 placeholder-gray-300 focus:ring-2 focus:ring-[#6CCBD1] focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Assign Tour Manager
          </label>
          <div className="relative">
            <Input
              placeholder="Search name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="h-[55px] border-2 border-[#6CCBD1] rounded-full p-2 w-full text-gray-400 placeholder-gray-300 focus:ring-2 focus:ring-[#6CCBD1] focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <p className="text-sm font-medium text-gray-700 mb-2">
            All Individuals ({members?.length || 0})
          </p>
          {isLoadingMainTipJARInfo ? (
            <div className="w-full flex flex-col gap-2">
              <Skeleton className="w-full h-[80px] rounded" />
              <Skeleton className="w-full h-[80px] rounded" />
              <Skeleton className="w-full h-[80px] rounded" />
            </div>
          ) : (
            <Card>
              <CardContent className="p-0">
                {members?.map((individual) => (
                  <div
                    key={individual.id}
                    className="flex items-center justify-between p-3 border-b last:border-b-0"
                  >
                    <div className="flex items-center gap-1">
                      <CircleUserRound size={24} />
                      <div>
                        <p className="font-medium text-gray-500">
                          {`${individual?.user?.first_name} ${individual?.user?.last_name}`}
                        </p>
                        <p className="text-sm text-gray-500">
                          {individual?.user?.shukran_id}
                        </p>
                      </div>
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      className="bg-[#6CCBD1] hover:bg-teal-500 text-white border-none"
                    >
                      <div className="flex flex-row items-center gap-2">
                        <p className="text-sm text-white">Assign</p>
                        <CirclePlus size={14} color="white" />
                      </div>
                    </Button>
                  </div>
                ))}
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      <Button
        className="w-full mt-6 bg-[#F2C773] text-white rounded-full h-[60px] font-semibold"
        onClick={() => handleNavigateToTipJarGroup()}
      >
        CREATE GROUP
      </Button>

      <div
        className={`${isCreatingTipJarGroup ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full p-2
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default CreateTipJarGroups;
