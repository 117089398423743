import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components-shadcn/ui/button";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import BackButton from "../globals/BackButton";
import { useTipGroupsLocalState } from "./state/local-state/useTipGroupsLocalState";
import Loader from "../globals/Loader";

import confetti from "../../assets/Icons/Tipping/confetti.gif";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import config from "../../config/environments";

const CheckoutForm = ({ setIsSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const result = await stripe.confirmPayment({
      elements: elements,
      confirmParams: {
        return_url: "https://app.shukran.co/download",
      },
    });
    if (result.error) {
      setError(result.error.message);
      toast.error(`${result.error.message}`);
      setProcessing(false);
      setIsSuccess(false);
    } else {
      toast.success("Payment succesfull");
      setProcessing(false);
      setIsSuccess(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="bg-white p-4 rounded-lg shadow">
        <PaymentElement
          options={{
            supportedPaymentMethods: ["card"],
          }}
        />
      </div>
      {error && <div className="text-red-500">{error}</div>}
      <Button
        type="submit"
        disabled={!stripe || processing}
        className="w-full bg-black text-white rounded-full py-2"
      >
        {processing ? "Processing..." : "Pay"}
      </Button>
    </form>
  );
};

const StripePaymentForm = () => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const _stripeKey = config.stripeKeys;
  const stripeKey = loadStripe(_stripeKey);

  useEffect(() => {
    let timeout;
    if (isSuccess === true) {
      timeout = setTimeout(() => {
        // navigate("/download");
        <SuccessModal setIsSuccess={setIsSuccess} />;
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [isSuccess]);

  const handleBack = () => {
    navigate(-1);
  };
  const { initialStipeDetails } = useTipGroupsLocalState();
  const clientSecret = initialStipeDetails?.key || null;

  return (
    <div className="flex flex-col h-screen bg-white p-4">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>
      {initialStipeDetails?.key?.split("").length > 0 && (
        <div className="max-w-md mx-auto p-4">
          <h2 className="text-2xl font-bold mb-4 text-[#50A2A7]">
            Please enter your card details to tip using Stripe
          </h2>
          <Elements stripe={stripeKey} options={{ clientSecret }}>
            <CheckoutForm setIsSuccess={setIsSuccess} />
          </Elements>
          <div className="mt-4 text-center text-sm text-gray-500">
            <p>Guaranteed safe & secure checkout</p>
            <p>Powered by Stripe</p>
          </div>
        </div>
      )}
      {isSuccess && <SuccessModal setIsSuccess={setIsSuccess} />}
    </div>
  );
};

const SuccessModal = ({ setIsSuccess }) => {
  const navigate = useNavigate();
  return (
    <div className="w-screen absolute h-screen backdrop-blur-sm flex items-center justify-center">
      <div className="my-auto blur-none px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            setIsSuccess(false);
            // navigate("/download");
            // addShukranId({})
          }}
        >
          X
        </button>

        <div className="flex flex-row justify-between">
          <img src={confetti} alt="confetti" className="h-28 -scale-x-100" />
          <img
            src={checkCircle}
            alt="error-img"
            className="h-24 left-1/3 absolute"
          />
          <img src={confetti} alt="confetti" className="h-28" />
        </div>

        {/* Check status of express tipping error responses */}
      </div>
    </div>
  );
};

export default StripePaymentForm;
