import React, { useState, useEffect } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import WastingGlass from "../../assets/Icons/wasting_glass.svg";

import { useSelector } from "react-redux";

import back from "../../assets/Icons/Rating/back_icon.svg";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { checkSaccoTipStatus } from "../../state/reducers/user/user.thunk";

export default function CreditCard() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  let searchState = useSelector((state) => state.search);
  let userState = useSelector((state) => state.account.userDetails);

  const tipData = useSelector((state) => state.tipData);

  const tippingState = useSelector((state) => state.tipping);
  const [searchParams] = useSearchParams();

  // https://beta.shukran.co/paystack?transaction_id=1918&trxref=gfn3ykycl0&reference=gfn3ykycl0

  useEffect(() => {
    let param = searchParams.get("transaction_id");
    // let param = searchParams.get("OrderMerchantReference");
    let cachedToken = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    // SHUKSTAGING
    // SHUKPROD
    // if (param.includes("SHUKSTAGING")) {
    //   param = param.replace("SHUKSTAGING", "");
    // } else {
    //   param = param.replace("SHUKPROD", "");
    // }
    dispatch(checkSaccoTipStatus(param, cachedToken));
    return () => {};
  }, []);

  useEffect(() => {
    if (tippingState.navigate.navigate === "/") {
      navigate("/");
      dispatch(balanceActions.checkTippingRedirect(""));
    }
    return () => {};
  }, [tippingState, navigate, dispatch]);

  useEffect(() => {
    if (tippingState.errorMessage === 403) {
      navigate("/login");
      dispatch(balanceActions.tippingError(""));
    }
    return () => {};
  }, [searchState, tippingState, navigate, dispatch]);

  const TippingTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {!tippingState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(balanceActions.tippingSuccess({}));
            dispatch(balanceActions.checkTippingSuccess(""));
            // addShukranId({})
            navigate("/");
          }}
        >
          X
        </button>
      )}
      {tippingState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
      )}
      {tippingState.tippingResponse.success &&
        !tippingState.isTransactionLoading && (
          <img src={checkCircle} alt="error-img" className="h-24" />
        )}
      {tippingState.tippingResponse.success &&
        !tippingState.isTransactionLoading && (
          <p className="justify-self-center	text-base text-center py-2 px-12">
            {tippingState.tippingResponse.msg}
          </p>
        )}
      {tippingState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          Confirming card payment...
        </p>
      )}

      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState?.errorMessage?.msg}
        </p>
      )}
      {tippingState?.errorMessage?.detail && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState?.errorMessage?.detail}
        </p>
      )}
      {/* Check status of express tipping error responses */}

      {tippingState.errorTransactionStatusMessage.msg && (
        <div className="flex flex-col items-center">
          <img src={WastingGlass} alt="time clock" className="w-20 h-20" />
          <p className="justify-self-center	text-base text-center py-2 px-12">
            Tip transaction pending. Kindly be patient as we process your tip
          </p>
        </div>
      )}
      {tippingState.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.status === 500 && (
        <div className="flex flex-col items-center">
          <img src={WastingGlass} alt="time clock" className="w-20 h-20" />
          <p className="justify-self-center	text-base text-center py-2 px-12">
            System error
          </p>{" "}
        </div>
      )}
      {tippingState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );
  return (
    <div
      className={`
        ${tippingState.errorMessage.error ? "relative" : ""}
        ${tippingState.tippingResponse.success ? "relative" : ""}
        ${tippingState.isLoading ? "relative" : ""}
        ${tippingState.errorTransactionStatusMessage ? "relative" : ""}
         w-full flex flex-col`}
    >
      <div
        className={` ${tippingState.errorMessage.error ? "blur-sm" : ""}
          ${tippingState.tippingResponse.success ? "blur-sm" : ""}
          ${tippingState.errorTransactionStatusMessage ? "blur-sm" : ""}
          ${tippingState.isLoading ? "blur-sm" : ""}
          flex flex-col px-3 w-full h-screen`}
      >
        <div className="flex flex-row items-center mt-9">
          <Link to={"/paymentOpt"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="mx-3 mt-11 mb-5 h-full">
          <span className="text-3xl font-semibold text-primary-pwa">
            Confirmation of card payment status?
          </span>
        </div>
      </div>
      <div
        className={`${
          tippingState.isLoading || tippingState.tippingResponse.success
            ? "absolute blur-none"
            : "hidden"
        }
        flex justify-center items-center w-full h-full
        `}
      >
        <TippingTransactionCard />
      </div>
      {/* <div
        className={`${
          tippingState.isLoading || tippingState.tippingResponse.success
            ? "absolute blur-none"
            : "hidden"
        }
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div> */}
    </div>
  );
}
