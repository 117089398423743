import { useTipJarLocalState } from "../../../src/components/Tipjar/state/local-state/useTipjarLocalState";
import { useFetchTipJarReports } from "../../../src/components/Tipjar/state/server-state/useTipJarServerState";
import { Card } from "../../components-shadcn/ui/card";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
import React, { useState, useEffect } from "react";
import { ChevronDown, ChevronUp, Send } from "lucide-react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../src/hooks/useWindowDimensions";

const DashboardReports = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    activeEntity,
    tipjarReport,
    setActiveTipJarReport,
    setTipJarReport,
    activeTipJarReport,
  } = useTipJarLocalState();
  const [reports, setReports] = useState(tipjarReport || []);
  const { width, height } = useWindowDimensions();

  const fetchReports = useFetchTipJarReports();
  const isFetchingReports = fetchReports.isLoading;
  const navigate = useNavigate();

  const handleFetchReports = async () => {
    try {
      const response = await fetchReports.mutateAsync({
        entity_id: activeEntity.id,
      });
      setReports(response.data || []);
      setTipJarReport(response.data || []);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!activeEntity || !activeEntity?.id) return;
    handleFetchReports();
  }, [activeEntity]);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
        .format(date)
        .toLowerCase();
    } catch (error) {
      return "";
    }
  };

  const handleReportClick = (reportData) => {
    setActiveTipJarReport([reportData]);
    navigate("/tipjar/report/");
  };

  // Get notifications to display based on expanded state
  const displayedReports = isExpanded ? reports : reports.slice(0, 1);

  return (
    <div
      className={`${
        height > 800 && "fixed bottom-0"
      }   w-full max-w-[600px] bg-transparent bg-white transition-all duration-300 ease-in-out rounded-tl-lg rounded-tr-lg
          ${isExpanded ? "h-[40vh]" : "h-auto"}`}
      style={{
        background: `${
          isExpanded ? "rgba(255, 255, 255, 0.9)" : "transparent"
        }`,
      }}
    >
      {/* Header Section */}
      <div className="flex justify-between items-center px-4 py-3 border-b">
        <p className="text-sm font-semibold text-gray-700">HIGHLIGHTS</p>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center text-[#6CCBD1] text-sm font-medium"
        >
          SHOW {isExpanded ? "LESS" : "MORE"}
          {isExpanded ? (
            <ChevronDown className="ml-1 h-4 w-4" />
          ) : (
            <ChevronUp className="ml-1 h-4 w-4" />
          )}
        </button>
      </div>

      {/* Notifications Container */}
      <div
        className={`overflow-y-auto px-4 transition-all duration-300
            ${
              isExpanded
                ? "max-h-[calc(50vh-60px)]"
                : "max-h-[120px] overflow-hidden"
            }`}
      >
        {isFetchingReports ? (
          <div className="flex justify-center items-center h-24">
            <span className="text-gray-500">Loading...</span>
          </div>
        ) : reports && reports.length > 0 ? (
          displayedReports.map((report, index) => (
            <Card
              key={index}
              className="my-3 bg-[#F3FAF9] border-none shadow hover:shadow-md transition-shadow duration-200"
              onClick={() => handleReportClick(report)}
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex items-center space-x-3">
                  <div className="w-8 h-8 rounded-full flex items-center justify-center">
                    <Send
                      size={16}
                      className="text-white transform rotate-45"
                      color="orange"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-900 text-sm sm:text-base">
                      Successful Distribution
                    </span>
                    <span className="text-gray-500 text-xs sm:text-sm">
                      {report.entity_jar.name}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  <span className="font-medium text-gray-900 text-sm sm:text-base">
                    Ksh {report.total_distribution_amount.toLocaleString()}
                  </span>
                  <span className="text-gray-500 text-xs sm:text-sm">
                    {formatDate(report.created_at)}
                  </span>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <div className="flex justify-center items-center h-24">
            <span className="text-gray-500">No notifications</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardReports;
