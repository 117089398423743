import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import cross from "../../assets/Icons/Tipping/cross.svg";
import { useForm } from "react-hook-form";
import actions from "../../state/index";
import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";

import back from "../../assets/Icons/Rating/back_icon.svg";
import {
  generateTokenOtp,
  sendTip,
} from "../../state/reducers/user/user.thunk";
import { searchActions } from "../../state/reducers/search/search.actions";
import { balanceActions } from "../../state/reducers/user/user.actions";
import useIsIOS from "../popups/useIsIos";

export default function MpesaNo() {
  const [phoneNo, setPhoneNo] = useState("");
  const [showPopUp, setShowPopUp] = useState("");
  const [cardOption, setCardOption] = useState(false);
  const [isIOS, setIsIOS] = useIsIOS();

  const dispatch = useDispatch();
  const { loginUser, addMpesaNo } = bindActionCreators(actions, dispatch);
  const navigate = useNavigate();

  let searchState = useSelector((state) => state.search);
  const balanceState = useSelector((state) => state.balance);
  const senderData = useSelector((state) => state.account.userDetails);
  const tippingState = useSelector((state) => state.tipping);
  const tipData = useSelector((state) => state.tipData);
  const reservationState = useSelector((state) => state.reservations);

  let receiverShukranId = tipData.shukranId;
  let nonShukranId = tipData.nonShukranId;
  let queryShukranId = tipData.queryShukranId;
  let queryName = tipData.queryName;
  // values from react hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleBack = () => {
    navigate(-1);
  };

  // user input handler
  useEffect(() => {
    return () => {};
  }, [searchState.isLoading]);

  useEffect(() => {
    if (searchState.errorMessage === 403) {
      navigate("/login");
      dispatch(searchActions.searchLoadError(""));
    }
    return () => {};
  }, [searchState, dispatch, navigate]);

  useEffect(() => {
    if (tippingState.navigate.navigate === "/") {
      navigate("/download");
      dispatch(balanceActions.checkTippingRedirect(""));
    }
    return () => {};
  }, [tippingState, navigate, dispatch]);

  useEffect(() => {
    if (balanceState.errorMessage.msg) {
      setTimeout(() => {
        dispatch(balanceActions.generateTokenError(""));
      }, 4000);
    }
    if (balanceState.success) {
      setTimeout(() => {
        // dispatch(balanceActions.generateTokenReset());
        // handleConfirmPopup(balanceState.userInfo);
        setShowPopUp(true);
      }, 3000);
    }
    return () => {};
  }, [balanceState, dispatch, navigate]);

  const handlePhone = (data) => {
    let payload = {
      phone: data.phoneNumber,
      where_from: reservationState.restaurant
        ? reservationState.restaurant
        : "",
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
    };
    if (!senderData.token) {
      dispatch(generateTokenOtp(payload));
    }
    addMpesaNo({
      mpesa_no: data.phoneNumber,
    });
    setPhoneNo(data.phoneNumber);
  };

  const TokenGenerationCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.generateTokenReset());
          dispatch(loginUser(balanceState.userInfo));
          // handleConfirmPopup(balanceState.userInfo);
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-32" />
      <p className="justify-self-center	text-base text-center py-2">
        Phone no. submitted successfully
      </p>
      <button
        className="mt-4 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
        onClick={() => {
          dispatch(balanceActions.generateTokenReset());
          dispatch(loginUser(balanceState.userInfo));
          // navigate("/confirmMpesaNo");
          // handleConfirmPopup(balanceState.userInfo);
        }}
      >
        {phoneNo ? "Send Tip" : "Confirm OTP code"}
      </button>
    </div>
  );

  const submitTip = () => {
    setShowPopUp(true);
    let payload = {
      amount: tipData.tipAmount,
      tipper_phone_number: phoneNo ? phoneNo : cardOption ? cardOption : "",
    };
    let token = senderData.token
      ? senderData.token
      : balanceState.generateResponse.token;

    localStorage.setItem("token", token);
    if (phoneNo && nonShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: nonShukranId,
        method: "mpesa",
      };
      dispatch(sendTip(payload, token));
      setShowPopUp(false);
    } else if (phoneNo && receiverShukranId) {
      payload = {
        ...payload,
        receivers: [
          {
            receiver_shukran_id: queryShukranId,
            amount: payload.amount,
          },
        ],
        method: "mpesa",
      };
      dispatch(sendTip(payload, token));
      setShowPopUp(false);
    } else if (phoneNo && queryShukranId) {
      payload = {
        ...payload,
        receivers: [
          {
            receiver_shukran_id: queryShukranId,
            amount: payload.amount,
          },
        ],
        receiver_shukran_id: queryShukranId,
        method: "mpesa",
      };
      dispatch(sendTip(payload, token));
      setShowPopUp(false);
    } else if (cardOption && nonShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: nonShukranId,
        method: "pesapal",
      };
      dispatch(sendTip(payload, token));
      setCardOption("");
    } else if (cardOption && receiverShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: receiverShukranId,
        method: "pesapal",
      };

      dispatch(sendTip(payload, token));
      setCardOption("");
    } else if (cardOption && queryShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: queryShukranId,
        method: "pesapal",
      };

      dispatch(sendTip(payload, token));
      setCardOption("");
    } else {
    }
  };

  const ConfirmTipPopup = () => (
    <div className="shadow py-8 px-6 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-base">Confirm Tip</span>
        <div
          onClick={() => {
            setShowPopUp(false);
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">Tip Recipient</label>
        <div className="flex flex-row justify-between">
          {nonShukranId ? (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">
                {tipData.nonShukranId}
              </span>
            </div>
          ) : queryShukranId ? (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">{queryName}</span>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">
                {tipData.receiverName + " " + tipData.receiverLastName}
              </span>
              <span className="text-sm text-gray-300">{tipData.shukranId}</span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Tip Amount</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">Ksh {tipData.tipAmount}</span>
          </div>
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Payment Method</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">{"MPESA"}</span>
          </div>
        </div>
      </div>

      <div className="mt-6">
        {!tippingState.tippingResponse.success && (
          <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            onClick={submitTip}
          >
            Send Tip
          </button>
        )}
      </div>
    </div>
  );

  const ErrorCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-8 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.generateTokenError(""));
        }}
      >
        X
      </button>
      <img src={cross} alt="error-img" className="h-20" />
      <p className="justify-self-center	text-base text-center py-2">
        {balanceState.errorMessage.msg}
      </p>
    </div>
  );

  const TippingTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {!tippingState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(balanceActions.tippingSuccess({}));
            dispatch(balanceActions.checkTippingSuccess(""));
            // navigate("/");
          }}
        >
          X
        </button>
      )}
      {tippingState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
      )}
      {tippingState.tippingResponse.success &&
        !tippingState.isTransactionLoading && (
          <img src={checkCircle} alt="error-img" className="h-24" />
        )}

      {tippingState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {cardOption
            ? "Redirecting to card details form..."
            : "Input your PIN on the Mpesa prompt to tip"}
        </p>
      )}
      {tippingState.tippingResponse?.success &&
        !tippingState.isTransactionLoading && (
          <p className="justify-self-center	text-base text-center py-2 px-12">
            {tippingState.tippingResponse?.msg}
          </p>
        )}
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {tippingState.errorTransactionStatusMessage.msg && (
        <div className="flex flex-col items-center">
          <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
          <p className="justify-self-center	text-base text-center py-2 px-12">
            Tip transaction pending. Kindly be patient as we process your tip
          </p>
        </div>
      )}
      {tippingState.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  const TippingTransactionError = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col items-center relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.checkTippingError(""));
          dispatch(balanceActions.tippingError(""));
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {tippingState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip to {receiverShukranId ? receiverShukranId : nonShukranId}{" "}
          Cancelled
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
        ${balanceState.errorMessage.error ? "relative" : ""}
        ${balanceState.errorMessage.msg ? "relative" : ""}
        ${balanceState.success ? "relative" : ""}
        ${balanceState.isLoading ? "relative" : ""}
        ${tippingState.errorMessage.error ? "relative" : ""}
        ${tippingState.errorMessage?.msg ? "relative" : ""}
        ${tippingState.tippingResponse.success ? "relative" : ""}
        ${tippingState.isLoading ? "relative" : ""}
        ${showPopUp && !balanceState.success ? "relative" : ""}
         w-full flex flex-col`}
    >
      <div
        className={`
          ${balanceState.errorMessage ? "blur-sm" : ""}
          ${balanceState.success ? "blur-sm" : ""}
          ${balanceState.isLoading ? "blur-sm" : ""}
          ${tippingState.errorMessage.error ? "blur-sm" : ""}
          ${tippingState.errorMessage?.msg ? "blur-sm" : ""}
          ${tippingState.tippingResponse.success ? "blur-sm" : ""}
          ${tippingState.isLoading ? "blur-sm" : ""}
          ${showPopUp && !balanceState.success ? "blur-sm" : ""}
          w-full flex flex-col px-3`}
      >
        <div className="flex flex-row items-center mt-9">
          <div
            className={`flex flex-row items-center`}
            onClick={() => handleBack()}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="mx-4 mt-11 h-full flex flex-col justify-between">
          <form onSubmit={handleSubmit(handlePhone)} className="flex flex-col">
            <div className="flex flex-col">
              <span className="text-2xl font-semibold text-primary-pwa">
                Enter Your Mpesa Number
              </span>
              <input
                className="drop-shadow-md border-solid border-[#50a3a7]/70 outline h-12 placeholder:text-base w-full p-2 text-black outline-none focus:outline-primary-pwa mt-4"
                type="number"
                placeholder="0700000000"
                {...register("phoneNumber", {
                  required: true,
                  maxLength: 15,
                  pattern: /^(?:\+254|0)[1-9]\d{8}$/,
                  // /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                })}
              />
              {errors.phoneNumber && (
                <p className="text-red-600 text-sm mt-2">
                  Please enter a valid number
                </p>
              )}
            </div>
            <div className="mt-5 flex flex-col py-5 px-5 bg-[#F3F0FF] rounded-sm">
              <div className="flex flex-row justify-between items-center">
                <span className="text-base">How does it work</span>
                <img src={QuestionIcon} className="" alt="question-mark" />
              </div>
              <span className="text-sm mt-2">
                You will receive an Mpesa prompt to confirm your tip to a
                service worker
              </span>
            </div>
            <button
              className="rounded-full py-3 bg-accent px-3 text-white text-md mx-auto mt-24 mb-12 w-full"
              type="submit"
            >
              PROCEED
            </button>
          </form>
        </div>
      </div>
      <div
        className={`${
          tippingState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-20 mx-auto text-sm mb-2`}
        />
      </div>
      <div
        className={`${
          tippingState.tippingResponse.success &&
          tippingState.tippingResponse.data?.authorization_url
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
          <span className="text-base text-center">
            {cardOption
              ? "Redirecting to card details form..."
              : "Input your PIN on the MPESA prompt to tip"}
          </span>
        </div>
      </div>
      <div
        className={`${
          balanceState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
          <span className="text-base text-center">
            SHUKRAN is generating an access key for submitted number
          </span>
        </div>
      </div>
      <div
        className={`${
          balanceState.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TokenGenerationCard />
      </div>
      <div
        className={`${
          showPopUp && !balanceState.success ? "absolute blur-none" : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ConfirmTipPopup />
      </div>
      <div
        className={`${
          tippingState.tippingResponse.success &&
          !tippingState.tippingResponse.data?.authorization_url
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TippingTransactionCard />
      </div>
      <div
        className={`${
          balanceState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <ErrorCard />
      </div>
      <div
        className={`${
          tippingState.errorTransactionStatusMessage ||
          tippingState.errorMessage?.msg ||
          tippingState.errorMessage?.error ||
          tippingState.errorMessage?.detail
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TippingTransactionError />
      </div>
    </div>
  );
}
