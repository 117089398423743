import React from "react";
import { Users2, Hotel } from "lucide-react";
import { useNavigate } from "react-router-dom";
import BackButton from "../globals/BackButton";
import { useTipJarLocalState } from "./state/local-state/useTipjarLocalState";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { CircleArrowRight } from "lucide-react";

const TipJarCard = ({ name, shukranId, isHotel, onClick }) => {
  const getColors = () => {
    if (isHotel) {
      return {
        stroke: "#F5E1BA",
        fill: "#FFFCF5",
        iconBg: "#F2C773",
      };
    }
    return {
      stroke: "#C5B7DF",
      fill: "#F8F5FF",
      iconBg: "#472884",
    };
  };

  const colors = getColors();

  return (
    <div
      onClick={onClick}
      className="flex items-center justify-between p-4 mb-4 rounded-lg cursor-pointer"
      style={{
        backgroundColor: colors.fill,
        border: `1px solid ${colors.stroke}`,
      }}
    >
      <div className="flex items-center">
        <div
          className="w-12 h-12 rounded-lg flex items-center justify-center mr-3"
          style={{ backgroundColor: colors.iconBg }}
        >
          {isHotel ? (
            <Hotel size={24} color="white" />
          ) : (
            <Users2 size={24} color="white" />
          )}
        </div>
        <div>
          <div className="text-sm text-gray-500">
            {isHotel ? "Hotel TipJAR" : "Group TipJAR"}
          </div>
          <div className="font-medium">{name}</div>
        </div>
      </div>
      <div className="flex items-center">
        <div
          className="w-8 h-8 bg-black rounded-full flex items-center justify-center"
          style={{ backgroundColor: colors.iconBg }}
        >
          <CircleArrowRight color="white" size={24} />
        </div>
      </div>
    </div>
  );
};

const SelectJarsToDisburseFrom = () => {
  const navigate = useNavigate();
  const { entityJARs, setActiveEntityJAR } = useTipJarLocalState();

  const defaultJar = entityJARs?.find((jar) => jar.is_default_entity_jar);
  const groupJars = entityJARs?.filter((jar) => !jar.is_default_entity_jar);

  const handleBack = () => navigate(-1);

  const handleCardClick = (jar, isHotel) => {
    setActiveEntityJAR(jar);
    if (isHotel) {
      navigate("/disburse-from-default-jar/");
    } else {
      navigate("/disburse-from-group-jar/");
    }
  };

  return (
    <div className="flex flex-col w-full h-screen px-4 pt-4">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>

      <h1 className="text-2xl font-medium text-[#6CCBD1] mb-6">
        Select a TipJar to payout tips
      </h1>

      {defaultJar && (
        <>
          <h2 className="text-lg font-medium text-gray-600 mb-4">Hotel Team</h2>
          <TipJarCard
            name={defaultJar.name}
            shukranId={defaultJar.shukran_id}
            isHotel={true}
            onClick={() => handleCardClick(defaultJar, true)}
          />
        </>
      )}

      {groupJars && groupJars.length > 0 && (
        <>
          <h2 className="text-lg font-medium text-gray-600 mt-6 mb-4">
            Groups
          </h2>
          {groupJars.map((jar) => (
            <TipJarCard
              key={jar.id}
              name={jar.name}
              shukranId={jar.shukran_id}
              isHotel={false}
              onClick={() => handleCardClick(jar, false)}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default SelectJarsToDisburseFrom;
