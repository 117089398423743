import SearchService from "../../../Services/search.service";
import { businessActions } from "../business/business.actions";
import { searchActions } from "./search.actions";

export function loadSearchResults(query, token) {
  return (dispatch) => {
    dispatch(searchActions.searchLoadStart());

    SearchService.getSearchResults(query, token)
      .then((response) => {
        dispatch(searchActions.searchLoadSuccess(response.data.results));
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response.status === 403) {
          errorMsg = error.response.status;
        } else if (error.request) {
          errorMsg = error.request;
        } else {
          errorMsg = error.response.data;
        }

        dispatch(searchActions.searchLoadError(errorMsg));
      });
  };
}

export function loadMoreSearchResults(query, token) {
  return (dispatch) => {
    dispatch(searchActions.searchLoadStart());

    SearchService.getMoreSearchResults(query, token)
      .then((response) => {
        dispatch(searchActions.searchLoadSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response.status === 403) {
          errorMsg = error.response.status;
        } else {
          errorMsg = error.response.data;
        }

        dispatch(searchActions.searchLoadError(errorMsg));
      });
  };
}

export function searchBusiness(query, token) {
  return (dispatch) => {
    dispatch(businessActions.searchBusinessStart());
    SearchService.getBusinessResults(query, token)
      .then((response) => {
        dispatch(businessActions.searchBusinessSuccess(response.data));
      })
      .catch((error) => {
        dispatch(businessActions.searchBusinessError(error.response));
      });
  };
}
export function getUserBusiness(token) {
  return (dispatch) => {
    dispatch(businessActions.getUserBusinessStart());
    SearchService.getUserBusiness(token)
      .then((response) => {
        dispatch(businessActions.getUserBusinessSuccess(response.data));
      })
      .catch((error) => {
        dispatch(businessActions.getUserBusinessError(error.response));
      });
  };
}
export function updateServiceWorkerBusiness(params, token) {
  return (dispatch) => {
    dispatch(businessActions.updateServiceWorkerBusinessStart());
    SearchService.updateServiceWorkerBusiness(params, token)
      .then((res) => {
        dispatch(businessActions.updateServiceWorkerBusinessSuccess(res.data));
      })
      .catch((error) => {
        dispatch(
          businessActions.updateServiceWorkerBusinessError(error.response)
        );
      });
  };
}
