import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import ShukranLogoSacco from "../../assets/Logos/shukran-logo-sacco.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import { getFromSessionStorage } from "../../Services/Storage";
import { getSaccoInfo } from "../../state/reducers/user/user.thunk";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

const ActivateExplainer = () => {
  const { register, handleSubmit } = useForm();
  const [comments, setComments] = useState("");
  const [searchParams] = useSearchParams();

  const userAccount = useSelector((state) => state.account);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const saccoState = useSelector((state) => state.sacco);

  const {
    // userDetails: { firstName, lastName, shukranId, phoneNumber },
    isAuthenticated,
  } = userAccount;
  const { saccoType } = balanceState;

  useEffect(() => {
    localStorage.setItem("hasSeenOnboarding", true);
    return () => {};
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const parseComments = (str) => {
    let newComments;
    if (str.includes("\r\n")) {
      newComments = str?.split("\r\n");
    } else {
      newComments = str;
    }

    return newComments;
  };
  useEffect(() => {
    if (saccoState.saccoInfo?.comments) {
      let arrParsed = parseComments(saccoState.saccoInfo?.comments);
      setComments(arrParsed);
    }

    return () => {};
  }, [saccoState]);

  useEffect(() => {

    let param = searchParams.get("origin");
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";

    originState = JSON.parse(originState);
    if (!isAuthenticated && (!param || originState)) {
      navigate("/login");
    }
    if (param) {
      localStorage.setItem("origin", true);
    }

    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    let hasSubscribedBool = localStorage.getItem("hasSubscribed");

    if (balanceState.userInfo.sacco_stage) {

      dispatch(getSaccoInfo(userAccount.userDetails.token));
    }
    if (isAuthenticated) {
    }
  }, []);

  const handleProceed = () => {
    dispatch(saccoActions.updateSaccoDetails(saccoState.saccoInfo));
    dispatch(saccoActions.getBalanceError(""));
    navigate("/activateSacco");
  };

  return (
    <div className={`w-screen h-full flex flex-col bg-imag-dash`}>
      <div className={`flex w-full h-full flex-col justify-between text-black`}>
        <div className="flex flex-row justify-start mt-2 px-5">
          <img
            src={ShukranLogoSacco}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-10 bg-white rounded-t-3xl px-8 pb-3 h-4/5">
          <div className="flex flex-col items-center h-1/2 text-primary-green">
            <span className="text-lg my-3 text-primary-green">
              Hi there {userAccount?.userDetails?.firstName}, 👋
            </span>
            <h2 className="text-3xl text-primary-green my-3 text-center">
              {comments
                ? "Your Application Requires Resubmission"
                : "Welcome to Shukran SACCO"}
            </h2>
            <div className="text-lg mt-3 text-primary-green text-center flex flex-col">
              {!comments
                ? "To receive tips with Shukran you need to activate your SACCO account"
                : typeof comments === "string"
                ? "* " + comments
                : comments.map((comment, idx) => (
                    <span key={idx}>* {comment}</span>
                  ))}
            </div>
          </div>
          {/* <button
            className="rounded-full py-2 bg-[#F2C773] text-white mt-16 text-base w-full"
            type="submit"
            onClick={handleProceed}
          >
            Proceed
          </button> */}
          <div className="flex flex-row justify-between mt-32 pt-3">
            <button
              type="button"
              className={`flex flex-row items-center`}
              onClick={() => handleBack()}
            >
              <img src={ArrowButtonPrev} className="w-12 h-12" alt="Previous" />
              <span className="ml-2">Previous</span>
            </button>
            <button
              type="button"
              onClick={handleProceed}
              className="flex flex-row items-center"
            >
              <span className="mr-2">Proceed</span>
              <img
                src={ArrowButtonNext}
                className="w-12 h-12"
                alt="Next Page"
                type="button"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivateExplainer;
