import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Trash2, Pencil, Loader2 } from "lucide-react";
import add from "../../assets/Icons/tipjar/add.png";
import "./members.css";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import BackButton from "../globals/BackButton";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import {
  useFetchDepartments,
  useActivateDepartment,
  useDeactivateDepartment,
} from "./state/server-state/useDepartmentServerState";
import NoDepartment from "../../assets/Images/no_department.png";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components-shadcn/ui/dialog";
import { Button } from "../../components-shadcn/ui/button";

export default function Groups() {
  const [departmentToEdit, setDepartmentToEdit] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const {
    entityDepartments,
    setDepartmentList,
    activeEntity,
    setActiveDepartment,
  } = useTipJarLocalState();

  const activateDepartment = useActivateDepartment();
  const deactivateDepartment = useDeactivateDepartment();

  const isActivatingDepartment = activateDepartment.isLoading;
  const isDeactivatingDepartment = deactivateDepartment.isLoading;

  // Separate active and inactive departments
  const activeDepartments =
    entityDepartments?.filter((dept) => dept.active) || [];
  const inactiveDepartments =
    entityDepartments?.filter((dept) => !dept.active) || [];

  const handleDeactivateDepartment = async () => {
    if (departmentToEdit) {
      try {
        const response = await deactivateDepartment.mutateAsync({
          department_id: departmentToEdit.id,
        });
        toast.success("Department deactivated succesfully");
        setIsConfirmModalOpen(false);
        setDepartmentToEdit(null);
        refetch();
      } catch (error) {
        toast.error("Failed to deactivate department");
      }
    }
  };

  const handleActivateDepartment = async () => {
    if (departmentToEdit) {
      try {
        const response = await activateDepartment.mutateAsync({
          department_id: departmentToEdit.id,
        });
        refetch();
        toast.success("Department activated succesfully");
      } catch (error) {
        toast.error("Failed to activate department");
      }
    }
  };

  const { data: depts, refetch } = useFetchDepartments(activeEntity?.id);

  useEffect(() => {
    if (depts && depts.length > 0) {
      setDepartmentList(depts);
    }
  }, [depts, setDepartmentList]);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col px-3 w-full h-screen">
      <div
        className={`flex flex-col`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="flex items-center mb-6 w-full">
          <div className="w-full flex flex-row items-center justify-between">
            <BackButton onPress={handleBack} />
          </div>
        </div>
        <div className="mx-4 mt-6 h-full w-full" style={{ width: "100%" }}>
          <h2 className="text-3xl font-semibold text-primary-pwa">
            Departments
          </h2>
          <p className="text-md font-semibold text-gray-400 mt-2">
            Create team departments and choose how tips are divided across each
          </p>

          <div className="mt-6 w-fit w-full justify-center flex items-center">
            <button
              onClick={() => navigate("/tipjar/groups/add")}
              className="flex flex-row items-center p-4 rounded-full shadow-md rounded-full"
            >
              <img src={add} className="add" alt="add member" />
              <h6 className="ml-2 text-[#F2C773] font-semibold">
                New Department
              </h6>
            </button>
          </div>

          {entityDepartments?.length > 0 ? (
            <div className="mt-6" style={{ width: "100%" }}>
              {/* Active Departments */}
              <h4 className="font-semibold">
                Active Departments ({activeDepartments.length})
              </h4>
              <div className="mt-2">
                {activeDepartments.map((department) => (
                  <div className=" empty" key={department?.id}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        className="border-2 border-[#F5F5F5] mb-3 min-h-[65px] p-2"
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          className="w-fit px-2"
                        >
                          <div className="w-[30px] h-[30px] rounded-full bg-gradient-to-b from-yellow-300 to-teal-300 mr-1"></div>
                          <div className="ml-2">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{ color: "#404B5A", fontSize: "14px" }}
                                className="font-semibold"
                              >
                                {department?.name}
                              </p>
                              <div
                                className="ml-1"
                                style={{
                                  border: "1px solid #6CCBD1",
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "12.5px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#F0FEFF",
                                  color: "#6CCBD1",
                                  fontSize: "8px",
                                }}
                              >
                                {department?.distribution_points}
                              </div>
                            </div>
                            <p style={{ color: "#929FB1", fontSize: "10px" }}>
                              {department?.members?.length || 0} Members
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          className="w-fit px-2"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                            className="px-2"
                          >
                            <p
                              style={{ fontSize: "12px", borderRadius: "10px" }}
                              className="text-sm text-white bg-accent px-2 py-1"
                              onClick={() => {
                                setActiveDepartment(department);
                                navigate(
                                  `/tipjar/groups/edit?group=${department?.id}`
                                );
                              }}
                            >
                              <div className="flex items-center gap-2 cursor-pointer">
                                <span className="text-white">Manage</span>
                                <Pencil
                                  className="mr-1"
                                  color="white"
                                  size={15}
                                />
                              </div>
                            </p>
                          </div>
                          <button
                            style={{ fontSize: "12px", borderRadius: "10px" }}
                            className="text-sm text-white bg-[#EE5582] px-2 py-1"
                            onClick={() => {
                              setDepartmentToEdit(department);
                              setIsConfirmModalOpen(true);
                            }}
                            disabled={
                              isDeactivatingDepartment &&
                              departmentToEdit?.id === department.id
                            }
                          >
                            <div className="flex items-center gap-2">
                              {isDeactivatingDepartment &&
                              departmentToEdit?.id === department.id ? (
                                <Loader2
                                  className="animate-spin"
                                  color="white"
                                  size={15}
                                />
                              ) : (
                                <Trash2 color="white" size={15} />
                              )}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Inactive Departments */}
              {inactiveDepartments.length > 0 && (
                <>
                  <h4 className="font-semibold mt-6">
                    Inactive Departments ({inactiveDepartments.length})
                  </h4>
                  <div className="mt-2">
                    {inactiveDepartments.map((department) => (
                      <div className=" empty" key={department?.id}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <div
                            className="border-2 border-[#F5F5F5] mb-3 min-h-[65px] p-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              className="w-fit px-2"
                            >
                              <div className="w-[30px] h-[30px] rounded-full bg-slate-600 mr-1"></div>
                              <div className="ml-2">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p
                                    style={{ fontSize: "14px" }}
                                    className="font-semibold text-slate-600"
                                  >
                                    {department?.name}
                                  </p>
                                  <div
                                    className="ml-1"
                                    style={{
                                      border: "1px solid rgb(71 85 105)",
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: "12.5px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: "rgb(241 245 249)",
                                      color: "rgb(71 85 105)",
                                      fontSize: "8px",
                                    }}
                                  >
                                    {department?.distribution_points}
                                  </div>
                                </div>
                                <p
                                  className="text-slate-600"
                                  style={{ fontSize: "10px" }}
                                >
                                  {department?.members?.length || 0} Members
                                </p>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                              className="px-2"
                            >
                              <p
                                style={{
                                  fontSize: "12px",
                                  borderRadius: "10px",
                                }}
                                className="text-sm text-white bg-accent px-2 py-1"
                                onClick={() => {
                                  setDepartmentToEdit(department);
                                  handleActivateDepartment(department);
                                }}
                              >
                                <div className="flex items-center gap-2 cursor-pointer">
                                  {isActivatingDepartment &&
                                  departmentToEdit?.id === department.id ? (
                                    <Loader2
                                      className="animate-spin"
                                      size={15}
                                    />
                                  ) : (
                                    <span className="text-white">Activate</span>
                                  )}
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="mt-6 empty gap-2 flex flex-col">
              <img
                src={NoDepartment}
                alt="No department"
                className="h-[80px] w-[140px]"
              />
              <p className="w-40 mt-6 text-center text-slate-300">
                Created departments will appear here
              </p>
            </div>
          )}
        </div>
      </div>

      <Dialog open={isConfirmModalOpen} onOpenChange={setIsConfirmModalOpen}>
        <DialogContent className="rounded-md">
          <DialogHeader>
            <DialogTitle>
              Are you sure you want to deactivate {departmentToEdit?.name}?
            </DialogTitle>
          </DialogHeader>

          <DialogFooter className="flex flex-row w-full justify-between mt-2">
            <Button
              onClick={() => {
                setIsConfirmModalOpen(false);
                setDepartmentToEdit(null);
              }}
              className="w-fit rounded-md bg-[#F2C773] hover:bg-[#e0b665] text-white font-semibold rounded-none flex items-center h-[28px]"
            >
              Cancel
            </Button>

            <Button
              onClick={handleDeactivateDepartment}
              className="w-fit rounded-md bg-red-500 hover:bg-red-400 text-white font-semibold rounded-none flex items-center h-[28px]"
              disabled={isDeactivatingDepartment}
            >
              {isDeactivatingDepartment && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ToastContainer />

      {entityDepartments?.length > 0 && (
        <div className=" mt-6">
          <button
            className="rounded-full mr-3 py-3 bg-accent px-3 mb-6 text-white text-m mx-auto mt-2 w-full"
            onClick={() => {
              navigate("/tipjar/groups/distribution");
            }}
            type="button"
          >
            CHANGE DISTRIBUTION
          </button>
        </div>
      )}
    </div>
  );
}
