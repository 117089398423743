import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronDown, ChevronUp, AlertCircle } from "lucide-react";
import { Button } from "../../../components-shadcn/ui/button";
import { Avatar } from "../../../components-shadcn/ui/avatar";
import { Checkbox } from "../../../components-shadcn/ui/checkbox";
import { useNavigate } from "react-router-dom";
import { useFetchDepartments } from "../../Groups/state/server-state/useDepartmentServerState";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import BackButton from "../../globals/BackButton";
import { useAddMemeberToGroup } from "../state/server-state/useTipJarServerState";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";

const SelectMembersInDepartment = () => {
  const [expandedDepartment, setExpandedDepartment] = useState("");
  const [selectedMembers, setSelectedMembers] = useState({});
  const [existingMembers, setExistingMembers] = useState({});
  const {
    activeEntity,
    setDepartmentList,
    setActiveDepartment,
    activeEntityJAR,
  } = useTipJarLocalState();

  const [activeDepartments, setActiveDepartments] = useState([]);

  const {
    data: departments,
    isLoading: isLoadingDepartments,
    error: departmentError,
  } = useFetchDepartments(activeEntity?.id);

  const navigate = useNavigate();

  if (departments === 403) {
    navigate("/login/");
  }

  const addGroupMembers = useAddMemeberToGroup();
  const isAddingGroupMembers = addGroupMembers.isLoading;

  useEffect(() => {
    if (departments && departments.length > 0) {
      const activeDepartments = departments.filter((el) => el.active);
      setActiveDepartments(activeDepartments);

      setDepartmentList(departments);

      const initialSelectedMembers = {};
      const initialExistingMembers = {};
      departments.forEach((department) => {
        initialSelectedMembers[department.id] = {};
        initialExistingMembers[department.id] = {};
        department.members.forEach((member) => {
          const isExistingMember = activeEntityJAR.members.some(
            (jarMember) => jarMember.user.shukran_id === member.user.shukran_id
          );
          if (isExistingMember) {
            initialSelectedMembers[department.id][
              member.user.shukran_id
            ] = true;
            initialExistingMembers[department.id][
              member.user.shukran_id
            ] = true;
          }
        });
      });
      setSelectedMembers(initialSelectedMembers);
      setExistingMembers(initialExistingMembers);
    }
  }, [departments, setDepartmentList, activeEntityJAR]);

  const handleBack = () => {
    navigate(-1);
  };

  const toggleMemberSelection = (departmentId, shukranId, event) => {
    // Prevent the event from bubbling up when clicking the checkbox
    if (event) {
      event.stopPropagation();
    }

    if (!existingMembers[departmentId]?.[shukranId]) {
      setSelectedMembers((prev) => ({
        ...prev,
        [departmentId]: {
          ...prev[departmentId],
          [shukranId]: !prev[departmentId]?.[shukranId],
        },
      }));
    }
  };

  const getSelectedCount = (departmentId) => {
    return Object.values(selectedMembers[departmentId] || {}).filter(Boolean)
      .length;
  };

  const handleConfirmMembers = () => {
    const payload = {
      entity_jar: activeEntityJAR.id,
      shukran_ids: Object.entries(selectedMembers).flatMap(
        ([departmentId, members]) =>
          Object.entries(members)
            .filter(
              ([shukranId, isSelected]) =>
                isSelected && !existingMembers[departmentId]?.[shukranId]
            )
            .map(([shukranId, _]) => ({
              shukran_id: shukranId,
              department_id: departmentId,
              is_team_leader: false,
            }))
      ),
    };
    handleAddMembersToGroup(payload);
  };

  const handleAddMembersToGroup = async (payload) => {
    try {
      const response = await addGroupMembers.mutateAsync(payload);
      toast.success("Members added successfully");
      navigate(-1);
    } catch (error) {
      toast.error("Failed to add members");
    }
  };

  if (isLoadingDepartments) {
    return <div>Loading...</div>;
  }

  if (departmentError) {
    return <div>Error: {departmentError.message}</div>;
  }

  return (
    <div className="flex flex-col px-3 pb-2 w-full h-screen mb-6">
      <div className="w-screen p-0">
        <BackButton onPress={handleBack} />
      </div>

      <h1 className="text-2xl font-semibold text-[#6CCBD1] mt-6 mb-2">
        Set up group
      </h1>
      <p className="text-sm text-gray-500 my-2">
        Select team individuals to add to group
      </p>

      {activeDepartments && activeDepartments.length > 0 ? (
        activeDepartments.map((department) => (
          <div key={department.id} className="mb-2">
            <Button
              variant="outline"
              className="w-full justify-between text-left p-4 border-2 border-gray-200 h-[60px]"
              onClick={() =>
                setExpandedDepartment(
                  department.id === expandedDepartment ? "" : department.id
                )
              }
            >
              <div className="flex items-center">
                <div className="w-[30px] h-[30px] rounded-full bg-gradient-to-b from-yellow-300 to-teal-300 mr-3"></div>
                <span>{department.name}</span>
              </div>
              <div className="flex items-center">
                <span className="mr-2 text-sm text-gray-500">
                  {getSelectedCount(department.id)} selected
                </span>
                {department.id === expandedDepartment ? (
                  <ChevronUp className="h-4 w-4" />
                ) : (
                  <ChevronDown className="h-4 w-4" />
                )}
              </div>
            </Button>

            {department.id === expandedDepartment && (
              <div className="border-x-2 border-b-2 border-gray-200 rounded-b-lg">
                {department.members
                  .filter((member) => member.can_join_entity_jar)
                  .map((member) => {
                    const isSelected =
                      selectedMembers[department.id]?.[member.user.shukran_id];
                    const isDisabled =
                      existingMembers[department.id]?.[member.user.shukran_id];

                    return (
                      <div
                        key={member.id}
                        className={`flex items-center justify-between p-4 border-b last:border-b-0 ${
                          !isDisabled ? "cursor-pointer hover:bg-gray-50" : ""
                        }`}
                        onClick={() => {
                          if (!isDisabled) {
                            toggleMemberSelection(
                              department.id,
                              member.user.shukran_id
                            );
                          }
                        }}
                      >
                        <div className="flex items-center flex-grow">
                          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-[#70C7CC] text-center mr-3">
                            <p className="text-white font-semibold">
                              {`${member?.user?.first_name[0]}${member?.user?.last_name[0]}`}
                            </p>
                          </div>

                          <div>
                            <p className="font-medium">{`${member.user.first_name} ${member.user.last_name}`}</p>
                            <p className="text-sm text-gray-500">
                              {member.user.shukran_id}
                            </p>
                          </div>
                        </div>
                        <div
                          className="flex items-center justify-center"
                          onClick={(e) => {
                            if (!isDisabled) {
                              toggleMemberSelection(
                                department.id,
                                member.user.shukran_id,
                                e
                              );
                            }
                          }}
                        >
                          <Checkbox
                            checked={isSelected || false}
                            disabled={isDisabled}
                            className="data-[state=checked]:bg-[#70C7CC] data-[state=checked]:border-[#70C7CC] cursor-pointer"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="flex flex-col items-center justify-center text-gray-500">
          <AlertCircle className="h-12 w-12 mb-2" />
          <p>No departments available</p>
        </div>
      )}

      <div className="mt-auto pb-2">
        <Button
          className="w-full bg-[#F2C773] hover:bg-yellow-500 text-white rounded-full h-[60px]"
          onClick={handleConfirmMembers}
          disabled={isAddingGroupMembers}
        >
          {isAddingGroupMembers ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ADDING MEMBERS...
            </>
          ) : (
            "CONFIRM MEMBERS"
          )}
        </Button>
      </div>
    </div>
  );
};

export default SelectMembersInDepartment;
