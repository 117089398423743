import React, { useState, useEffect } from "react";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { useNavigate, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { adjustAccessPercentage } from "../../state/reducers/user/user.thunk";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import BackButton from "../globals/BackButton";

export default function AdjustPercentage() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});
  const [isClicked, setIsClicked] = useState(false);

  const balanceData = useSelector((state) => state.balance);
  const userAccount = useSelector((state) => state.account);
  const saccoState = useSelector((state) => state.sacco);
  const tipAmount = useSelector((state) => state.tipData.tipAmount);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirm = (data) => {
    let payload = {
      access_tip_active: true,
      access_tip_percentage: Number(data.percentage),
    };
    dispatch(adjustAccessPercentage(payload, userAccount.userDetails.token));
  };
  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");

    if (saccoState.errorMessage.status === 403 && isLoggedIn) {
      navigate("/login");
    }

    return () => {};
  }, [saccoState, navigate]);

  const RateError = () => (
    <div className="drop-shadow-2xl bg-white pt-10 flex flex-col relative pb-4 rounded-3xl w-4/5 z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.adjustAccessError(""));
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="Not found icon" className="h-20" />

      <span className="justify-self-center	text-lg text-center p-2">
        {saccoState.errorMessage?.data?.msg}
      </span>
    </div>
  );

  const SuccessRating = () => (
    <div className="drop-shadow-2xl bg-white pt-10 flex flex-col relative pb-4 rounded-3xl w-4/5 z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.adjustAccessSuccess([]));
          localStorage.setItem("hasAdjustedPercentage", true);
          navigate("/accessTips");
        }}
      >
        X
      </button>

      <img src={checkCircle} alt="error-img" className="h-20" />

      <p className="justify-self-center	text-lg text-center p-2">
        {"Access balance is " +
          saccoState.results?.data?.access_tip_percentage +
          "% of your daily tips."}
      </p>
    </div>
  );

  return (
    <div
      className={`
     ${saccoState.isLoading ? "relative" : ""}
      ${saccoState.errorMessage ? "relative" : ""}

      ${saccoState.results.success ? "relative" : ""}
    w-screen flex flex-col h-full`}
    >
      <div
        className={`
        ${saccoState.isLoading ? "blur-sm" : ""}
        ${saccoState.errorMessage ? "blur-sm" : ""}
        ${saccoState.results.success ? "blur-sm" : ""}
        flex flex-col w-full h-full px-5`}
      >
        {/* <DashBoardHeader /> */}
        <div className="flex flex-row items-center mt-9">
          <div className="w-screen p-0">
            <BackButton onPress={() => navigate("/")} />
          </div>

          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="bg-white flex flex-col">
          <p className="mt-5 text-3xl font-semibold text-primary-pwa">
            Select percentage of your daily tips you would like to access
          </p>
          <form
            onSubmit={handleSubmit(handleConfirm)}
            className="flex flex-col justify-between h-full"
          >
            <div className="w-full mt-7">
              <datalist id="tickmarks">
                <option value="0" label="0"></option>
                <option value="10" label="10"></option>
                <option value="20" label="20"></option>
                <option value="30" label="30"></option>
                <option value="40" label="40"></option>
                <option value="50" label="50"></option>
              </datalist>
              <input
                id="temp"
                name="temp"
                list="tickmarks"
                min="0"
                max="50"
                step="1"
                type="range"
                className="accent-inactivated bg-transparent w-full"
                {...register("percentage", {
                  required: true,
                })}
              />
              {errors.percentage && (
                <p className="text-red-600 text-sm">
                  Ensure you have entered in a KRA pin
                </p>
              )}
            </div>

            <button
              className="rounded-full py-2 bg-primary-green text-white text-base w-full mt-32 mb-4"
              type="submit"
            >
              Confirm
            </button>
          </form>
        </div>
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SuccessRating />
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <RateError />
      </div>
      <div
        className={`${
          saccoState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mb-1`}
        />
      </div>
    </div>
  );
}
