import React, { useEffect, useState } from "react";
import logo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { Controller, useForm } from "react-hook-form";
import loader from "../../assets/Icons/Registration/loader.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import { Link, Outlet } from "react-router-dom";
import RegInput from "./RegInput";
import { testUrl } from "../../helper";

import {
  getFromSessionStorage,
  saveToSessionStorage,
} from "../../Services/Storage";
import { loginUser } from "../../state/actions/registerUser";
import { requestOtpCode } from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";

const axios = require("axios");

export default function Registration() {
  // set state for input values
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [IDNumber, setIDNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [results, setResults] = useState("created");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isFocusedConfirm, setIsFocusedCon] = useState(false);
  const [isFocusedPassword, setIsFocusedPass] = useState(false);

  // gets data from redux store
  const userData = useSelector((state) => state.account);
  const userDataToken = useSelector((state) => state.account.user);
  const dispatch = useDispatch();

  // updates data to redux store
  const { registerUser } = bindActionCreators(actions, dispatch);

  // query strings

  const [searchParams] = useSearchParams();

  // values froom react hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  useEffect(() => {
    let param = searchParams.get("phone");

    if (param) {
      setValue("phoneNumber", param);
    }
    return () => {};
  }, []);

  // navigate function
  let navigate = useNavigate();

  // set method that handles input change event
  const handleInputChange = (e, setFunc) => {
    const value = e.target.value;
    setFunc(value);
  };

  // puts state into object
  const getData = (data) => {
    return {
      first_name: data["firstName"],
      last_name: data["lastName"],
      phone: data["phoneNumber"],
      password: password,
      role: "service",
    };
  };

  // set method that runs when the submit is hit
  const handleFormSubmit = async (data) => {
    let nonShukranId = searchParams.get("phone");
    let url = nonShukranId
      ? `${testUrl}accounts/auth/activate/`
      : `${testUrl}accounts/auth/signup/`;
    try {
      setIsLoading(true);
      const response = await axios({
        url: url,
        method: "post",
        data: getData(data),
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
      });
      if (response["status"] === 201) {
        await saveToSessionStorage("token", response.data.data.token);
        await saveToSessionStorage("ShukranId", response.data.data.shukran_id);
        await saveToSessionStorage("firstName", response.data.data.first_name);
        setResults("created");
        setIsError(false);
        setIsLoading(false);
        registerUser(response.data.data);
        loginUser(response.data.data);
        dispatch(balanceActions.balanceLoadError(""));
        localStorage.setItem("isLoggedIn", true);
        let params = {
          phone: response.data.data.phone,
          token: response.data.data.token,
        };
        dispatch(requestOtpCode(params, response.data.data.token));
        navigate("/confirmcode");
        // navigate("/");
      } else if (response["status"] >= 400) {
        setResults(response.data);
        setIsError(true);
        setIsLoading(false);
      } else if (response.response.status >= 400) {
        setResults(response.response.data);
        setIsError(true);
        setIsLoading(false);
      } else {
      }
    } catch (e) {
      setIsError(true);
    }
  };

  const RegisterError = () => (
    <div className="p-7 shadow bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setIsError(false);
          // navigate("/rate");
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="error-img" className="h-20" />
      <h2 className={"text-center font-bold	text-lg"}>Sign up error</h2>
      <p className="justify-self-center	text-base text-center py-2">
        {results?.msg}
      </p>
    </div>
  );

  return (
    <div
      className={`
      ${isLoading ? "relative" : ""}
      ${isError ? "relative" : ""} w-full flex flex-col`}
    >
      <div
        className={`
          ${isLoading ? "blur-sm" : ""}
          ${isError ? "blur-sm" : ""}
          flex w-fit flex-col text-black mx-auto bg-imag-dash`}
      >
        <div className="header flex flex-row justify-center mt-2">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
        </div>
        <div className="input-div flex flex-col	pt-4 bg-white rounded-t-3xl px-7">
          <h2 className="text-lg">Create your Shukran SACCO account</h2>
          <form
            // onSubmit={handleSubmit(handleFormSubmit)}
            className="flex flex-col"
          >
            <div className="flex flex-row mt-2">
              <div className="w-1/2 mr-2 ">
                <label className="mt-2 text-[#929FB1] text-sm italic">
                  First Name
                </label>
                <input
                  className=" border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12 placeholder:text-base w-full py-2 text-black"
                  type="text"
                  placeholder="Joe"
                  autoFocus
                  onChange={(e) => handleInputChange(e, setFirstName)}
                  {...register("firstName", {
                    required: true,
                  })}
                />
                {errors.firstName && (
                  <p className="text-red-600 text-sm">First Name required</p>
                )}
              </div>
              <div className="w-1/2">
                <label className="mt-2 text-[#929FB1] text-sm italic">
                  Last Name{" "}
                </label>
                <input
                  className=" border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12 placeholder:text-base  w-full py-2 text-black"
                  type="text"
                  placeholder="Doe"
                  onChange={(e) => handleInputChange(e, setLastName)}
                  {...register("lastName", {
                    required: true,
                  })}
                />
                {errors.lastName && (
                  <p className="text-red-600 text-sm	">Last Name required</p>
                )}
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-[#929FB1] text-sm italic">
                Phone Number{" "}
              </label>
              <input
                className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12placeholder:text-base  w-full py-2 text-black"
                type="number"
                placeholder="0700000000"
                onChange={(e) => handleInputChange(e, setPhoneNumber)}
                {...register("phoneNumber", {
                  required: true,
                  // maxLength: 15,
                  // pattern:
                  //   /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                })}
              />
              {errors.phoneNumber && (
                <p className="text-red-600 text-sm">
                  Please check the phoneNumber
                </p>
              )}
            </div>

            {/* <div className="flex flex-col mt-2">
              <label className="mt-2 text-[#929FB1] text-sm italic">
                National ID{" "}
              </label>
              <input
                className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12placeholder:text-base  w-full py-2 text-black"
                type="number"
                placeholder="3103XXXX"
                onChange={(e) => handleInputChange(e, setPhoneNumber)}
                {...register("nationalId", {
                  required: true,
                  maxLength: 8,
                  pattern: /^[0-9]{7,8}$|^[A-Z]{1,2}[0-9]{6,8}$/,
                })}
              />
              {errors.nationalId && (
                <p className="text-red-600 text-sm">
                  Please enter a valid national ID
                </p>
              )}
            </div> */}
            {/* <div className="flex flex-col justify-start mt-2">
              <span className="mt-2 text-[#929FB1] text-sm italic">Gender</span>
              <div className="flex flex-row justify-between">
                <label
                  htmlFor="field-female"
                  className="text-sm font-medium text-gray-900 w-1/4 flex flex-row-reverse justify-between my-2"
                >
                  Female
                  <input
                    {...register("gender")}
                    id="field-female"
                    type="radio"
                    value="female"
                    className="h-4 w-4 border-accent focus:ring-1"
                  />
                </label>
                <label
                  htmlFor="field-male"
                  className="text-sm font-medium text-gray-900 w-1/4 flex justify-between flex-row-reverse my-2"
                >
                  Male
                  <input
                    {...register("gender")}
                    id="field-male"
                    type="radio"
                    value="male"
                    className="h-4 w-4 border-accent focus:ring-1"
                  />
                </label>
              </div>
            </div> */}
            {/* <div className="flex flex-col mt-2">
              <label
                className="mt-2 text-[#929FB1] text-sm italic"
                htmlFor="field-date"
              >
                Date Of Birth
              </label>
              <Controller
                control={control}
                name="date-input"
                id="field-date"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="01/01/1997"
                    className="border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12placeholder:text-base  w-full py-2 text-black"
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
            </div> */}
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-[#929FB1] text-sm italic">
                Password{" "}
              </label>
              <RegInput
                setValue={setValue}
                setPassword={setPassword}
                setIsFocused={setIsFocusedPass}
                isFocused={isFocusedPassword}
              />
            </div>

            <div className="flex flex-col mt-2">
              <label className="mt-2 text-[#929FB1] text-sm italic">
                Confirm Password{" "}
              </label>
              <RegInput
                setValue={setValue}
                setPassword={setConfirmPassword}
                setIsFocused={setIsFocusedCon}
                isFocused={isFocusedConfirm}
              />
              {password !== confirmPassword && isFocusedConfirm ? (
                <p className="text-red-600">Passwords do not match</p>
              ) : (
                " "
              )}
            </div>

            <button
              className="rounded-full py-2  bg-[#F2C773] text-white text-base w-full mt-8"
              onClick={handleSubmit(handleFormSubmit)}
            >
              {" "}
              SIGN UP
            </button>
          </form>
        </div>

        <div className="footer-reg flex flex-col bg-white px-8 py-2">
          <p>
            <Link to="/login" className="underline">
              Have an account?
            </Link>{" "}
            <Link to="/login" className="underline  text-[#6CCBD1]">
              SIGN IN
            </Link>
            <Outlet />
          </p>
        </div>
      </div>
      <div
        className={`${
          isError ? "absolute blur-none" : "hidden"
        } flex justify-center items-center flex-col w-full h-full`}
      >
        <RegisterError />
      </div>
      <div
        className={`
          ${isLoading ? "absolute blur-none" : "hidden"}
          flex flex-col justify-center items-center w-full h-full
        `}
      >
        <div className="  my-auto p-7 shadow bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
          <img
            src={loader}
            alt="loader-img"
            className="rounded px-8 mx-auto h-28 animate-spin"
          />
          <p className="text-xl mt-4 p-4 text-center">
            Getting you signed up !
          </p>
        </div>
      </div>
    </div>
  );
}
