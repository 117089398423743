// const pushServerPublicKey = "BMNfNn4J5MmtBjc0Hzfm6H2xk0hIpVhXhztiA4-qUOq-leyyGTDd3Kw2r7xvJuMGYiva83uVeDbA7SBojXomKmQ";
const pushServerPublicKey =
  "BE3iclkeeZQm0izFdPmf84Oxa3uBVeXj15uaE9ViqTtoyNbKDktfNRRq0yOzU8FReoAw5jS1y1fvyyXPCiJrEE8";
// const pushServerPrivateKey = "a-IclW27x6cwhmDs7Wdlm5UjUNxNHVJbvVfYyySbe7s"

// const pushServerPublicKey = `${process.env.REACT_APP_PUBLIC_VAPID_KEY}`

function urlBase64ToUint8Array(base64String) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission();
}
/**
 * shows a notification
 */
function sendNotification() {
  // const img = "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg";
  // const text = "Take a look at this brand new t-shirt!";
  const title = "Tipped";
  const options = {
    body: "You have been TIPPED by PM0003",
    icon: "/public/logo-192x192.png",
    vibrate: [200, 100, 200],
    tag: "new-product",
    image: "/public/logo-192x192.png",
    badge: "/public/favicon-32x32.png",
    actions: [
      {
        action: "OPEN",
        title: "Open Shukran",
        icon: "/public/logo-192x192.png",
      },
    ],
  };
  navigator.serviceWorker.ready.then(function (serviceWorker) {
    serviceWorker.showNotification(title, options);
  });
}

/**
 *
 */
function registerServiceWorker() {
  return navigator.serviceWorker.register("/sw.js");
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(pushServerPublicKey),
  });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  sendNotification,
  createNotificationSubscription,
  getUserSubscription,
};
