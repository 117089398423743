import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import VisaIcon from "../../assets/Icons/Tipping/visa.svg";
import CardIcon from "../../assets/Icons/Tipping/card.svg";
import MastercardIcon from "../../assets/Icons/Tipping/mastercard.svg";
import MpesaIcon from "../../assets/Icons/Tipping/mpesa.svg";
import RightIcon from "../../assets/Icons/Tipping/right_icon.svg";
import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";

import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import actions from "../../state";
import { depositActions } from "../../state/reducers/deposit/deposit.actions";
import { depositAmount } from "../../state/reducers/user/user.thunk";

export default function DepositOptions() {
  const [togglePopup, setTogglePopup] = useState(false);
  const [cardOption, setCardOption] = useState(false);
  const [cardPopup, setCardPopup] = useState(false);
  const [depositAmt, setDepositAmt] = useState("");
  const [depositAmountErr, setDepositAmountErr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selected, setSelected] = useState("");
  const [amounts, setAmounts] = useState([
    {
      option: "card",
      label: "Credit/Debit card",
      selected: false,
      id: 0,
    },
    {
      option: "mpesa",
      label: "Mpesa",
      selected: false,
      id: 1,
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { updateDepositedAmount } = bindActionCreators(actions, dispatch);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({});

  const depositState = useSelector((state) => state.deposit);
  const userData = useSelector((state) => state.account.userDetails);
  const balanceState = useSelector((state) => state.balance);
  const userInfo = useSelector((state) => state.balance.userInfo);
  const transactionState = useSelector((state) => {
    let uniqueIds = [];
    let arrFil = state.transactions?.cardTransactions?.data ? state.transactions?.cardTransactions?.data :[]
    let unique = arrFil.filter(element => {
      let isDuplicate = uniqueIds.includes(element.authorization_json.last4);

      if (!isDuplicate) {
        uniqueIds.push(element.authorization_json.last4);
        return true;
      }

      return false;
    });
    return {
      cardTransactions: unique
    }
  });

  //Handle enter key input
  useEffect(() => {
    let depositNo = localStorage.getItem("depositNumber")
      ? localStorage.getItem("depositNumber")
      : userData.phoneNumber;
    setPhoneNumber(depositNo);

    return () => {};
  }, []);

  useEffect(() => {
    if (depositState.errorMessage.status === 403) {
      navigate("/login");
      dispatch(depositActions.depositLoadError(""));
    }
    return () => {};
  }, [depositState]);

  useEffect(() => {
    if (depositState.navigate.navigate === "/" && !cardOption) {
      setTimeout(() => {
        setTogglePopup(false);
        dispatch(depositActions.checkTransactionRedirect(""));
        navigate("/");
      }, 3000);
    }
    if (depositState.results.success && cardOption) {
      setTimeout(() => {
        dispatch(depositActions.depositLoadSuccess({}));
        dispatch(depositActions.checkTransactionError(""));
        setCardPopup(false);
        navigate("/");
      }, 3000);
    }
    return () => {};
  }, [depositState, cardOption]);



  const handleSelectPayment = (e, data) => {
    let newAmounts = amounts;
    let optionsList = data;

    // let newAmounts = amounts.filter((opt) => opt.id !== data.id);
    if (data.id === 1) {
      optionsList.selected = true;
      newAmounts[0].selected = false;
      setSelected(data.option);
      newAmounts[data.id] = data;
    } else {
      optionsList.selected = true;
      newAmounts[1].selected = false;
      setSelected(data.option);
      newAmounts[data.id] = data;
    }
    setAmounts(newAmounts);

    if (depositAmt) {
      let payload = {
        shukran_id: userData.shukranId,
        amount: Number(depositAmt),
        phone: userData.phoneNumber,
        token: userData.token,
        payMembership: false,
      };
      // setDepositAmt(inputDepAmt);
      updateDepositedAmount(payload);
      if (optionsList.option === "card") {
        if (!!transactionState.cardTransactions.length) {
          setCardOption(true)
          setCardPopup(true)
        } else {
          navigate("/depositInputCard");
        }
      } else {
        if (phoneNumber) {
          setTogglePopup(true);
        } else {
          navigate("/depositMpesa");
        }
      }
    } else {
      setDepositAmountErr(true)
    }
  };


  const handleDeposit = () => {
    let cardEmail = !!transactionState.cardTransactions.length
      ? transactionState.cardTransactions[0].customer_json.email
      : '',
    cardId =  !!transactionState.cardTransactions.length
      ? transactionState.cardTransactions[0].id
      : ''
    let payload = {
      // shukran_id: userData.shukranId,
      amount: Number(depositAmt),
      // token: userData.token,
    };
    if (!cardOption) {
      payload ={
        ...payload,
        phone: userData.phoneNumber,
        method: "mpesa"
      }
      dispatch(depositAmount(payload, userData.token));
      setTogglePopup(false);
    } else {
      payload = {
        ...payload,
        email: cardEmail,
        method: "paystack",
        user_card_id: cardId
      };
      dispatch(depositAmount(payload, userData.token));
      setCardPopup(false);
    }
  };

  const ConfirmDepositPopup = () => (
    <div className="shadow py-8 px-6 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-lg">Confirm Deposit</span>
        <div
          onClick={() => {
            setTogglePopup(false);
            setCardOption(false);
            setCardPopup(false);
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">From</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black w-36">{phoneNumber}</span>
          </div>
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">To</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black">
              Sacco Account ~ {userData.shukranId}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Deposit Amount</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-lg text-black">Ksh {depositAmt}</span>
          </div>
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Payment Method</label>
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">
              {cardOption ? "Card Payment" : "MPESA"}
            </span>
          </div>
          {!!transactionState.cardTransactions.length && cardOption && <ul className="mt-3">
            <label className="text-subtle text-sm">Card Details</label>
            {transactionState.cardTransactions
              .map((card, idx) => (
                <li
                  className={`flex flex-row justify-between items-center`}
                  key={idx}
                >
                  <div className="flex flex-row items-center justify-between w-full">
                    <span className="text-sm">XXXX XXXX XXXX {card.authorization_json.last4}</span>
                    <span className="text-xs text-gray-500">{card.authorization_json.brand}</span>
                  </div>
                </li>
              )
            )
          }
          </ul>}
        </div>
      </div>
      <div className="mt-8">
        {!depositState.results.success && (
          <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            disabled={!depositState.isLoading ? false : true}
            onClick={() => handleDeposit()}
          >
            PAY
          </button>
        )}
      </div>
    </div>
  );

  const DepositTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {!depositState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(depositActions.depositLoadSuccess({}));
            dispatch(depositActions.depositLoadError(''));
            dispatch(depositActions.checkTransactionSuccess({}));
            dispatch(depositActions.checkTransactionError(""));
            // addShukranId({})
            // setTogglePopup(false);
            // navigate("/");
          }}
        >
          X
        </button>
      )}
      {depositState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1`}
        />
      )}
      {depositState.results.success && !depositState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-32" />
      )}
      {(depositState.errorTransactionStatusMessage ||
        depositState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-32" />
      )}
      {depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          Input your PIN on the Mpesa prompt to make a deposit
        </p>
      )}

      {depositState.results.success && !depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.results.msg}
        </p>
      )}
      {depositState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage.msg}
        </p>
      )}
      {depositState.errorMessage?.data?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage?.data?.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.txn_status && (
        <p className="justify-self-center	text-base text-center py-2">
          Deposit transaction{" "}
          {depositState.errorTransactionStatusMessage.txn_status}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
        ${depositState.errorMessage.success ? "relative" : ""}
        ${depositState.errorMessage.error ? "relative" : ""}
        ${depositState.isLoading ? "relative" : ""}
        ${depositState.results.success ? "relative" : ""}
        ${depositState.errorTransactionStatusMessage ? "relative" : ""}
        ${togglePopup ? "relative" : ""}
        ${cardPopup ? "relative" : ""}
        w-full flex flex-col h-full
      `}
    >
      <div
        className={`
          ${depositState.errorMessage.success ? "blur-sm" : ""}
          ${depositState.errorMessage.error ? "blur-sm" : ""}
          ${depositState.results.success ? "blur-sm" : ""}
          ${depositState.isLoading ? "blur-sm" : ""}
          ${depositState.errorTransactionStatusMessage ? "blur-sm" : ""}
          ${togglePopup ? "blur-sm" : ""}
          ${cardPopup ? "blur-sm" : ""}
          flex flex-col px-3 w-full h-full`}
      >
        <div className="flex flex-row items-center mt-9">
          <Link to={"/"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="mx-2 mt-11 h-full flex flex-col">
          <span className="text-3xl font-semibold text-primary-pwa">
            How would you like to Deposit to account?
          </span>
          {/* <form
            className="flex flex-col justify-between h-4/5 mt-5 mb-4"
            onSubmit={handleSubmit(handleSubmitOption)}
          > */}
          <div className="flex flex-col justify-between h-4/5 mt-5 mb-4">
            <div className="flex flex-col mt-1">
              <label className="mt-2 text-label text-base">Amount </label>
              <input
                className="rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                type="number"
                placeholder="Enter amount"
                value={depositAmt}
                onFocus={(e) => {
                  let arr = amounts.map((option, idx) => {
                    option.selected = false
                    return option
                  })
                  setAmounts(arr)
                  setDepositAmountErr(false)
                }}
                onChange={(e) => {
                  setDepositAmt(e.target.value)
                }}
              />
              {depositAmountErr && (
                <p className="text-red-600 text-sm">
                  Ensure you have entered an amount
                </p>
              )}
            </div>
            <span className="text-base mt-4">Deposit From:</span>
            <div className="mt-2 bg-white rounded-md border-2 border-gray">
              {amounts.map((amount, idx) => (
                <div
                  className={`flex flex-row justify-between items-center p-4 w-full
                    ${amount.selected ? "bg-primary-green" : "bg-accent-light-2" }
                    ${idx === 0 ? "border-b-2" : ""}
                    border-gray`
                  }
                  key={idx}
                  onClick={(e) => handleSelectPayment(e, amount)}
                >
                  <div className="flex flex-row items-center">
                    <img src={idx === 0 ? CardIcon : MpesaIcon} alt="icon"
                      className={`${idx === 0 ? "border-b-2 bg-[#404B5A] p-2 rounded-full" : ""} `}
                    />
                     {idx === 0
                      ?
                      <div className={`pl-4 flex flex-row items-center py-2`}>
                           <img src={VisaIcon} alt="icon" />
                           <div className="italic text-sm mx-2">{' & '}</div>
                           <img src={MastercardIcon} alt="icon" />
                           <div className={`${
                                amount.selected ? "text-white" : "text-black"
                              } italic text-sm ml-2`}
                            >{' supported'}</div>
                        </div>
                      :
                        <span
                          className={`${
                            amount.selected ? "text-white" : "text-black"
                          } ml-4`}
                        >
                          {amount.label}
                        </span>
                      }
                  </div>
                  <img src={RightIcon} alt="icon" />
                </div>
              ))}
            </div>
            <div className="mt-3 flex flex-col py-5 px-5 bg-[#F3F0FF] rounded-sm">
              <div className="flex flex-row justify-between items-center">
                <span className="text-base">How does it work</span>
                <img src={QuestionIcon} className="" alt="question-mark" />
              </div>
              <span className="text-sm mt-2">
                You can lipa pole pole for your membership fee, share capital
                and SACCO balance. Start with as little as 100 shillings.
              </span>
            </div>
            {/* <button
              className="rounded-full bg-[#F2C773] p-2 text-white text-base w-full mx-auto mt-4"
              type="submit"
            >
              Proceed
            </button>
          </form> */}
          </div>
        </div>
      </div>
      <div
        className={`${
          togglePopup &&
          !depositState.isLoading &&
          !depositState.results.success &&
          !depositState.errorTransactionStatusMessage &&
          !depositState.errorMessage
            ? "absolute blur-none"
            : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ConfirmDepositPopup />
      </div>
      <div
        className={`${
          cardPopup &&
          !depositState.isLoading &&
          !depositState.results.success &&
          !depositState.errorTransactionStatusMessage &&
          !depositState.errorMessage
            ? "absolute blur-none"
            : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ConfirmDepositPopup />
      </div>
      <div
        className={`${
          depositState.isLoading && !depositState.results.success
            ? "absolute blur-none"
            : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
          <span className="text-base text-center">
            {!cardOption
              ? 'An Mpesa prompt will appear on your phone shortly please to input pin to complete transaction'
              : 'Initiating card deposit...'
            }
          </span>
        </div>
      </div>
      <div
        className={`${
          depositState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
      <div
        className={`${
          depositState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
      <div
        className={`${
          depositState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
    </div>
  );
}
