import React, { useEffect } from "react";
import DashBoardHeader from "../Dashboard/DashBoardHeader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { depositActions } from "../../state/reducers/deposit/deposit.actions";

import loader from "../../assets/Icons/Registration/loader.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import cross from "../../assets/Icons/Rating/not_found.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import {
  depositAmount,
  loadBalanceResults,
} from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";

export default function ConfirmDeposit() {
  const depositData = useSelector((state) => state.account.depositedAmount);
  const depositState = useSelector((state) => state.deposit);
  const userData = useSelector((state) => state.account.userDetails);
  const balanceData = useSelector((state) => state.balance);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeposit = () => {
    dispatch(depositAmount(depositData, userData.token));
  };

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    // fetchData();
    dispatch(loadBalanceResults(params, userData.token));
  }, [dispatch, userData.token]);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (balanceData.errorMessage === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (balanceData.errorMessage === 403 && !isLoggedIn) {
      navigate("/registration");
    }
    return () => {};
  }, [balanceData, navigate]);

  const DepositTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(depositActions.depositLoadStart({}));
          dispatch(depositActions.checkTransactionError(""));
          // addShukranId({})
          navigate("/");
        }}
      >
        X
      </button>
      {depositState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-10 mx-auto text-sm mt-1`}
        />
      )}
      {depositState.results.success && !depositState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-10" />
      )}
      {(depositState.errorTransactionStatusMessage ||
        depositState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-20" />
      )}
      {depositState.results.success && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.results.msg}
        </p>
      )}
      {depositState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.txn_status && (
        <p className="justify-self-center	text-base text-center py-2">
          Deposit transaction{" "}
          {depositState.errorTransactionStatusMessage.txn_status}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
      ${depositState.errorMessage.success ? "relative" : ""}
      ${depositState.errorMessage.error ? "relative" : ""}
      ${depositState.isLoading ? "relative" : ""}
      ${depositState.results.success ? "relative" : ""}
      ${
        depositState.errorTransactionStatusMessage ? "relative" : ""
      } w-full flex flex-col
          `}
    >
      <div
        className={`
         ${depositState.errorMessage.success ? "blur-sm" : ""}
          ${depositState.errorMessage.error ? "blur-sm" : ""}
          ${depositState.results.success ? "blur-sm" : ""}
          ${depositState.isLoading ? "blur-sm" : ""}
          ${
            depositState.errorTransactionStatusMessage ? "blur-sm" : ""
          } flex flex-col m-0 bg-imag-dash w-full
        `}
      >
        <DashBoardHeader navigateLink={"deposit"} />
        <div className="bg-white rounded-t-3xl flex flex-col px-8">
          <div className="flex flex-col">
            <h1 className="mt-11 text-black text-center text-base pb-2">
              Confirm Deposit
            </h1>
            <div className="flex flex-row mt-7 items-center justify-between w-full h-10">
              <span className="text-subtle text-sm">Mpesa Number</span>
              <div className="flex flex-row items-center font-bold">
                +{userData.phoneNumber}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between mt-1 w-full h-10">
              <span className="text-subtle text-sm">Amount</span>
              <div className="flex flex-row items-center font-bold">
                {"KES " + depositData?.amount}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between mt-1 w-full h-10 mb-2">
              <span className="text-subtle text-sm">Name</span>
              <div className="flex flex-row items-center font-bold">
                {userData.firstName}
              </div>
            </div>
          </div>
          <button
            className="rounded-full bg-accent h-12 text-white text-xl w-full mt-28 py-4 flex justify-center items-center"
            type="submit" // onClick={() => handleFormSubmit(e)}
            disabled={!depositState.isLoading ? false : true}
            onClick={() => handleDeposit()}
          >
            Deposit
          </button>
        </div>
      </div>
      <div
        className={`${
          depositState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          depositState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
      <div
        className={`${
          depositState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
      <div
        className={`${
          depositState.errorMessage.error ? "absolute blur-none" : "hidden"
        }
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="p-4 bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(
                depositActions.depositLoadError({
                  error: false,
                  msg: "",
                  data: "",
                })
              );
              // navigate("/rate");
            }}
          >
            X
          </button>
          <img src={cross} alt="error-img" className="h-12" />

          <h2
            className={
              depositState.errorMessage.data
                ? "text-center font-bold	text-lg"
                : "hidden"
            }
          >
            {depositState.errorMessage.data}
          </h2>
          <p
            className={
              depositState.errorMessage.error
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {depositState.errorMessage.msg}
          </p>
        </div>
      </div>
      <div
        className={`${
          depositState.errorMessage.success ? "absolute blur-none" : "hidden"
        }
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="p-4 bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(
                depositActions.depositLoadError({
                  error: false,
                  msg: "",
                  data: "",
                })
              );
              // navigate("/rate");
            }}
          >
            X
          </button>
          <img src={cross} alt="error-img" className="h-12" />

          <h2
            className={
              depositState.errorMessage.msg
                ? "text-center font-bold	text-lg"
                : "hidden"
            }
          >
            {depositState.errorMessage.msg}
          </h2>
          <p
            className={
              depositState.errorMessage.msg
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {depositState.errorMessage.msg}
          </p>
        </div>
      </div>
    </div>
  );
}
