import {
  useQuery,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";
import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../../utils/constructApiUrl";
import useAxios from "../../useAxios";
import { ProfileQueryTypes } from "./profileQueryTypes";

const getProfile = async ($http: AxiosInstance, options: any) => {
  const apiUrl = constructApiUrl(`accounts/auth/profile/`);
  try {
    const response = await $http.get(apiUrl, options);
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;

    if (responseCode === 403) {
      console.log("PROFILE RES CODE dd---->", responseCode);
      return responseCode;
    }
    throw new Error("Could not get profile");
  }
};

export const useGetProfile = () => {
  const $http = useAxios();

  return useMutation({
    mutationKey: [ProfileQueryTypes.GET_PROFILE],
    mutationFn: (options: any) => getProfile($http, options),
  });
};
