import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { balanceActions } from "../../state/reducers/user/user.actions";

const UserType = () => {
  const [userError, setUserError] = useState();
  const { register, handleSubmit } = useForm();

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const { saccoType } = balanceState;

  const handleFormSubmit = async (data) => {
    dispatch(balanceActions.setUserType(data.userType));
    if (data.userType === "tipper") {
      navigate("/tipperType");
    } else if (data.userType === "service worker") {
      dispatch(balanceActions.setSaccoType("yes"));
      navigate("/onboardingSw");
    } else {
      setUserError(true);
    }
  };

  const UserError = () => (
    <div className="p-7 w-4/5 bg-white drop-shadow-md pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setUserError(false);
          // navigate("/rate");
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="error-img" className="h-20 my-3" />
      <p className="justify-self-center	text-base text-center mx-8 py-2">
        Please select one of the two options
      </p>
    </div>
  );

  return (
    <div
      className={`${userError ? "relative" : ""} w-screen flex flex-col h-full`}
    >
      <div
        className={` ${
          userError ? "blur-sm" : ""
        } w-screen h-full flex flex-col px-5 bg-imag-dash`}
      >
        <div className={`flex w-full h-full flex-col text-black`}>
          <div className="flex flex-row justify-start mt-2">
            <img
              src={logo}
              alt="logo of shukran"
              className="rounded py-3 h-16 mt-16 mb-4"
            />
          </div>
          <div className="input-div flex flex-col	pt-4 h-full">
            <h2 className="text-3xl font-medium text-primary-pwa">
              How do you want to use Shukran?
            </h2>
            <form
              className="flex flex-col justify-between h-full mb-8"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="flex flex-col mt-2">
                <div className="flex items-center bg-accent-light-2 border-b-2 border-gray rounded-t-md">
                  <label
                    htmlFor="field-tipper"
                    className="text-base font-medium text-gray-900 w-full flex justify-between my-6 mx-3"
                  >
                    Give Tips
                    <input
                      {...register("userType")}
                      id="field-tipper"
                      type="radio"
                      value="tipper"
                      className="h-4 w-4 border-accent focus:ring-1"
                    />
                  </label>
                </div>
                <div className="flex items-center bg-accent-light-2 border-b-2 border-gray rounded-b-md">
                  <label
                    htmlFor="field-service-worker"
                    className="text-base font-medium text-gray-900 w-full flex justify-between my-6 mx-3"
                  >
                    Receive Tips
                    <input
                      {...register("userType")}
                      id="field-service-worker"
                      type="radio"
                      value="service worker"
                      className="h-4 w-4 border-accent focus:ring-1"
                    />
                  </label>
                </div>
              </div>
              <button
                className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
                type="submit"
              >
                Proceed
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`${
          userError ? "absolute blur-none" : "hidden"
        } flex justify-center items-center flex-col w-full h-full`}
      >
        <UserError />
      </div>
    </div>
  );
};

export default UserType;
