import UserService from "../../../Services/user.service";

import { ratingActions } from "../rating/rating.actions";
import { resetActions } from "../reset/reset.actions";
import { withdrawActions } from "../withdraw/withdraw.actions";
import { depositActions } from "../deposit/deposit.actions";
import { balanceActions } from "./user.actions";
import { registrationActions } from "../registration/registration.actions";
import { saccoActions } from "../sacco/sacco.actions";
import { reservationActions } from "../reservation/reservation.actions";
import { tipjarActions } from "../tipjar/tipjar.actions";
import userStore from "../../../store/user.store";

export function loadBalanceResults(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.balanceLoadStart());
    UserService.getUserBalance(query, token)
      .then((response) => {
        dispatch(balanceActions.balanceLoadSuccess(response.data.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(balanceActions.balanceLoadError(error.response.status));
        } else {
          dispatch(
            balanceActions.balanceLoadError(
              error.response.statusText
                ? error.response.statusText
                : "Undefined Error"
            )
          );
        }
      });
  };
}

export function getUserSubscription(token) {
  return (dispatch) => {
    dispatch(balanceActions.subscriptionLoadStart());
    UserService.getUserSubscription(token)
      .then((response) => {
        dispatch(balanceActions.subscriptionLoadSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(balanceActions.subscriptionLoadError(error.response.status));
        } else {
          dispatch(balanceActions.subscriptionLoadError(error.response));
        }
      });
  };
}

export function initDeactivateCard(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.deactivateCardStart());
    UserService.deactivateCard(query, token)
      .then((response) => {
        if (response.status === 204) {
          dispatch(
            balanceActions.deactivateCardSuccess({
              success: true,
            })
          );
        } else {
          dispatch(balanceActions.deactivateCardSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(balanceActions.deactivateCardError(error.response.status));
        } else {
          dispatch(balanceActions.deactivateCardError(error.response));
        }
      });
  };
}

export function sendNotification(token) {
  return (dispatch) => {
    dispatch(balanceActions.sendNotificationStart());
    UserService.sendNotification(token)
      .then((response) => {
        dispatch(balanceActions.sendNotificationSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(balanceActions.sendNotificationError(error.response.status));
        } else {
          dispatch(balanceActions.sendNotificationError(error.response));
        }
      });
  };
}

export function postUserSubscription(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.postSubscriptionStart());
    UserService.postSubscription(query, token)
      .then((response) => {
        localStorage.setItem("hasSubscribed", true);
        dispatch(balanceActions.postSubscriptionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(balanceActions.postSubscriptionError(error.response.data));
      });
  };
}

export function postActivateSacco(query, token) {
  return (dispatch) => {
    dispatch(saccoActions.saccoActivateStart());
    UserService.activateSacco(query, token)
      .then((response) => {
        dispatch(saccoActions.saccoActivateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saccoActions.saccoActivateError(error.response));
      });
  };
}

export function anonActivateSacco(query) {
  return (dispatch) => {
    dispatch(saccoActions.saccoActivateStart());
    UserService.anonActivateSacco(query)
      .then((response) => {
        dispatch(saccoActions.saccoActivateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saccoActions.saccoActivateError(error.response));
      });
  };
}

export function adjustAccessPercentage(query, token) {
  return (dispatch) => {
    dispatch(saccoActions.adjustAccessStart());
    UserService.adjustAccessPercentage(query, token)
      .then((response) => {
        dispatch(saccoActions.adjustAccessSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saccoActions.adjustAccessError(error.response));
      });
  };
}
export function accessTips(query, token) {
  return (dispatch) => {
    dispatch(saccoActions.accessTipStart());
    UserService.accessTip(query, token)
      .then((response) => {
        dispatch(saccoActions.accessTipSuccess(response.data));
        let statusPayload = response.data.data.id;

        setTimeout(() => {
          dispatch(checkAccessTipStatus(statusPayload, token));
        }, 15000);
      })
      .catch((error) => {
        dispatch(saccoActions.accessTipError(error.response));
      });
  };
}

export function checkAccessTipStatus(query, token) {
  return (dispatch) => {
    dispatch(saccoActions.checkAccessTipStart());
    UserService.checkTransactions(query, token)
      .then((response) => {
        dispatch(saccoActions.checkAccessTipSuccess(response.data));
        setTimeout(() => {
          dispatch(saccoActions.checkAccessTipSuccess({}));
          dispatch(saccoActions.checkAccessTipRedirect({ navigate: "/" }));
        }, 4000);
      })
      .catch((error) => {
        let errorMsg = error.response;
        // dispatch(balanceActions.balanceLoadStart());
        dispatch(saccoActions.checkAccessTipError(errorMsg));
      });
  };
}

export function getSaccoLoans(token) {
  return (dispatch) => {
    dispatch(saccoActions.getLoansStart());
    UserService.getSaccoLoans(token)
      .then((response) => dispatch(saccoActions.getLoansSuccess(response.data)))
      .catch((error) => {
        dispatch(saccoActions.getLoansError(error.response));
      });
  };
}

export function getSaccoBalances(token) {
  return (dispatch) => {
    dispatch(saccoActions.getBalanceStart());
    UserService.getSaccoBalances(token)
      .then((response) => {
        dispatch(saccoActions.getBalanceSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saccoActions.getBalanceError(error.response));

        if (error.response.status === 403) {
          dispatch(saccoActions.getBalanceError(error.response.status));
        } else if (error.response.status === 404) {
          dispatch(saccoActions.getBalanceError(error.response.data));
        } else {
          dispatch(
            saccoActions.getBalanceError(
              error.response.statusText
                ? error.response.statusText
                : "Undefined Error"
            )
          );
        }
      });
  };
}

export function getSaccoInfo(token) {
  return (dispatch) => {
    dispatch(saccoActions.getSaccoInfoStart());
    UserService.getSaccoInformation(token)
      .then((response) =>
        dispatch(saccoActions.getSaccoInfoSuccess(response.data.data))
      )
      .catch((error) => {
        dispatch(saccoActions.getSaccoInfoError(error.response));
      });
  };
}

export function loadTransactionResults(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.transactionLoadStart());

    UserService.getUserTransaction(query, token)
      .then((response) => {
        dispatch(balanceActions.transactionLoadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          balanceActions.transactionLoadError(error.response.statusText)
        );
      });
  };
}

export function loadMoreTransactionResults(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.transactionLoadStart());
    UserService.getMoreUserTransaction(query, token)
      .then((response) => {
        dispatch(balanceActions.transactionLoadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          balanceActions.transactionLoadError(error.response.statusText)
        );
      });
  };
}

export function loadSingleUserTransactions(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.transactionLoadStart());
    UserService.getSingleUserTransactions(query, token)
      .then((response) => {
        dispatch(balanceActions.transactionLoadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          balanceActions.transactionLoadError({ status: error.response.status })
        );
      });
  };
}
export function loadTIpjarDisbursements(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.getTipjarTransactionsStart());
    UserService.getTipjarDisbursements(query, token)
      .then((response) => {
        dispatch(balanceActions.getTipjarTransactionsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          balanceActions.getTipjarTransactionsError({
            status: error.response.status,
          })
        );
      });
  };
}
export function getCardTransactions(token) {
  return (dispatch) => {
    dispatch(balanceActions.cardTransactionsStart());
    UserService.getCardTransactions(token)
      .then((response) => {
        dispatch(balanceActions.cardTransactionsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          balanceActions.cardTransactionsError(error.response.statusText)
        );
      });
  };
}

export function generateTokenOtp(query) {
  return (dispatch) => {
    dispatch(balanceActions.generateTokenStart());
    UserService.generateTokenOtp(query)
      .then((response) => {
        dispatch(balanceActions.generateTokenSuccess(response.data.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(balanceActions.generateTokenError(error.response.status));
        } else if (error.response.status === 404) {
          dispatch(
            balanceActions.generateTokenError({
              msg: "server response 404",
            })
          );
        } else {
          dispatch(balanceActions.generateTokenError(error.response.data));
        }
      });
  };
}

export function verifyTokenOtp(query) {
  return (dispatch) => {
    dispatch(balanceActions.verifyTokenStart());
    UserService.verifyTokenOtp(query)
      .then((response) => {
        dispatch(balanceActions.verifyTokenSuccess(response.data));
      })

      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(balanceActions.verifyTokenError(error.response.status));
        } else {
          dispatch(balanceActions.verifyTokenError(error.response.data));
        }
      });
  };
}

export function sendTip(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.tippingStart());
    UserService.sendTip(query, token)
      .then((response) => {
        dispatch(balanceActions.tippingSuccess(response.data));
        let statusPayload = response.data.data;

        if (!statusPayload.reference) {
          setTimeout(() => {
            dispatch(checkSaccoTipStatus(statusPayload.id, token));
          }, 15000);
        } else if (query.user_card_id && query.method === "paystack") {
          setTimeout(() => {
            dispatch(checkSaccoTipStatus(statusPayload.id, token));
          }, 12000);
        } else {
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(balanceActions.tippingError(error.response.status));
        } else {
          dispatch(balanceActions.tippingError(error.response.data));
        }
      });
  };
}

export function checkSaccoTipStatus(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.checkTippingStart());
    dispatch(balanceActions.checkTippingError(""));
    UserService.checkTransactions(query, token)
      .then((response) => {
        if (response.status === 204) {
          dispatch(
            balanceActions.checkTippingError({
              success: true,
              msg: "Error transaction confirmed",
            })
          );
        } else {
          dispatch(balanceActions.checkTippingSuccess(response.data));
          setTimeout(() => {
            dispatch(balanceActions.checkTippingSuccess({}));
            dispatch(balanceActions.checkTippingRedirect({ navigate: "/" }));
          }, 6000);
        }
      })
      .catch((error) => {
        let errorMsg = error.response;
        // dispatch(balanceActions.balanceLoadStart());
        dispatch(balanceActions.checkTippingError(errorMsg));

        // setTimeout(() => {
        //   dispatch(balanceActions.checkTippingRedirect({ navigate: "/" }));
        // }, 4000);
      });
  };
}

export function getUserData(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.getUserDataStart());
    UserService.getUserData(query, token)
      .then((response) => {
        dispatch(balanceActions.getUserDataSuccess(response.data.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          // window.location.href = "/login";
          dispatch(balanceActions.getUserDataError(error.response.status));
        } else {
          dispatch(balanceActions.getUserDataError(error.response.statusText));
        }
      });
  };
}
export function getTipjarData(query, token) {
  return (dispatch) => {
    dispatch(balanceActions.getTipJarDataStart());
    UserService.getTipjarData(query, token)
      .then((response) => {
        dispatch(
          balanceActions.getTipJarDataSuccess(response.data.tipjar_details)
        );
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(balanceActions.getTipJarDataError(error.response.status));
        } else {
          dispatch(
            balanceActions.getTipJarDataError(error.response.statusText)
          );
        }
      });
  };
}
export function getTipjarMembers(query, token) {
  return (dispatch) => {
    dispatch(tipjarActions.getTipJarMembersStart());
    UserService.getTipjarData(query, token)
      .then((response) => {
        dispatch(tipjarActions.getTipJarMembersSuccess(response?.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(tipjarActions.getTipJarMembersError(error.response.status));
        } else {
          dispatch(
            tipjarActions.getTipJarMembersError(error.response.statusText)
          );
        }
      });
  };
}

export function getTipjarGroups(query, token) {
  return (dispatch) => {
    dispatch(tipjarActions.getTipJarGroupsStart());
    UserService.getTipjarGroups(query, token)
      .then((response) => {
        dispatch(tipjarActions.getTipJarGroupsSuccess(response?.data?.results));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(tipjarActions.getTipJarGroupsError(error.response.status));
        } else {
          dispatch(
            tipjarActions.getTipJarGroupsError(error.response.statusText)
          );
        }
      });
  };
}
export function getTipjarMembersSelected() {
  return (dispatch) => {
    dispatch(tipjarActions.getTipjarMemberSelectedStart());

    const selected = localStorage.getItem("selected");
    dispatch(
      tipjarActions.getTipjarMemberSelectedSuccess(JSON.parse(selected))
    );
  };
}

export function postTipjarMembersSelected(value) {
  return (dispatch) => {
    dispatch(tipjarActions.postTipjarMemberSelectedStart());

    localStorage.setItem("selected", JSON.stringify(value));
    dispatch(tipjarActions.postTipjarMemberSelectedSuccess(value));
  };
}

export function getTipjarGroup(query, token) {
  return (dispatch) => {
    dispatch(tipjarActions.getTipJarGroupStart());
    UserService.getTipjarGroup(query, token)
      .then((response) => {
        dispatch(tipjarActions.getTipJarGroupSuccess(response?.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(tipjarActions.getTipJarGroupError(error.response.status));
        } else {
          dispatch(
            tipjarActions.getTipJarGroupError(error.response.statusText)
          );
        }
      });
  };
}
export function postTipjarGroups(query, token) {
  return (dispatch) => {
    dispatch(tipjarActions.postTipJarGroupsStart);
    UserService.postTipjarGroups(query, token)
      .then((response) => {
        dispatch(tipjarActions.postTipJarGroupsSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(tipjarActions.postTipJarGroupsError(errorMsg));
      });
  };
}
export function Disburse(query, token) {
  return (dispatch) => {
    dispatch(tipjarActions.tipjarTippingStart);

    UserService.disburseTipjar(query, token)
      .then((response) => {
        dispatch(tipjarActions.tipjarTippingSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(tipjarActions.tipjarTippingError(errorMsg));
      });
  };
}
export function CheckDisbursement(query, token) {
  return (dispatch) => {
    dispatch(tipjarActions.tipjarCheckTippingStart);
    UserService.getDisbursedTipjar(query, token)
      .then((response) => {
        dispatch(tipjarActions.tipjarCheckTippingSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(tipjarActions.tipjarCheckTippingError(errorMsg));
      });
  };
}
export function postTipjarMembers(query) {
  return (dispatch) => {
    dispatch(tipjarActions.postTipJarMembersStart);
    UserService.postTipjarMembers(query)
      .then((response) => {
        dispatch(tipjarActions.postTipJarMembersSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(tipjarActions.postTipJarMembersError(errorMsg));
      });
  };
}
export function updateTipjarMembers(params, query) {
  return (dispatch) => {
    dispatch(tipjarActions.postTipJarMembersStart);
    UserService.updateTipjarMembers(params, query)
      .then((response) => {
        dispatch(tipjarActions.postTipJarMembersSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(tipjarActions.postTipJarMembersError(errorMsg));
      });
  };
}
export function deleteTipjarGroup(query) {
  return (dispatch) => {
    dispatch(tipjarActions.deleteTipJarGroupStart);
    UserService.deleteTipjarGroup(query)
      .then((response) => {
        dispatch(tipjarActions.deleteTipJarGroupSuccess({}));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(tipjarActions.deleteTipJarGroupError(errorMsg));
      });
  };
}

export function resetPassword(query, token) {
  return (dispatch) => {
    dispatch(resetActions.resetPasswordStart());

    UserService.resetPassword(query, token)
      .then((response) => {
        dispatch(resetActions.resetPasswordSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;

        dispatch(resetActions.resetPasswordError(errorMsg));
      });
  };
}

export function verifyOtpCode(query) {
  return (dispatch) => {
    dispatch(resetActions.verifyOtpStart());
    UserService.verifyOtp(query)
      .then((response) => {
        dispatch(resetActions.verifyOtpSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(resetActions.verifyOtpError(errorMsg));
      });
  };
}

export function requestOtpCode(query, token) {
  return (dispatch) => {
    dispatch(registrationActions.requestOtpStart());
    UserService.requestOtp(query, token)
      .then((response) => {
        dispatch(registrationActions.requestOtpSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(registrationActions.requestOtpError(errorMsg));
      });
  };
}

export function confirmOtpCode(query) {
  return (dispatch) => {
    dispatch(registrationActions.confirmOtpStart());
    UserService.verifyOtp(query)
      .then((response) => {
        dispatch(registrationActions.confirmOtpSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(registrationActions.confirmOtpError(errorMsg));
      });
  };
}

export function forgotPassword(query, token) {
  return (dispatch) => {
    dispatch(resetActions.forgotPasswordStart());
    UserService.forgotPassword(query, token)
      .then((response) => {
        dispatch(resetActions.forgotPasswordSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response.data;

        dispatch(resetActions.forgotPasswordError(errorMsg));
      });
  };
}

export function changePassword(query, token) {
  return (dispatch) => {
    dispatch(resetActions.changePasswordStart());
    UserService.changePassword(query, token)
      .then((response) => {
        dispatch(resetActions.changePasswordSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response;
        dispatch(resetActions.changePasswordError(errorMsg));
      });
  };
}

export function depositAmount(query, token) {
  return (dispatch) => {
    dispatch(depositActions.depositLoadStart());
    UserService.deposit(query, token)
      .then((response) => {
        dispatch(depositActions.depositLoadSuccess(response.data));
        let statusPayload = response.data.data;

        if (!statusPayload.reference) {
          setTimeout(() => {
            dispatch(checkDepositStatus(statusPayload.id, token));
          }, 20000);
        } else if (query.user_card_id && query.method === "paystack") {
          setTimeout(() => {
            dispatch(checkDepositStatus(statusPayload.id, token));
          }, 15000);
        } else {
        }
      })
      .catch((error) => {
        let errorMsg = error.response;
        dispatch(depositActions.depositLoadError(errorMsg));
      });
  };
}
export function checkDepositStatus(query, token) {
  return (dispatch) => {
    dispatch(depositActions.checkTransactionStart());
    UserService.checkTransactions(query, token)
      .then((response) => {
        if (response.status === 204) {
          dispatch(depositActions.checkTransactionSuccess(response.data));
        } else {
          dispatch(depositActions.checkTransactionSuccess(response.data));
          setTimeout(() => {
            dispatch(depositActions.checkTransactionSuccess({}));
            dispatch(
              depositActions.checkTransactionRedirect({ navigate: "/" })
            );
          }, 4000);
        }
        // dispatch(depositActions.checkTransactionSuccess(response.data))
      })
      .catch((error) => {
        let errorMsg = error.response
          ? error.response.data
          : error.request.data;
        dispatch(balanceActions.balanceLoadStart());
        dispatch(depositActions.checkTransactionError(errorMsg));
      });
  };
}

export function withdraw(query, token) {
  return (dispatch) => {
    dispatch(withdrawActions.withdrawLoadStart());
    UserService.withdraw(query, token)
      .then((response) => {
        dispatch(withdrawActions.withdrawLoadSuccess(response.data));
        let statusPayload = response.data.data.id;

        setTimeout(() => {
          dispatch(checkTransactionStatus(statusPayload, token));
        }, 16000);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(withdrawActions.withdrawLoadError(errorMsg));
      });
  };
}

export function checkTransactionStatus(query, token) {
  return (dispatch) => {
    dispatch(withdrawActions.checkTransactionStart());
    UserService.checkTransactions(query, token)
      .then((response) => {
        dispatch(withdrawActions.checkTransactionSuccess(response.data));
        setTimeout(() => {
          dispatch(withdrawActions.checkTransactionSuccess({}));
          dispatch(withdrawActions.checkTransactionRedirect({ navigate: "/" }));
        }, 4000);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        dispatch(withdrawActions.checkTransactionError(errorMsg));
      });
  };
}

export function joinSaccoPassword(query, token) {
  return (dispatch) => {
    dispatch(resetActions.changePasswordStart());
    UserService.changePassword(query, token)
      .then((response) => {
        dispatch(resetActions.changePasswordSuccess(response.data));
      })
      .catch((error) => {
        let errorMsg = error.response;
        dispatch(resetActions.changePasswordError(errorMsg));
      });
  };
}

export function joinSacco(profileQuery, passwordQuery, token) {
  return (dispatch) => {
    dispatch(resetActions.updateProfileStart());

    UserService.updateProfile(profileQuery, token)
      .then((response) => {
        dispatch(resetActions.updateProfileSuccess(response.data));

        dispatch(joinSaccoPassword(passwordQuery, token));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(resetActions.updateProfileError(error.response.status));
        } else {
          let errorMsg = error.response;
          dispatch(resetActions.updateProfileError(errorMsg));
        }
      });
  };
}

export function updateProfile(query, token) {
  return (dispatch) => {
    dispatch(resetActions.updateProfileStart());

    UserService.updateProfile(query, token)
      .then((response) => {
        userStore.getState().setUserInfo({
          ...response.data?.data,
          ...userStore.getState().user,
        });

        dispatch(resetActions.updateProfileSuccess(response.data));
        // dispatch(balanceActions.getUserDataSuccess(response.data.data))
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(resetActions.updateProfileError(error.response.status));
        } else {
          let errorMsg = error.response;
          dispatch(resetActions.updateProfileError(errorMsg));
        }
      });
  };
}

export function submitRatings(query, token) {
  return (dispatch) => {
    dispatch(ratingActions.submitRatingStart());
    UserService.submitRatings(query, token)
      .then((response) => {
        dispatch(ratingActions.submitRatingSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(ratingActions.submitRatingError(error.response.status));
        } else {
          let errorMsg = error.response;
          dispatch(ratingActions.submitRatingError(errorMsg));
        }
      });
  };
}

export function getRatings(query, token) {
  return (dispatch) => {
    dispatch(ratingActions.getRatingStart());
    UserService.getRatings(query, token)
      .then((response) => {
        dispatch(ratingActions.getRatingSuccess(response.data));
      })
      .catch((error) => {
        dispatch(ratingActions.getRatingError(error.response));
      });
  };
}

export function getBusinessRatings(query, token) {
  return (dispatch) => {
    dispatch(ratingActions.getBusinessRatingStart());
    UserService.getBusinessRatings(query, token)
      .then((response) => {
        dispatch(ratingActions.getBusinessRatingSuccess(response.data));
      })
      .catch((error) => {
        dispatch(ratingActions.getBusinessRatingError(error.response));
      });
  };
}

export function redeemReward(token) {
  return (dispatch) => {
    dispatch(saccoActions.redeemRewardStart());
    UserService.redeemReward(token)
      .then((response) => {
        if (response.data.success) {
          dispatch(saccoActions.redeemRewardSuccess(response.data));
        } else {
          dispatch(saccoActions.redeemRewardError(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(saccoActions.redeemRewardError(error.response.status));
        } else {
          let errorMsg = error.response;
          dispatch(saccoActions.redeemRewardError(errorMsg));
        }
      });
  };
}

export function getReferrals(token) {
  return (dispatch) => {
    dispatch(saccoActions.getReferralsStart());
    UserService.getReferrals(token)
      .then((response) => {
        dispatch(saccoActions.getReferralsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saccoActions.getReferralsError(error.response));
      });
  };
}

export function getRedeemableReferrals(token) {
  return (dispatch) => {
    dispatch(saccoActions.getRedeemableReferralsStart());
    UserService.getRedeemableReferrals(token)
      .then((response) => {
        dispatch(saccoActions.getRedeemableReferralsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saccoActions.getRedeemableReferralsError(error.response));
      });
  };
}

export function getReservationHistory(token) {
  return (dispatch) => {
    dispatch(reservationActions.getReservationHistoryStart());
    UserService.getReservationHistory(token)
      .then((response) => {
        dispatch(
          reservationActions.getReservationHistorySuccess(response.data)
        );
      })
      .catch((error) => {
        dispatch(reservationActions.getReservationHistoryError(error.response));
      });
  };
}

export function searchRestaurant(query, token) {
  return (dispatch) => {
    dispatch(reservationActions.searchRestaurantStart());
    UserService.searchRestaurant(query, token)
      .then((response) => {
        dispatch(
          reservationActions.searchRestaurantSuccess(response.data.data)
        );
      })
      .catch((error) => {
        dispatch(reservationActions.searchRestaurantError(error.response));
      });
  };
}

export function makeReservation(query, token) {
  return (dispatch) => {
    dispatch(reservationActions.makeReservationStart());
    UserService.makeReservation(query, token)
      .then((response) => {
        dispatch(reservationActions.makeReservationSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(reservationActions.makeReservationError(error.response));
      });
  };
}
