import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reservationActions } from "../../state/reducers/reservation/reservation.actions";
import { searchRestaurant } from "../../state/reducers/user/user.thunk";

const AutoComplete = ({
  setPlace,
  setAutocompleteVal,
  inputRef,
  isReservation,
  setBusinessError,
  place,
}) => {
  const autoCompleteRef = useRef();
  const options = {
    componentRestrictions: { country: ["ke", "qa"] },
    fields: [
      "formatted_address",
      "geometry",
      "photo",
      "type",
      "name",
      "place_id",
    ],
    types: ["establishment"],
  };

  const dispatch = useDispatch();

  const userAccount = useSelector((state) => state.account);

  useEffect(() => {
    if (window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener(
        "place_changed",
        async function () {
          const place = await autoCompleteRef.current.getPlace();
            setPlace({
            address: place.formatted_address,
            photo: place.photos ? place?.photos[0]?.getUrl() : "",
            name: place.name,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          if (isReservation) {
            dispatch(
              searchRestaurant(place.place_id, userAccount.userDetails.token)
            );
          }
        },
        { passive: true }
      );
    }
  }, []);

  return (
    <input
      placeholder="Fogo Gaucho"
      onFocus={() => setBusinessError("")}
      ref={inputRef}
      // value={place?.address}
      onChange={(e) => setAutocompleteVal(e.target.value)}
      className={`
      ${
        isReservation
          ? `text-sm text-white placeholder:text-white block truncate ... p-4 bg-primary-green ${
              place.photo
                ? "pl-3 w-5/6 rounded-l-full"
                : "pl-10 w-full rounded-full"
            } drop-shadow-2xl`
          : "bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
      }
    `}
    />
  );
};
export default AutoComplete;
