import React, { useEffect, useMemo, useState } from "react";
import { Avatar } from "../../components-shadcn/ui/avatar";
import { Button } from "../../components-shadcn/ui/button";
import { Card } from "../../components-shadcn/ui/card";
import { Input } from "../../components-shadcn/ui/input";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { ChevronLeft, X, Edit2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import BackButton from "../globals/BackButton";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import EntityJarIcon from "../../assets/Images/entity-jar-icon.png";
import IndividualJarIcon from "../../assets/Images/individual-jar-icon.png";
import GroupJarIcon from "../../assets/Images/group-tipjar-icon.png";
import { OctagonAlert } from "lucide-react";
import TipAmount from "../tipping/TipAmount";

const ConfirmGroupTipAmount = () => {
  const {
    entityToTip,
    entityJARToTip,
    selectedIndividualsToTip,
    entityTipAmount,
    entityJARTipAmount,
    selectedIndividualsTipAmount,
    setEntityToTip,
    setEntityJARToTip,
    setSelectedIndividualsToTip,
    setEntityTipAmount,
    setEntityJARTipAmount,
    setSelectedIndividualsTipAmount,
    setTotalAmountToTip,
    selectedCurrency,
  } = useTipJarLocalState();

  const [editingId, setEditingId] = useState(null);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const removeItem = (type, id) => {
    if (type === "entity") {
      setEntityToTip(null);
      const newEntityTipAmount = { ...entityTipAmount };
      delete newEntityTipAmount[id];
      setEntityTipAmount(newEntityTipAmount);
    } else if (type === "entityJAR") {
      setEntityJARToTip(null);
      const newEntityJARTipAmount = { ...entityJARTipAmount };
      delete newEntityJARTipAmount[id];
      setEntityJARTipAmount(newEntityJARTipAmount);
    } else if (type === "individual") {
      setSelectedIndividualsToTip(
        selectedIndividualsToTip.filter(
          (individual) => individual.user.shukran_id !== id
        )
      );
      const newSelectedIndividualsTipAmount = {
        ...selectedIndividualsTipAmount,
      };
      delete newSelectedIndividualsTipAmount[id];
      setSelectedIndividualsTipAmount(newSelectedIndividualsTipAmount);
    }
  };

  const handleAmountEdit = (type, id, newAmount) => {
    if (type === "entity") {
      setEntityTipAmount({ ...entityTipAmount, [id]: newAmount });
    } else if (type === "entityJAR") {
      setEntityJARTipAmount({ ...entityJARTipAmount, [id]: newAmount });
    } else if (type === "individual") {
      setSelectedIndividualsTipAmount({
        ...selectedIndividualsTipAmount,
        [id]: newAmount,
      });
    }
    setEditingId(null);
  };

  const tipItems = useMemo(() => {
    const items = [];

    if (entityToTip && entityToTip.id) {
      items.push({
        id: entityToTip.id,
        name: entityToTip.name,
        code: entityToTip.id,
        amount: entityTipAmount[entityToTip.id] || 0,
        type: "entity",
      });
    }

    if (entityJARToTip && entityJARToTip.id) {
      items.push({
        id: entityJARToTip.id,
        name: entityJARToTip.name,
        code: entityJARToTip.shukran_id,
        amount: entityJARTipAmount[entityJARToTip.id] || 0,
        type: "entityJAR",
      });
    }

    selectedIndividualsToTip.forEach((individual) => {
      if (individual && individual.user && individual.user.shukran_id) {
        items.push({
          id: individual.user.shukran_id,
          name: `${individual.user.first_name} ${individual.user.last_name}`,
          code: individual.user.shukran_id,
          amount: selectedIndividualsTipAmount[individual.user.shukran_id] || 0,
          type: "individual",
          initials: `${individual.user.first_name[0]}${individual.user.last_name[0]}`,
        });
      }
    });

    return items;
  }, [
    entityToTip,
    entityJARToTip,
    selectedIndividualsToTip,
    entityTipAmount,
    entityJARTipAmount,
    selectedIndividualsTipAmount,
  ]);

  const totalAmount = useMemo(() => {
    const total = tipItems.reduce(
      (sum, item) => sum + parseFloat(item.amount || 0),
      0
    );
    setTotalAmountToTip(total);
    return total;
  }, [tipItems]);

  const handleProceedClicked = () => {
    navigate("/tipjar/select-payment-method/");
  };

  return (
    <div className="flex flex-col px-3 w-full h-screen px-2">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>

      <h1 className="text-2xl font-semibold text-[#6CCBD1] mb-2 mt-4">
        Confirm total
      </h1>

      {tipItems.length === 0 && (
        <div className="flex justify-center items-center h-full">
          <div className="w-full flex flex-col gap-2 items-center">
            <OctagonAlert className="w-10 h-10" />
            <h1 className="font-semibold text-[#6CCBD1] mb-2">
              No tip recipient(s)
            </h1>
          </div>
        </div>
      )}

      {tipItems.map((item) => (
        <React.Fragment key={item.id}>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <Avatar className="h-10 w-10 mr-3">
                {item.type === "individual" && (
                  <div className="bg-[#ABDADD] text-white w-full h-full flex items-center justify-center">
                    {item.initials}
                  </div>
                )}

                {item.type === "entityJAR" && (
                  <div className="bg-[#C5B7DF] text-white w-full h-full flex items-center justify-center">
                    <img src={GroupJarIcon} alt="icon" />
                  </div>
                )}

                {item.type === "entity" && (
                  <div className="bg-[#F5E1BA] text-white w-full h-full flex items-center justify-center">
                    <img src={EntityJarIcon} alt="icon" />
                  </div>
                )}
              </Avatar>
              <div>
                <p className="font-medium">{item.name}</p>
                <p className="text-sm text-gray-500">{item.code}</p>
              </div>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => removeItem(item.type, item.id)}
              className="text-red-500"
            >
              Remove
            </Button>
          </div>
          <Card className="p-3 mb-3 flex justify-between items-center shadow-md rounded-full">
            {editingId === item.id ? (
              <Input
                type="number"
                defaultValue={item.amount}
                className="w-24"
                onBlur={(e) =>
                  handleAmountEdit(item.type, item.id, e.target.value)
                }
                autoFocus
              />
            ) : (
              <span className="font-medium">
                Amount: {selectedCurrency}. {item.amount}
              </span>
            )}
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setEditingId(item.id)}
            >
              <Edit2 className="h-4 w-4 text-gray-500" />
            </Button>
          </Card>
          <hr className="my-3 border-gray-200" />
        </React.Fragment>
      ))}

      <div className="mt-6 mb-4">
        <p className="text-lg font-medium">
          Total: {selectedCurrency}. {totalAmount.toLocaleString()}
        </p>
      </div>

      <div className="w-full pb-2">
        <Button
          className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white rounded-full h-[60px]"
          onClick={handleProceedClicked}
        >
          PROCEED
        </Button>
      </div>
    </div>
  );
};

export default ConfirmGroupTipAmount;
