import { create, StateCreator } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  PersistOptions,
} from "zustand/middleware";

interface ActiveEntitySlice {
  activeEntity: any;
  setActiveEntity: (entity: any) => void;
  entityMembers: any[];
  setEntityMembers: (entityMembers: any[]) => void;
  entityJARs: any[];
  setEntityJARs: (entityJARs: any[]) => void;
}
interface DefaultEntityJarDataSlice {
  defaultEntityJAR: any;
  setDefaultEntityJAR: (defaultEntityJAR: any) => void;
}

interface MainTipJARSlice {
  mainTipJar: any;
  setMainTipJar: (tipJarInfo: any) => void;
}

interface ActiveEntityJARSlice {
  activeEntityJAR: any;
  setActiveEntityJAR: (activeTipJAR: any) => void;
}

interface ActiveEntityJARGroupSlice {
  activeEntityJARGroup: any;
  setActiveEntityJARGroup: (activeEntityJARGroup: any) => void;
}

interface EntityJarMembersSlice {
  entityJARMembers: any[];
  selectedType: string | null;
  setSelectedType: (type: string) => void;
  setEntityJARMembers: (entityJARMembers: any[]) => void;
}

interface SelectedCurrencySlice {
  selectedCurrency: string;
  setSelectedCurency: (currency: string) => void;
}

interface ActiveDepartmentSlice {
  entityDepartments: any[];
  activeDepartment: any;
  setActiveDepartment: (activeDepartment: any) => void;
  setDepartmentList: (departmentList: any[]) => any;
}

interface AmountToDisburseFromJarSlice {
  amountToDisburseFromDefaultJar: number | null;
  setAmountToDisburseFromDefaultJar: (amount: number) => void;
}

interface TipJarReportSlice {
  tipjarReport: any;
  setTipJarReport: (tipjarReport: any) => void;
  activeTipJarReport: any;
  setActiveTipJarReport: (activeTipJarReport: any) => void;
}

// Add Reset interface
interface ResetSlice {
  reset: () => void;
}

// slice that handles & keeps track of the tippers information when they want to tip an entity, entityJAR or individuals
interface EntityTipDataSlice {
  entityToTip: any;
  entityJARToTip: any;
  selectedIndividualsToTip: any[];
  entityTipAmount: any;
  entityJARTipAmount: any;
  selectedIndividualsTipAmount: any[];
  totalAmountToTip: any;
  setTotalAmountToTip: (totalAmount: any) => any;
  setEntityToTip: (entity: any) => void;
  setEntityJARToTip: (entityJAR: any) => void;
  setSelectedIndividualsToTip: (selectedIndividuals: any[]) => void;
  setEntityTipAmount: (entityAmount: any) => void;
  setEntityJARTipAmount: (entityJARAmount: any) => void;
  setSelectedIndividualsTipAmount: (selectedIndividualsAmount: any[]) => void;
}

// Combined type for the store
type TipJarStore = ActiveEntitySlice &
  MainTipJARSlice &
  ActiveEntityJARSlice &
  ActiveEntityJARGroupSlice &
  ActiveDepartmentSlice &
  EntityJarMembersSlice &
  EntityTipDataSlice &
  SelectedCurrencySlice &
  DefaultEntityJarDataSlice &
  AmountToDisburseFromJarSlice &
  ResetSlice &
  TipJarReportSlice;

// Define initial state
const initialState = {
  activeEntity: null,
  entityMembers: [],
  entityJARs: [],
  defaultEntityJAR: null,
  mainTipJar: null,
  activeEntityJAR: null,
  activeEntityJARGroup: null,
  entityJARMembers: [],
  selectedType: null,
  selectedCurrency: "KES",
  entityDepartments: [],
  activeDepartment: null,
  entityToTip: null,
  entityJARToTip: null,
  selectedIndividualsToTip: [],
  entityTipAmount: null,
  entityJARTipAmount: null,
  selectedIndividualsTipAmount: [],
  amountToDisburseFromDefaultJar: null,
  totalAmountToTip: null,
};

const createResetSlice: StateCreator<TipJarStore, [], [], ResetSlice> = (
  set
) => ({
  reset: () => {
    // Clear localStorage
    localStorage.removeItem("tipjar-storage");
    // Reset state to initial values
    set(initialState);
  },
});

const createAmountToDisburseFromDefaultJar: StateCreator<
  TipJarStore,
  [],
  [],
  AmountToDisburseFromJarSlice
> = (set) => ({
  amountToDisburseFromDefaultJar: null,
  setAmountToDisburseFromDefaultJar: (amount) =>
    set({ amountToDisburseFromDefaultJar: amount }),
});

const createSelectedCurrencySlice: StateCreator<
  TipJarStore,
  [],
  [],
  SelectedCurrencySlice
> = (set) => ({
  selectedCurrency: "KES",
  setSelectedCurency: (currency) => set({ selectedCurrency: currency }),
});

const createDefaultEntityJarData: StateCreator<
  TipJarStore,
  [],
  [],
  DefaultEntityJarDataSlice
> = (set) => ({
  defaultEntityJAR: null,
  setDefaultEntityJAR: (defaultEntityJAR) =>
    set({ defaultEntityJAR: defaultEntityJAR }),
});

const createTipJarReportSlice: StateCreator<
  TipJarStore,
  [],
  [],
  TipJarReportSlice
> = (set) => ({
  tipjarReport: null,
  setTipJarReport: (tipjarReport: any) => set({ tipjarReport: tipjarReport }),
  activeTipJarReport: null,
  setActiveTipJarReport: (activeTipJarReport: any) =>
    set({ activeTipJarReport: activeTipJarReport }),
});

const createEntityTipDataSlice: StateCreator<
  TipJarStore,
  [],
  [],
  EntityTipDataSlice
> = (set) => ({
  entityToTip: null,
  entityJARToTip: null,
  selectedIndividualsToTip: [],
  entityTipAmount: null,
  entityJARTipAmount: null,
  selectedIndividualsTipAmount: [],
  totalAmountToTip: null,
  setEntityToTip: (entity: any) => set({ entityToTip: entity }),
  setEntityJARToTip: (entityJAR: any) => set({ entityJARToTip: entityJAR }),
  setSelectedIndividualsToTip: (selectedIndividuals: any[]) =>
    set({ selectedIndividualsToTip: selectedIndividuals }),
  setEntityTipAmount: (entityAmount: any) =>
    set({ entityTipAmount: entityAmount }),
  setEntityJARTipAmount: (entityJARAmount: any) =>
    set({ entityJARTipAmount: entityJARAmount }),
  setSelectedIndividualsTipAmount: (selectedIndividualsAmount: any[]) =>
    set({ selectedIndividualsTipAmount: selectedIndividualsAmount }),
  setTotalAmountToTip: (totalAmount: number | null) =>
    set({ totalAmountToTip: totalAmount }),
});

const createActiveEntitySlice: StateCreator<
  TipJarStore,
  [],
  [],
  ActiveEntitySlice
> = (set) => ({
  activeEntity: null,
  setActiveEntity: (entity: any) => set({ activeEntity: entity }),
  entityMembers: [],
  setEntityMembers: (entityMembers: any[]) =>
    set({ entityMembers: entityMembers }),
  entityJARs: [],
  setEntityJARs: (entityJars: any[]) => set({ entityJARs: entityJars }),
});

const createMainTipJARSlice: StateCreator<
  TipJarStore,
  [],
  [],
  MainTipJARSlice
> = (set) => ({
  mainTipJar: null,
  setMainTipJar: (mainTipJar: any) => set({ mainTipJar: mainTipJar }),
});

const createActiveEntityJARSlice: StateCreator<
  TipJarStore,
  [],
  [],
  ActiveEntityJARSlice
> = (set) => ({
  activeEntityJAR: null,
  setActiveEntityJAR: (activeEntityJAR: any) =>
    set({ activeEntityJAR: activeEntityJAR }),
});

const createActiveEntityJARGroup: StateCreator<
  TipJarStore,
  [],
  [],
  ActiveEntityJARGroupSlice
> = (set) => ({
  activeEntityJARGroup: null,
  setActiveEntityJARGroup: (activeEntityJARGroup: any) =>
    set({ activeEntityJARGroup: activeEntityJARGroup }),
});

const createEntityJARMembersSlice: StateCreator<
  TipJarStore,
  [],
  [],
  EntityJarMembersSlice
> = (set) => ({
  selectedType: null,
  entityJARMembers: [],
  setEntityJARMembers: (members: any[]) => set({ entityJARMembers: members }),
  setSelectedType: (type: string) => set({ selectedType: type }),
});

const createActiveDepartemntSlice: StateCreator<
  TipJarStore,
  [],
  [],
  ActiveDepartmentSlice
> = (set) => ({
  entityDepartments: [],
  activeDepartment: null,
  setActiveDepartment: (activeDepartment: any) =>
    set({ activeDepartment: activeDepartment }),
  setDepartmentList: (departmentList: any[]) =>
    set({ entityDepartments: departmentList }),
});

// Persist configuration
type PersistState = {
  version: number;
  data: TipJarStore;
};

const persistOptions: PersistOptions<TipJarStore> = {
  name: "tipjar-storage",
  storage: createJSONStorage(() => localStorage),
  version: 1, // Increment this when you make breaking changes to the store structure
  partialize: (state: any) => ({
    ...state,
    // Exclude any properties you don't want to persist
    reset: undefined,
  }),

  onRehydrateStorage: (state: TipJarStore) => {
    // Optional: Handle any logic when the store is rehydrated
    return (error: any) => {
      if (error) {
        console.log("Rehydration error ---> ", error);
      }
    };
  },
};

export const useTipJarLocalState = create<TipJarStore>()(
  persist(
    (...a) => ({
      ...createActiveEntitySlice(...a),
      ...createMainTipJARSlice(...a),
      ...createActiveEntityJARSlice(...a),
      ...createActiveEntityJARGroup(...a),
      ...createActiveDepartemntSlice(...a),
      ...createEntityJARMembersSlice(...a),
      ...createEntityTipDataSlice(...a),
      ...createSelectedCurrencySlice(...a),
      ...createDefaultEntityJarData(...a),
      ...createResetSlice(...a),
      ...createAmountToDisburseFromDefaultJar(...a),
      ...createTipJarReportSlice(...a),
    }),
    persistOptions
  )
);

// Optional: Export a method to clear persisted data
export const clearPersistedState = () => {
  localStorage.removeItem("tipjar-storage");
};
