import { combineReducers } from "redux";
import { getFromSessionStorage } from "../../Services/Storage";
import businessReducer from "./business/business.reducer";
import depositReducer from "./deposit/deposit.reducer";
import updateProfileReducer from "./profile/profile.reducer";
import ratingsReducer from "./rating/rating.reducer";
import registrationReducer from "./registration/registration.reducer";
import resetReducer from "./reset/reset.reducer";
import saccoReducer from "./sacco/sacco.reducer";
import searchReducer from "./search/search.reducer";
import subscriptionReducer from "./subscription/subscription.reducer";
import tippingReducer from "./tipping/tippingReducer";
import transactionReducer from "./transaction/transaction.reducer";
import balanceReducer from "./user/user.reducer";
import withdrawReducer from "./withdraw/withdraw.reducer";
import reservationReducer from "./reservation/reservation.reducer";
import getTipJarInfo from "./tipjar/tipjar.reducer";

//get token from sesion storage
const getToken = () => {
  let token = getFromSessionStorage("token");
  return token ? true : false;
};

const userAccountData = (
  state = {
    balance: 0,
    isDisplayed: true,
    isDisplayedPassword: true,

    isAuthenticated: getToken(),
    userDetails: {
      firstName: getFromSessionStorage("firstName"),
      shukranId: getFromSessionStorage("ShukranId"),
      token: getFromSessionStorage("token"),
      phoneNumber: getFromSessionStorage("phoneNumber"),
      depositNumber: getFromSessionStorage("phoneNumber"),
      withdrawNumber: getFromSessionStorage("phoneNumber"),
      balance: getFromSessionStorage("balance"),
    },
  },
  action
) => {
  switch (action.type) {
    case "registerUser":
      return {
        ...state,
        user: action.payload, // check important info
        isAuthenticated: true,
        userDetails: {
          firstName: action.payload.first_name,
          shukranId: action.payload.shukran_id,
          token: action.payload.token,
          phoneNumber: action.payload.phone,
          depositNumber: action.payload.phone,
          withdrawNumber: action.payload.phone,
          balance: action.payload.balance,
        },
      };
    case "registerToken":
      return {
        ...state,
        user: action.payload, // check important info
        isAuthenticated: true,
        userDetails: {
          ...state.userDetails,
          phoneNumber: action.payload.phone,
          token: action.payload.token,
        },
      };

    case "userLoggedIn":
      return {
        ...state,
        loginData: action.payload,
        isAuthenticated: true,
        userDetails: {
          firstName: action.payload.first_name,
          shukranId: action.payload.shukran_id,
          token: action.payload.token,
          phoneNumber: action.payload.phone,
          depositNumber: action.payload.phone,
          withdrawNumber: action.payload.phone,
          balance: action.payload.balance,
        },
      };

    case "fetchUser":
      return {
        ...state,
        user: action.payload,
        userDetails: {
          ...state.userDetails,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          shukranId: action.payload.shukran_id,
          phoneNumber: action.payload.phone,
        },
      };
    case "addMpesaNo":
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          phoneNumber: action.payload.mpesa_no,
        },
      };
    case "userLogOut":
      return {
        ...state,
        isAuthenticated: false,
        userDetails: {},
      };

    case "fetchOTP":
      return { ...state, isSuccess: action.payload };

    case "submitOTP":
      return { ...state, isSignedIn: true };

    case "setDepositAmount":
      return {
        ...state,
        depositAmount: action.payload,
      };

    case "updateDepositNumber": {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          depositNumber: action.payload,
        },
        // depositNumber: action.payload,
      };
    }

    case "updateDepositedAmount": {
      return {
        ...state,
        depositedAmount: action.payload,
      };
    }
    case "setWithdrawAmount":
      return {
        ...state,
        withdrawAmount: action.payload,
      };

    case "updateWithdrawNumber":
      return {
        ...state,
        withdrawNumber: action.payload,
      };

    case "hasTipped":
      return {
        ...state,
        balance: action.payload,
      };

    case "login":
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.data,
      };

    case "toggelBal":
      return {
        ...state,
        isDisplayed: !state["isDisplayed"],
      };

    case "toggelPassword":
      return {
        ...state,
        isDisplayedPassword: !state["isDisplayedPassword"],
      };

    case "keepLoggeIn":
      return {
        ...state,
        keepLoggeIn: true,
      };

    case "upDateBalance":
      return {
        ...state,
        balance: action.balance,
      };

    default:
      return state;
  }
};

const userTransactions = (state = {}, action) => {
  switch (action.type) {
    case "allUserTransactions":
      return { ...state, transactions: action.payload };
    default:
      // need this for default case
      return state;
  }
};

const TipData = (state = {}, action) => {
  switch (action.type) {
    case "TipAddId":
      return {
        ...state,
        shukranId: action.payload.shukran_id,
        receiverName: action.payload.first_name,
        receiverLastName: action.payload.last_name,
      };
    case "addTipAmount":
      return { ...state, tipAmount: action.amount };
    case "addCurrency":
      return { ...state, currency: action.currency };
    case "addNonShukranId":
      return {
        ...state,
        nonShukranId: action.payload.non_shukran_id,
      };
    case "addQueryShukranId":
      return {
        ...state,
        queryShukranId: action.payload.query_shukran_id,
        queryName: action.payload.query_name,
        currency: action.payload.currency,
      };

    default:
      // need this for default case
      return state;
  }
};

const ReferrerData = (state = {}, action) => {
  switch (action.type) {
    case "addReferrerId":
      return {
        referrerId: action.payload.referrerId,
      };

    default:
      // need this for default case
      return state;
  }
};
const reducers = combineReducers({
  account: userAccountData,
  reset: resetReducer,
  subscription: subscriptionReducer,
  profile: updateProfileReducer,
  transactions: transactionReducer,
  withdraw: withdrawReducer,
  deposit: depositReducer,
  rating: ratingsReducer,
  tipData: TipData,
  referrerData: ReferrerData,
  search: searchReducer,
  business: businessReducer,
  balance: balanceReducer,
  tipping: tippingReducer,
  registration: registrationReducer,
  sacco: saccoReducer,
  reservations: reservationReducer,
  tipjar: getTipJarInfo,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;
