import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import cross from "../../assets/Icons/Tipping/cross.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import WastingGlass from "../../assets/Icons/wasting_glass.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  checkSaccoTipStatus,
  sendTip,
} from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import { testUrl } from "../../helper";
import { loginUser } from "../../state/actions/registerUser";
import BackButton from "../globals/BackButton";

export default function CreditInputCard() {
  const [cardEmail, setCardEmail] = useState("");
  const [cardResponse, setCardResponse] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.balance.userInfo);
  const tippingState = useSelector((state) => state.tipping);
  const tipData = useSelector((state) => state.tipData);
  const senderData = useSelector((state) => state.account.userDetails);
  const loginData = useSelector((state) => state.account.loginData);
  const balanceState = useSelector((state) => state.balance);

  const [publicKey, setPublicKey] = useState("");
  const [amount, setAmount] = useState("");
  const [saveCard, setSaveCard] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPay, setConfirmPay] = useState(false);
  const shukranId = tipData.shukranId;
  const nonShukranId = tipData.nonShukranId;
  const queryShukranId = tipData.queryShukranId;
  const [emailToken, setEmailToken] = useState("");
  const Text = () => (
    <div className="mt-12 rounded-3xl py-2 bg-primary-pwa text-white text-base uppercase w-52 mx-auto">
      Confirm
    </div>
  );
  const componentProps = {
    email: mail,
    currency: tipData.currency === "USD" ? tipData?.currency : "KES",
    amount: tippingState.tippingResponse.data?.amount * 100,
    metadata: {
      name,
      phone,
    },
    publicKey: tippingState.tippingResponse.data?.key,
    reference: `${tippingState.tippingResponse.data?.reference}`,
    text: <Text />,
    onSuccess: (res) => {
      setConfirmPay(false);
      setCardResponse(true);
      // dispatch(balanceActions.tippingSuccess({}));
      setTimeout(() => {
        dispatch(checkSaccoTipStatus(res.reference, emailToken));
      }, 10000);
    },
    onClose: () => {
      dispatch(balanceActions.tippingSuccess({}));
    },
  };

  useEffect(() => {
    if (!tippingState.isLoading) {
      setLoading(false);
    } else if (tippingState.tippingResponse) {
      setConfirmPay(true);
    }
  }, [tippingState?.isLoading]);
  useEffect(() => {
    if (tipData.tipAmount === undefined) {
      navigate(-1);
    }
  }, []);

  const emailAuthentication = async () => {
    if (tipData.tipAmount === undefined) {
      navigate(-1);
    } else {
      if (mail) {
        setLoading(true);
        const body = {
          email: mail,
        };
        const response = await axios.post(
          `${testUrl}accounts/auth/signup-email-only/otp/`,
          body
        );
        if (response.data?.success) {
          setEmailToken(response?.data?.data?.token);
          PayToPaystack(response?.data?.data?.token);
          setLoading(false);
        }
      }
    }
  };
  const PayToPaystack = (token) => {
    const body = {
      receiver_shukran_id: shukranId
        ? shukranId
        : nonShukranId
        ? nonShukranId
        : queryShukranId
        ? queryShukranId
        : tipData?.phone,

      method: "paystack",
      email: mail,
      user_card_id: "",
      save_card_details: true,
      amount: tipData?.tipAmount,
      currency: tipData?.currency === "USD" ? "USD" : "KES",
    };

    if (token) {
      dispatch(sendTip(body, token));
    }
  };
  useEffect(() => {
    if (tippingState.errorMessage === 403) {
      navigate("/login");
      dispatch(balanceActions.tippingError(""));
    }
    return () => {};
  }, [tippingState, dispatch, navigate]);

  useEffect(() => {
    if (tippingState.navigate.navigate === "/") {
      navigate("/download");
      setCardResponse(false);
      //dispatch(balanceActions.checkTippingRedirect(""));
    }
    return () => {};
  }, [tippingState]);

  useEffect(() => {
    if (tipData) {
      setAmount(tipData.amount);
      let newName = tipData.first_name + " " + tipData.last_name;
      setName(newName);
    }
    return () => {};
  }, []);

  const handleBack = () => {
    dispatch(balanceActions.tippingSuccess({}));
    dispatch(balanceActions.checkTippingSuccess(""));
    navigate(-1);
  };

  const handleUserEmail = (data) => {
    // setCardResponse(true)
    setSaveCard(data.saveCard);
    setCardEmail(data?.email);
    submitTip(data);
  };

  const submitTip = (data) => {
    let depositPhone = userInfo?.phone;
    if (depositPhone) {
      depositPhone = "0" + depositPhone.slice(3);
    }
    depositPhone = depositPhone ? depositPhone : senderData.phoneNumber;
    setPhone(depositPhone);
    setAmount(Number(tipData.tipAmount));
    let payload = {
      amount: Number(tipData.tipAmount),
      tipper_phone_number: depositPhone,
      email: data?.email,
      method: "paystack",
      currency: tipData?.currency === "USD" ? "USD" : "KES",
      user_card_id: "",
      save_card_details: !!data.saveCard,
    };

    localStorage.setItem("token", senderData.token);
    if (tipData.nonShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: tipData.nonShukranId,
        currency: tipData?.currency === "USD" ? "USD" : "KES",
      };

      dispatch(sendTip(payload, senderData.token));
      setCardResponse("");
    } else if (tipData.shukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: tipData.shukranId,
        currency: tipData?.currency === "USD" ? "USD" : "KES",
      };

      dispatch(sendTip(payload, senderData.token));
      setCardResponse("");
    } else if (tipData.queryShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: tipData.queryShukranId,
        currency: tipData?.currency === "USD" ? "USD" : "KES",
      };

      dispatch(sendTip(payload, balanceState.generateResponse.token));
      setCardResponse("");
    } else {
    }
  };

  const ConfirmTipPopup = () => (
    <div className="px-4 mt-28 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-base">Confirm Tip</span>
        <div
          onClick={() => {
            // setCardEmail('');
            // setCardResponse(false)
            dispatch(balanceActions.tippingSuccess({}));
            dispatch(balanceActions.checkTippingSuccess(""));
            setConfirmPay(false);
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-fit">
        <label className="text-subtle text-sm">Tip Recipient</label>
        <div className="flex flex-row justify-between">
          {tipData.nonShukranId ? (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">
                {tipData.nonShukranId}
              </span>
            </div>
          ) : tipData.queryShukranId ? (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">
                {tipData.queryName}
              </span>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">
                {tipData.receiverName + " " + tipData.receiverLastName}
              </span>
              <span className="text-sm text-gray-300">{tipData.shukranId}</span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Tip Amount</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">
              {tipData?.currency} {tipData.tipAmount}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Payment Method</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">Card Payment</span>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-row items-center justify-center">
        {/* <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            onClick={submitTip}
          >
            Proceed
          </button> */}
        <PaystackButton {...componentProps} />
      </div>
    </div>
  );

  const TippingTransactionCard = () => (
    <div className="px-4 mt-28 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col items-center relative pb-4 rounded-3xl z-50">
      {!tippingState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(balanceActions.tippingSuccess({}));
            dispatch(balanceActions.checkTippingSuccess(""));
            // addShukranId({})
            navigate("/");
          }}
        >
          X
        </button>
      )}
      {tippingState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
      )}
      {tippingState.tippingResponse.success &&
        !tippingState.isTransactionLoading && (
          <img src={checkCircle} alt="error-img" className="h-24" />
        )}

      {tippingState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          Confirming transaction status...
        </p>
      )}
      {tippingState.tippingResponse?.success &&
        !tippingState.isTransactionLoading && (
          <p className="justify-self-center	text-base text-center py-2 px-12">
            {tippingState.tippingResponse?.msg}
          </p>
        )}
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {tippingState.errorTransactionStatusMessage.msg && (
        <div className="flex flex-col items-center">
          <img src={WastingGlass} alt="waiting glass" className="w-20 h-20" />
          <p className="justify-self-center	text-base text-center py-2 px-12">
            Tip transaction pending. Kindly be patient as we process your tip
          </p>
        </div>
      )}
      {tippingState.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  const TippingTransactionError = () => (
    <div className="my-auto px-4 mb-28 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.checkTippingError(""));
          dispatch(balanceActions.tippingError(""));
        }}
      >
        X
      </button>
      <img
        src={WastingGlass}
        alt="waiting glass"
        className="w-20 h-20 mx-auto"
      />
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState?.errorMessage?.msg}
        </p>
      )}
      {/* {
        amount: 5,
        method: "paystack",
        receiver_shukran_id: "0724767901",
        save_card_details: false,
        tipper_phone_number: "0724767902",
        user_card_id: ""
      } */}

      {/* Check status of express tipping error responses */}

      {tippingState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
      ${tippingState.errorMessage.error ? "relative" : ""}
      ${tippingState.errorMessage?.msg ? "relative" : ""}
      ${tippingState.isLoading ? "relative" : ""}
      ${confirmPay === true ? "relative" : ""}
      ${loading ? "relative" : ""}
      ${tippingState.tippingResponse.data?.key ? "relative" : ""}
      ${cardResponse ? "relative" : ""}
      ${
        tippingState.errorTransactionStatusMessage ||
        tippingState.errorMessage?.detail
          ? "relative"
          : ""
      } w-full flex flex-col`}
    >
      <div
        className={`
        ${tippingState.errorMessage.error ? "blur-sm" : ""}
        ${tippingState.errorMessage?.msg ? "blur-sm" : ""}

        ${confirmPay === true ? "blur-sm" : ""}
        ${tippingState?.isLoading ? "blur-sm" : ""}
        ${loading ? "blur-sm" : ""}
        ${cardResponse ? "blur-sm" : ""}
        ${
          tippingState.errorTransactionStatusMessage ||
          tippingState.errorMessage?.detail
            ? "blur-sm"
            : ""
        } flex flex-col px-3 w-full h-full`}
      >
        {/* <div className="flex flex-row items-center mt-9">
          <div onClick={() => handleBack()} className="">
            <img src={back} className="" alt="rate-star" />
          </div>
        </div> */}
        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>
        <div className="mx-3 mt-11 mb-5 h-full">
          <label className="text-3xl font-semibold text-primary-pwa">
            Please enter your email to receive card payments receipts.
          </label>
        </div>
        <form
          className="flex flex-col justify-between m-3 h-full"
          onSubmit={handleSubmit(handleUserEmail)}
        >
          <div className="flex flex-col mt-1">
            <label className="my-2 text-label text-xs">Email *</label>
            <input
              className="text-sm block p-4 pl-10 w-full drop-shadow-lg"
              type="email"
              placeholder="jane@gmail.com"
              {...register("email", {
                required: true,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              })}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {errors?.email && (
              <p className="text-red-600 text-sm pt-3">
                Please enter your email
              </p>
            )}
          </div>
          {!tipData.queryShukranId && (
            <div className="flex flex-col mt-5">
              <div className="flex flex-row mt-2 text-sm items-center ">
                <input
                  type="checkbox"
                  className="mr-6 active:bg-primary-pwa"
                  {...register("saveCard", {
                    required: false,
                  })}
                />
                <p className="text-label text-xs">
                  Save card for future use (Secured by Paystack - Regulated by
                  CBK)
                </p>
              </div>
              {errors.saveCard && (
                <p className="text-red-600 text-sm pt-1">
                  Please enter your consent
                </p>
              )}
            </div>
          )}
          <button
            className="rounded-full py-2 mt-6 bg-accent text-white text-base w-full"
            type="button"
            onClick={emailAuthentication}
          >
            CONTINUE
          </button>
        </form>
      </div>
      <div
        className={`${cardResponse ? "absolute blur-none" : "hidden"}
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <TippingTransactionCard />
      </div>
      <div
        className={`${
          loading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
          <span className="text-base text-center">
            SHUKRAN is generating an access key for submitted number
          </span>
        </div>
      </div>
      <div
        className={`${
          tippingState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-20 mx-auto text-sm mb-2`}
        />
      </div>
      <div
        className={`${
          confirmPay ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center w-full h-full`}
      >
        <ConfirmTipPopup />
      </div>
      {/* <div
        className={`${tippingState.tippingResponse.data?.key
            ? "absolute blur-none"
            : "hidden"
          } flex justify-center items-center w-full h-screen`}
      >
        <div className="my-auto px-4 mb-28 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              dispatch(balanceActions.tippingSuccess({}));
            }}
          >
            X
          </button>
          <img
            src={wallet}
            alt="loader"
            className={`h-14 mx-auto text-sm`}
          />

          <PaystackButton {...componentProps} />
        </div>
      </div> */}
      <div
        className={`${
          tippingState.errorTransactionStatusMessage ||
          tippingState.errorMessage?.msg ||
          tippingState.errorMessage?.error ||
          tippingState.errorMessage?.detail
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-screen`}
      >
        <TippingTransactionError />
      </div>
    </div>
  );
}
