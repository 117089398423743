import axios from "axios";
import { testUrl } from "../helper";
import { useParams } from "react-router-dom";

class UserService {
  constructor() {
    this.setHeaders = (token) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios.defaults.headers.common["XAT"] = `U`;
      axios.defaults.headers.common["X-IDT"] = `A`;
    };
  }

  getUserBalance = (balanceParams, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}accounts/auth/balance/`, balanceParams);
  };

  getUserSubscription = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}notifications/fetch-subscription/`);
  };

  postSubscription = (subParams, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}notifications/subscribe/`, subParams);
  };

  getUserTransaction = (transactionParams, token) => {
    this.setHeaders(token);

    return axios.get(`${testUrl}transactions/user/all/`, transactionParams);
  };

  getMoreUserTransaction = (link, token) => {
    this.setHeaders(token);
    const urlParam = link.split("co/")[1];
    return axios.get(`${testUrl}${urlParam}`);
  };

  getSingleUserTransactions = (transactionParams, token) => {
    this.setHeaders(token);
    return axios.get(
      `${testUrl}transactions/user/history/?shukran_id=${transactionParams}`
    );
  };

  getTipjarDisbursements = (transactionParams, token) => {
    this.setHeaders(token);
    return axios.get(
      `${testUrl}tipjar/disburse/?tipjar_id=${transactionParams}`
    );
  };

  getUserTips = (transactionParams, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}transactions/user/tips/`, transactionParams);
  };

  sendTip = (tipParams, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}api/v1/tips/`, tipParams);
  };

  deactivateCard = (cardId, token) => {
    this.setHeaders(token);
    return axios.delete(
      `${testUrl}transactions/paystack-cards/${cardId}/delete/`
    );
  };

  generateTokenOtp = (payload) => {
    return axios.post(
      `${testUrl}accounts/auth/signup-phone-only/otp/`,
      payload
    );
  };

  sendNotification = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}notifications/reservations/waitlist/`);
  };

  verifyTokenOtp = (payload) => {
    return axios.post(
      `${testUrl}accounts/auth/signup-phone-only/verify/`,
      payload
    );
  };

  getUserData = (userParams, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}accounts/auth/profile/`, userParams);
  };

  getTipjarData = (userParams, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}tipjar/`, userParams);
  };

  getTipjarGroups = (userParams, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}api/v1/tipjar/groups/`);
  };

  getTipjarGroup = (userParams, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}tipjar/groups/${userParams}/`, userParams);
  };

  postTipjarGroups = (userParams, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}tipjar/groups/`, userParams);
  };

  disburseTipjar = (userParams, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}tipjar/disburse/`, userParams);
  };

  getDisbursedTipjar = (userParams, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}transactions/${userParams}`, userParams);
  };

  postTipjarMembers = (userParams, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}tipjar/add-member/`, userParams);
  };

  updateTipjarMembers = (params, body, token) => {
    this.setHeaders(token);
    return axios.put(`${testUrl}tipjar/members/${params}/`, body);
  };

  deleteTipjarGroup = (params, token) => {
    this.setHeaders(token);
    return axios.delete(`${testUrl}tipjar/groups/${params}/`);
  };

  withdraw = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}transactions/withdraw/mpesa/`, params);
  };

  activateSacco = (params, token) => {
    this.setHeaders(token);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    return axios.post(`${testUrl}sacco/activate-sacco/`, params);
  };

  anonActivateSacco = (params, token) => {
    this.setHeaders(token);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    return axios.post(`${testUrl}sacco/anonymous-activate-sacco/`, params);
  };

  getSaccoInformation = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}sacco/activate-sacco/`);
  };

  accessTip = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}loans/borrow/`, params);
  };

  adjustAccessPercentage = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}sacco/sacco-access-tips/`, params);
  };

  getSaccoLoans = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}loans/transactions/`);
  };

  getSaccoBalances = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}sacco/balance/`);
  };

  deposit = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}transactions/deposit/mpesa/`, params);
  };

  checkTransactions = (params, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}transactions/status/?transaction_id=${params}`);
  };

  getCardTransactions = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}transactions/paystack-cards/`);
  };

  updateProfile = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}accounts/auth/profile/update/`, params);
  };

  // ratings/reviews APIs
  submitRatings = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}ratings/submit/`, params);
  };

  getRatings = (params, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}ratings/users/rated/`, params);
  };

  getBusinessRatings = (params, token) => {
    this.setHeaders(token);
    return axios.get(
      `${testUrl}ratings/business/ratings/?business_id=${params}`
    );
  };

  // Referral/Redeem award APIs
  redeemReward = (token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}sacco/referrals/redeem/`);
  };

  getReferrals = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}sacco/referrals/all/`);
  };

  getRedeemableReferrals = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}sacco/referrals/redeemable/`);
  };

  // password APIs
  forgotPassword = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}accounts/auth/password/forgot/`, params);
  };

  resetPassword = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}accounts/auth/password/reset/`, params);
  };

  changePassword = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}accounts/auth/password/change/`, params);
  };

  verifyOtp = (params) => {
    return axios.post(`${testUrl}accounts/auth/submit_otp/`, params);
  };

  requestOtp = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}accounts/auth/request_otp/`, params);
  };

  // Reservation endpoints
  getReservationHistory = (token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}businesses/reservations/`);
  };

  searchRestaurant = (params, token) => {
    this.setHeaders(token);
    return axios.get(`${testUrl}businesses/restaurants/?place_id=${params}`);
  };

  makeReservation = (params, token) => {
    this.setHeaders(token);
    return axios.post(`${testUrl}businesses/reservations/`, params);
  };
}

export default new UserService();
