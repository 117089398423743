export const TipJarGroupsQueryTypes = {
  CREATE_ENTITY: "createEntity",
  CREATE_ENTITY_JAR: "createEntityJar",
  FETCH_ENTITIES: "fetchEntities",
  FETCH_ENTITY_JARS: "fetchEntityJars",
  DISBURSE_POINT_SYSTEM: "disbursePointSystem",
  SEND_TIPS: "sendTips",
  CALCULATE_TIP_CHARGE: "calculateTipCharge",
  CHECK_MPESA_TRANSACTION_STATUS: "checkMpesaTrasactionStatus",
};
