import React, { useState } from "react";
import { ReloadIcon } from "@radix-ui/react-icons";
import { ChevronLeft, Info } from "lucide-react";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import BackButton from "../globals/BackButton";
import { Button } from "../../../src/components-shadcn/ui/button";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import StripeConfirmationDialog from "./StripeConfirmationDialog";
import { useSendTips } from "./state/server-state/useTipGroupsServerState";
import { useTipGroupsLocalState } from "./state/local-state/useTipGroupsLocalState";

const StripeEmailInput = () => {
  const [email, setEmail] = useState("");
  const { setInitialStripeDetails } = useTipGroupsLocalState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const sendTips = useSendTips();
  const isSendingTips = sendTips.isLoading;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleProceed = () => {
    if (validateEmail(email)) {
      setShowConfirmation(true);
    } else {
      toast.error("Enter a valid email");
    }
  };

  const handleSendTip = async (payload) => {
    setShowConfirmation(false);

    try {
      const response = await sendTips.mutateAsync(payload);
      navigate("/stripe-payment-form/");
      setInitialStripeDetails(response?.data?.data);
    } catch (error) {
      toast.error("An unexpected error occurred");
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white p-4">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>

      <h1 className="text-2xl font-semibold text-[#50A2A7] mb-4">
        Enter your Email Address to Receive Tip Receipts
      </h1>

      <div
        className={`flex flex-row drop-shadow-xl mt-8 justify-between relative focus:outline-primary-pwa`}
        style={{ width: "100%" }}
      >
        <input
          // type="text"
          // value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="johndoe@gmail.com"
          className="w-full p-3 rounded-md border-2 border-[#50A2A7] focus:outline-none focus:border-[#50A2A7]"
        />
      </div>

      <div className="bg-[#F0E6FF] rounded-md p-4 mt-6">
        <div className="flex items-start">
          <Info className="h-5 w-5 text-[#7C3AED] mr-2 mt-1" />
          <div>
            <h3 className="font-semibold text-[#7C3AED] mb-1">
              How does it work
            </h3>
            <p className="text-sm text-[#7C3AED]">
              Your email will be used to send payment receipts for your card
              tips.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-auto mb-4">
        <Button
          disabled={isSendingTips}
          className="w-full rounded-full h-[60px] bg-[#F2C773] text-white font-semibold"
          onClick={handleProceed}
        >
          {isSendingTips && (
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          )}
          PROCEED
        </Button>
      </div>

      {showConfirmation && (
        <StripeConfirmationDialog
          onClose={() => setShowConfirmation(false)}
          onSendTip={handleSendTip}
          email={email}
        />
      )}
    </div>
  );
};

export default StripeEmailInput;
