import React, { useEffect, useState } from "react";
import BaseOnboard from "./BoilerPlate/BaseOnboard";
import PageImage from "../../assets/Images/onboarding/Tip.png";

export default function StartTipping() {
  const [firstTimeLogIn, setFirstTimeLogIn] = useState("");
  const textContent = "Make someone’s day.";
  const contentTitle = "TIP";

  useEffect(() => {
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);
    setFirstTimeLogIn(isLoggedIn);
    return () => {};
  }, []);

  return (
    <BaseOnboard
      pageImage={PageImage}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext={firstTimeLogIn ? "/" : "/search"}
      navigatePrev="/tipOnboard"
    />
  );
}
