import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import loader from "../../assets/Icons/Registration/loader.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import actions from "../../state";
import { loadSingleUserTransactions } from "../../state/reducers/user/user.thunk";
import moment from "moment";
import TransactionCard from "../Card/TransactionCard";

const TransactionHistory = () => {
  const [transaction, setTransaction] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchShukranId = location.state;

  const { addShukranId, addNonShukranId } = bindActionCreators(
    actions,
    dispatch
  );
  // user global state values
  const transactionState = useSelector((state) => state.transactions);
  const userAccount = useSelector((state) => state.account);

  const transactions = useSelector((state) => {
    // show transactions from latest to earliest
    let stateArr = state?.transactions?.transactions.results
      ? state?.transactions?.transactions?.results.slice(0, 10)
      : 0;

    const appendAdditionOperator = (amount, currency) => {
      return `+ ${currency} ` + amount;
    };

    const appendSubtractionOperator = (amount, currency) => {
      return `- ${currency} ` + amount;
    };
    const parseType = (type) => {
      let newType = type.split("_");
      newType = newType.map((type, idx) => {
        return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
      });
      return newType.join(" ");
    };

    let arr = stateArr
      ? stateArr.map((trans) => {
          let dataFormat = {
            id: trans.id,
            amount: trans.amount,
            type: trans.type,
            date: moment(trans.created_at).format("D/M/Y"),
            status: trans.status,
            sender_name: trans.receiver_first_name,
            sender_id: trans.receiver_shukran_id,
            tip_type: trans.tip_type,
            currency: trans.currency,
            exchange_rate: trans.exchange_rate,
          };
          if (trans.type === "tipjar_disbursement") {
            dataFormat = {
              ...dataFormat,
              sender_id: trans.shukran_id,
            };
            return dataFormat;
          }
          if (trans.type === "Tip") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount, trans.currency),
              sender_name: trans.sender_first_name,
              receiver_shukran_id: trans.receiver_shukran_id,
            };
            return dataFormat;
          }

          if (trans.type === "sacco_tip") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount, trans.currency),
              sender_name: trans.sender_first_name,
              sender_id: trans.recipient_id,
            };
            return dataFormat;
          }

          if (
            trans.type === "pay_membership_fee" ||
            trans.type === "pay_share_capital" ||
            trans.type === "contribution"
          ) {
            dataFormat = {
              ...dataFormat,
              sender_name: parseType(trans.type),
            };
            return dataFormat;
          }
          if (trans.type === "access_transfer") {
            dataFormat = {
              ...dataFormat,
              sender_name: parseType(trans.type),
              amount: appendSubtractionOperator(trans.amount),
            };
            return dataFormat;
          }
          if (trans.type === "Credit") {
            dataFormat = {
              ...dataFormat,
              amount: appendAdditionOperator(trans.amount),
              sender_name: "Deposit",
            };
            return dataFormat;
          }

          if (trans.type === "Debit") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount),
              sender_name: "Withdrawal",
            };
            return dataFormat;
          }

          return dataFormat;
        })
      : [];
    const ids = arr.map((o) => o.id);
    arr = arr.filter(({ id }, index) => !ids.includes(id, index + 1));
    arr.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
    return arr;
  });

  useEffect(() => {
    if (searchShukranId) {
      dispatch(
        loadSingleUserTransactions(
          searchShukranId,
          userAccount.userDetails.token
        )
      );
    }
    return () => {};
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (transactionState.errorMessage.status === 403) {
      navigate("/login");
    }
    return () => {};
  }, [transactionState]);
  return (
    <div
      className={`
          mx-auto w-full flex flex-col h-screen pb-20
          ${transactionState.isLoading ? "h-full" : ""}
          `}
    >
      <div
        className={`
          ${transactionState.isLoading ? "blur-sm" : ""}
          mx-auto w-full flex flex-col h-full pb-20`}
      >
        <div onClick={handleBack} className="m-5">
          <img src={back} className="" alt="rate-star" />
        </div>
        <div className="relative w-full px-4 flex flex-col items-start pb-3">
          {transactions &&
            transactions.map((transact, idx) => (
              <>
                {idx > 0 ? (
                  transaction.date !== transactions[idx - 1].date &&
                  idx !== 0 && (
                    <div className="mt-2 mb-1 text-base px-2.5">
                      {transaction.date}
                    </div>
                  )
                ) : (
                  <div className="mt-2 mb-1 text-base px-2.5">
                    {transaction.date}
                  </div>
                )}
                <TransactionCard
                  key={transaction.id}
                  transaction={transact}
                  isHistory={true}
                />
              </>
            ))}
        </div>
      </div>
      <div
        className={`${
          transactionState.isLoading ? "absolute blur-none" : "hidden"
        }
          flex justify-center items-center w-full h-full
          `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default TransactionHistory;
