import { actionTypes } from "./search.actions";

const searchState = {
  isLoading: false,
  results: [],
  errorMessage: "",
  searchId: "",
};

const checkIfObj = (payload) => {
  if (payload.constructor.name === "Object") {
    let madeArr = [payload];

    return madeArr;
  } else {
    return payload;
  }
};

const searchReducer = (state = searchState, { type, payload }) => {
  switch (type) {
    case actionTypes.SEARCH_LOAD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.UPDATE_SEARCH_QUERY:
      return {
        ...state,
        isLoading: false,
        searchId: payload,
      };
    case actionTypes.SEARCH_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: checkIfObj(payload),
      };
    case actionTypes.SEARCH_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
export default searchReducer;
