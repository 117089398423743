import React, { useState, useEffect } from "react";
import { getFromSessionStorage } from "../../Services/Storage";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import loader from "../../assets/Icons/Registration/loader.svg";
import onStar from "../../assets/Icons/Rating/on_star.svg";
import offStar from "../../assets/Icons/Rating/off_star.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import successIcon from "../../assets/Icons/Rating/success.svg";
import clearIcon from "../../assets/Icons/Rating/clear.svg";
import searchIcon from "../../assets/Icons/Rating/search.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "./Rate.css";
import {
  getBusinessRatings,
  submitRatings,
} from "../../state/reducers/user/user.thunk";
import { searchBusiness } from "../../state/reducers/search/search.thunk";
import { businessActions } from "../../state/reducers/business/business.actions";
import { ratingActions } from "../../state/reducers/rating/rating.actions";
import { searchActions } from "../../state/reducers/search/search.actions";
import axios from "axios";
import { testUrl } from "../../helper";
import { debounce } from "lodash";

const API_URL = testUrl + "businesses/?name=";

const DEBOUNCE = 1000;

const searchFun = (queryParam, dispatch, token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .get(`${API_URL}${queryParam}`)
    .then((res) => {

      dispatch(businessActions.searchBusinessSuccess(res.data));
      // setResults(res.data.data);
    })
    .catch((err) => {
      let errorMsg = "";
      if (err.response.status === 403) {
        errorMsg = err.response.status;
      } else {
        errorMsg = err.response.data;
      }
      dispatch(businessActions.searchBusinessSuccess(""));
      dispatch(businessActions.searchBusinessError(errorMsg));
    });
};

/**
 * This is the debounced function that we will run once the user hit a key
 * lodash debounce return a function, that can be invoked any time
 * this function takes a function, searchFunction, and a debounce time
 * in this way we guarantee that the we only fetch after certin time and we don't spam the api with calls every time the user hits a key
 */
const debouncedSearch = debounce(searchFun, DEBOUNCE);

export default function Rate() {
  const [comment, setComment] = useState("");

  const [searchId, setSearchId] = useState("");
  const [defaultView, setDefaultView] = useState(true);
  const [rating, setRating] = useState(0);
  const [errorView, setErrorView] = useState();
  const [successView, setSuccessView] = useState(false);
  const [toggleSearch, setToggleSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const tokenInfo = useSelector((state) => {
    let token = state.account.userDetails.token;
    let id = state.account.userDetails.shukranId;
    // let receiverId = state.tipData.shukranId;
    // let receiverName = state.tipData.receiverName;
    // let receiverLastName = state.tipData.receiverLastName;

    return {
      token: token ? token : "",
      shukran_id: id ? id : "",
      receiverId: state.tipData.shukranId,
      receiverName: state.tipData.receiverName,
      receiverLastName: state.tipData.receiverLastName,
    };
  });
  const userState = useSelector((state) => state.account.userDetails);
  const ratingState = useSelector((state) => {
    return state.rating;
  });
  const businessState = useSelector((state) => {
    return state.business;
  });
  let userInfo = tokenInfo;

  useEffect(() => {
    dispatch(ratingActions.submitRatingError(""));
    dispatch(searchActions.searchLoadSuccess([]));
  }, []);

  useEffect(() => {
    if (businessState.errorMessage === 403) {
      navigate("/login");
      dispatch(businessActions.searchBusinessError(""));
    }
    return () => {};
  }, [businessState]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //   navigate
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addShukranId } = bindActionCreators(actions, dispatch);
  const handleChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (ratingState.errorMessage === 403) {
      navigate("/login");
    }
    if (ratingState.errorMessage.status === 403) {
      navigate("/login");
    }
    return () => {};
  }, [ratingState]);

  useEffect(() => {
    if (businessState.errorMessage === 403) {
      navigate("/login");
    }
    if (businessState.errorMessage.status === 403) {
      navigate("/login");
    }
    return () => {};
  }, [businessState]);

  const handleRatingSubmit = (data) => {
    if (!rating) {
      dispatch(
        ratingActions.submitRatingError({
          data: {
            msg: "Please ensure you have selected a star rating",
          },
          status: 401,
        })
      );
    } else {
      let params = {
        rater_id: getFromSessionStorage("ShukranId"),
        rated_id: userInfo.receiverId,
        // rated_id: 'TK0001',
        rating_level: rating,
        comment: data.textArea ? data.textArea : "",
        // token: getFromSessionStorage("token"),
      };

      dispatch(submitRatings(params, getFromSessionStorage("token")));
    }
  };

  const handleViewBusinessRatings = (data) => {
    dispatch(getBusinessRatings(data, userState.token));
  };
  const handleBusinessSearch = (data) => {
    if (!data.searchParam) {
    } else {

      let param = data.searchParam;
      dispatch(searchBusiness(param, userState.token));
    }
  };

  const handleChangePin = (e) => {
    setSearchId(e.target.value);
    let v = e.target.value;
    const search = debouncedSearch;
    if (!v) {
      // when the user clear the field we don't want to perform a search, we need to clear the state and do nothing else
      debouncedSearch.cancel();
      dispatch(searchActions.searchLoadSuccess([]));
      // setIsLoading(false);
    } else {
      dispatch(searchActions.searchLoadStart());
      search(v, dispatch, userState.token);
    }
  };

  const handleSelectBusiness = (business) => {

    dispatch(businessActions.searchBusinessClear());
    addShukranId({
      shukran_id: business.id,
      first_name: business.name,
      last_name: business.address,
    });
    setDefaultView(false);
  };

  //Handle enter key input
  function handleEnterKey(e) {
    if (e.keyCode === 13) {
      setIsFocused(false);
      // setToggleSearch(true)
      let userId = userState.shukranId;
      if (userId === searchId) {
        dispatch(
          searchActions.searchLoadError({
            error: true,
            msg: "A User cannot tip their own Shukran ID",
            title: "Unacceptable Shukran ID",
          })
        );
      } else {

        let params = {
          shukran_id: searchId,
        };
        if (searchId) {
          dispatch(businessActions.searchBusinessError(""));
        } else {
          dispatch(
            searchActions.searchLoadError({
              error: true,
              msg: "Please enter a shukran id",
              title: "Empty Field",
            })
          );
        }
      }
    }
  }
  const SuccessView = () => {
    return (
      <>
        <div className="bg-[#F0FEFF] mt-7 rounded-md p-5 mx-4">
          <div className="flex flex-row justify-between">
            <img src={NotFoundIcon} alt="Not found icon" className="" />
          </div>
        </div>
      </>
    );
  };

  const RateError = () => (
    <>
      <div className="bg-red-100 mt-7 rounded-md p-5 mx-4">
        <div className="flex flex-row justify-between">
          <span className="text-sm">Not Found</span>
          <img src={NotFoundIcon} alt="Not found icon" className="" />
        </div>
        <span className="text-sm text-slate-400 mt-1">
          Please crosscheck the restaurant name or try a different name
        </span>
      </div>
    </>
  );

  const RateCard = () => (
    <form
      onSubmit={handleSubmit(handleRatingSubmit)}
      className="flex flex-col w-full h-full justify-between"
    >
      <div className="px-4 flex flex-col mt-4">
        <label className="pb-2 text-sm">Rate Your Experience</label>
        <div className="flex mt-2 flex-row justify-between items-center">
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <button
                type="button"
                key={index}
                onClick={() => setRating(index)}
              >
                <img
                  src={index <= rating ? onStar : offStar}
                  alt="star-img"
                  className="h-7"
                />
              </button>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col mx-2 items-center h-3/6">
        {/* <label className="pb-4 text-sm">{'Anything we could improve or you liked'}</label> */}
        <textarea
          type="text"
          className="p-4 text-base mt-5 mb-4 h-40 w-11/12 drop-shadow-2xl"
          placeholder="Optional comment..."
          {...register("textArea")}
        />
      </div>
      <div className="my-5 flex flex-col items-center">
        <button
          className="rounded-full bg-accent w-full py-4 text-white text-xl"
          type="submit"
        >
          SUBMIT
        </button>
      </div>
    </form>
  );

  const SearchError = () => (
    <div className="flex flex-col">
      <div className="text-[#EF5F89] my-3 bg-white rounded-md flex flex-col justify-between items-center">
        Looks like this restaurant does not exist. Please confirm its in search
        again
      </div>
    </div>
  );

  const SuccessRating = () => (
    <div className="  my-auto p-7 shadow bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(ratingActions.submitRatingSuccess({}));
          addShukranId({});
          // navigate("/");
        }}
      >
        X
      </button>

      <img src={checkCircle} alt="error-img" className="h-20" />

      <p className="justify-self-center	text-lg text-center py-2">
        {ratingState.results.msg}
      </p>
    </div>
  );

  const ErrorRating = () => (
    <div className="p-7 shadow bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          // setIsclicked(!isClicked);
          dispatch(
            ratingActions.submitRatingError({
              error: false,
              msg: "",
              data: "",
            })
          );
          if (ratingState.errorMessage.status !== 401) {
            addShukranId({});
          }
          // navigate("/rate");
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="error-img" className="h-20" />
      <h2 className={"text-center font-bold	text-lg"}>Error</h2>
      <p className="justify-self-center	text-base text-center py-2">
        {ratingState.errorMessage?.data?.msg
          ? ratingState.errorMessage?.data?.msg
          : ratingState.errorMessage.status + " Error"}
      </p>
    </div>
  );

  const StarRating = ({ count }) => {
    return Array(count)
      .fill(1)
      .map((_, i) => i + 1)
      .map((idx, i) => (
        <div key={i}>
          <img src={onStar} alt="Star" className="h-3 w-3" />
        </div>
      ));
  };
  const NonRating = ({ count }) => {
    return Array(5 - count)
      .fill(1)
      .map((_, i) => i + 1)
      .map((idx, i) => (
        <div key={i}>
          <img src={offStar} alt="Star" className="h-3 w-3" />
        </div>
      ));
  };

  return (
    <div
      className={`${ratingState.isLoading ? "relative" : ""}
      ${ratingState.errorMessage.data ? "relative" : ""}
      ${ratingState.results.success ? "relative" : ""}
      flex w-full flex-col h-10/12`}
    >
      <div
        className={`
        ${ratingState.isLoading ? "blur-sm" : ""}
        ${ratingState.errorMessage.data ? "blur-sm" : ""}
        ${ratingState.results.success ? "blur-sm" : ""}
        flex flex-col px-5 w-full`}
      >
        <div className="flex flex-row items-center mt-9">
          <Link to={"/profile/android"} className="ml-2">
            <img src={back} className="" alt="rate-star" />
          </Link>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <p className="text-lg mx-4 my-5">Help us serve you better</p>
        <div className="px-4 flex flex-col">
          {!userInfo.receiverId ? (
            // <form
            //   onSubmit={handleSubmit(handleBusinessSearch)}
            //   className={`flex flex-row shadow-lg`}
            // >
            <div className={`flex flex-row shadow-lg`}>
              <input
                value={searchId}
                onChange={(e) => handleChangePin(e)}
                onKeyDown={(e) => handleEnterKey(e)}
                className={`text-sm w-5/6 px-8
                   ${
                     businessState.errorMessage &&
                     !businessState.isLoading &&
                     !businessState.results.length
                       ? "focus:outline-[#EF5F89]"
                       : ""
                   }
                `}
                placeholder="Enter Business Name"
                onFocus={(e) => {
                  if (e.currentTarget === e.target) {
                    setIsFocused(true);
                    dispatch(businessActions.searchBusinessError(""));
                  }
                }}
                onBlur={(e) => {
                  setToggleSearch(false);
                  setIsFocused(false);
                }}
              />
              <div className="p-4 flex justify-center items-center">
                {defaultView ? (
                  <button type="submit">
                    <img src={searchIcon} alt="search icon" className="" />
                  </button>
                ) : errorView ? (
                  <div onClick={() => setComment("")}>
                    <img src={clearIcon} alt="Clear Icon" className="" />
                  </div>
                ) : successView ? (
                  <div>
                    <img src={successIcon} alt="Success Icon" className="" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row justify-between items-center h-16 border-b-2 border-b-[#B9F5F947]">
                <div className="flex flex-row items-center">
                  <div className="w-7 h-7 text-xs bg-accent flex justify-center items-center rounded-full">
                    <p className="text-white text-xs">{userInfo.receiverId}</p>
                  </div>
                  <div className="flex flex-col justify-between ml-4">
                    <span className="text-sm">{userInfo.receiverName}</span>
                    <span className="text-xs text-gray-500">
                      {userInfo.receiverLastName}
                    </span>
                  </div>
                </div>
                <div
                  className="pr-2 pl-5 text-xl cursor-pointer"
                  onClick={() => {
                    setDefaultView(true);
                    addShukranId({});
                    dispatch(
                      ratingActions.getBusinessRatingSuccess({
                        ratings: [],
                      })
                    );
                  }}
                >
                  X
                </div>
              </div>
              {!!ratingState.businessReviews.ratings.length ? (
                <div
                  className="flex flex-row-reverse cursor-pointer"
                  onClick={() => {
                    dispatch(
                      ratingActions.getBusinessRatingSuccess({
                        ratings: [],
                      })
                    );
                  }}
                >
                  <span className="italic text-xs pt-2 underline text-gray-500">
                    Rate Business
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {defaultView && <></>}
        {businessState.isLoading && (
          <div className="flex flex-row items-center justify-center px-4 mt-7">
            <img
              src={loader}
              alt="loader"
              className={`animate-spin h-8 mx-auto text-sm mt-1`}
            />
          </div>
        )}
        {!!businessState.results && (
          <div>
            {businessState.results[0]?.results.map((business, idx) => (
              <div
                key={idx}
                onClick={() => {
                  handleSelectBusiness(business);
                }}
                className="flex flex-row cursor-pointer items-center mx-4 mt-7 h-16 border-b-2 border-b-[#B9F5F947]"
              >
                <div className="w-7 h-7 text-xs bg-accent flex justify-center items-center rounded-full">
                  <p className="text-white text-xs">{business.id}</p>
                </div>
                <div className="flex flex-col justify-between ml-4">
                  <span className="text-sm">{business.name}</span>
                  <span className="text-xs text-gray-500">
                    {business.address}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
        {ratingState.isLoadingBusinessReviews && (
          <div className="flex flex-row items-center justify-center px-4 mt-7">
            <img
              src={loader}
              alt="loader"
              className={`animate-spin h-8 mx-auto text-sm mt-1`}
            />
          </div>
        )}
        {!!ratingState.businessReviews && (
          <div className="mb-2">
            {ratingState.businessReviews.ratings.map((review, idx) => (
              <div
                key={idx}
                className="flex flex-col mx-4 mt-4 drop-shadow-xl py-3 px-3 bg-white rounded-sm"
              >
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-row">
                    <div className="w-7 h-7 text-xs bg-accent flex justify-center items-center rounded-full">
                      <p className="text-white text-xs">
                        {review.rater_id.split("", 2)}
                      </p>
                    </div>
                    <div className="flex flex-col justify-between px-6">
                      <span className="text-sm">{review.rater_name}</span>
                      <div className="text-sm text-header flex flex-row">
                        <StarRating count={review.rating_level} />
                        <NonRating count={review.rating_level} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col text-xs text-slate-600">
                    <span>Reviewed</span>
                    <p>
                      {review.created_at
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row">
                  <p className="text-sm pt-3 text-slate-700">
                    {review.comment}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {userInfo.receiverId &&
          !ratingState.isLoadingBusinessReviews &&
          !!businessState.results &&
          !ratingState.businessReviews.ratings.length && <RateCard />}
        {businessState.errorMessage &&
          !businessState.isLoading &&
          !businessState.results.length && <SearchError />}
        {errorView && <RateError />}
        {!!businessState.results.length && <SuccessView />}
        {businessState.isLoading && (
          <img
            src={loader}
            alt="loader"
            className="animate-spin h-8 text-base mt-7 mx-auto"
          />
        )}
      </div>
      <div
        className={`${
          ratingState.results.success ? "absolute blur-none" : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <SuccessRating />
      </div>
      <div
        className={`${ratingState.isLoading ? "absolute blur-none" : "hidden"}
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <div className="p-4 bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-10 mx-auto text-sm mt-1`}
          />
        </div>
      </div>
      <div
        className={`${
          ratingState.errorMessage.status >= 400
            ? "absolute blur-none"
            : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ErrorRating />
      </div>
    </div>
  );
}
