import React, { useState, useEffect } from "react";
import "./akiba.css";
import ProgressBar from "react-customizable-progressbar";
import axios from "axios";
import { testUrl } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import {
  getSaccoBalances,
  loadBalanceResults,
  redeemReward,
} from "../../state/reducers/user/user.thunk";
import { BeatLoader, MoonLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";

const AkibaProgress = ({
  viewBoxSize,
  radius,
  strokeWidth,
  streakInfo,
  loading,
  setAkibaShow,
  type,
  setShowToast,
}) => {
  const dispatch = useDispatch();
  const [circumference, setCircumference] = useState(0);
  const [offset, setOffset] = useState(0);
  const [progress, setProgress] = useState(0);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [days, setDays] = useState(0);
  const account = useSelector((state) => state.account);
  const claimReward = async () => {
    setLoadingClaim(true);

    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${account.userDetails.token}`;
      axios.defaults.headers.common["XAT"] = `U`;
      axios.defaults.headers.common["X-IDT"] = `A`;
      const res = await axios.post(`${testUrl}sacco/akiba100/redeem/`);

      if (res.data.success === true) {
        // setShowToast({ show: true, type: "success" });
        toast.success("Redeemed successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setAkibaShow(false);
        dispatch(getSaccoBalances(account.userDetails.token));
        //dispatch(loadBalanceResults(account.userDetails.token));
        setLoadingClaim(false);
      } else {
        // setShowToast({ show: true, type: "fail" });
        toast.warning("No reward, start saving to get a reward", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoadingClaim(false);
      }
    } catch (e) {
      setLoadingClaim(false);
      setShowToast({ show: true, type: "fail" });
    }
  };

  useEffect(() => {
    let progressValue = progress;
    let progressEndValue =
      streakInfo?.current_week === 0
        ? 0
        : 7 - (streakInfo?.current_week * 7 - streakInfo?.daily_streak);
    let speed = 10;
    if (progress < progressEndValue) {
      let progressing = setInterval(() => {
        progressValue++;
        setProgress(progressValue);

        if (progressValue >= progressEndValue) {
          clearInterval(progressing);
        }
      }, speed);
    }
  }, [streakInfo]);

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [progress, circumference]);

  return (
    <div
      className="w-1/3 my-3 py-2 pl-4 flex items-center justify-center"
      style={{ borderLeft: "1px dotted #274458" }}
    >
      {loading ? (
        <div className="flex items-center flex-col justify-center inner">
          loading
        </div>
      ) : (
        <div className="flex items-center flex-col justify-center inner">
          <p
            style={{
              position: "absolute",
              marginBottom: "120px",
              fontSize: "12px",
              color: "#DDEDEE",
            }}
          >
            SAVE DAILY
          </p>
          {/* <div className="circular_progress mb-6">
          <div className="pointer"></div>

        </div> */}
          <div className="progress_container mb-10">
            <ProgressBar
              progress={progress}
              radius={60}
              steps={7}
              pointerRadius={4}
              strokeColor="#e9b44c"
              pointerStrokeColor="#e9b44c"
              strokeWidth={4}
              trackStrokeWidth={4}
              className="circular_svg "
              trackTransition="0.2s ease"
              fillColor="#3a5363"
              trackStrokeColor="#fff"
            >
              <div className="value_container absolute_center mt-3">
                <p className="day">DAY</p>
                <p className="count">{streakInfo?.daily_streak}</p>
              </div>
            </ProgressBar>
          </div>

          {/* <p
            style={{
              position: "absolute",
              marginTop: "65px",
              fontSize: "10px",
              color: "#DDEDEE",
            }}
          >
            Week {streakInfo?.current_week}
          </p> */}
          <div style={{ position: "absolute", marginTop: "65px" }}>
            <button
              className="w-45 h-4   px-3"
              onClick={() => claimReward()}
              style={{
                fontSize: "12px",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
                backgroundColor: "#e9b44c",
              }}
            >
              {loadingClaim ? (
                <BeatLoader size={10} loading={true} color="#FFFFFF" />
              ) : (
                "REDEEM"
              )}
            </button>
          </div>
          <div
            style={{ marginTop: "100px", position: "absolute" }}
            className="flex flex-row items-center justify-center mt-2"
          >
            <p
              style={{
                fontSize: "10px",
                color: "#DDEDEE",
                fontWeight: 400,
              }}
            >
              Earn{" "}
            </p>
            <p
              style={{
                fontSize: "10px",
                color: "#F2C773",
                fontWeight: 700,
              }}
              className="ml-1"
            >
              {" "}
              KSH{" "}
              {streakInfo?.amount_eligible
                ? streakInfo?.amount_eligible
                : streakInfo?.next_reward_eligible}
            </p>
          </div>
          <div
            style={{
              position: "absolute",
              marginTop: "125px",
            }}
            className="flex flex-row items-center justify-center"
          >
            <p
              style={{
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: 400,
                color: "#DDEDEE",
              }}
            >
              Get Started
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#DDEDEE",
              }}
            >
              😃
            </p>
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default AkibaProgress;
