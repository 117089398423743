import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logos/shukran-logo-dark.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import fiveOfTen from "../../assets/Illustrations/sacco/five_of_ten.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { useDispatch, useSelector } from "react-redux";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import { getFromSessionStorage } from "../../Services/Storage";
import {
  getUserData,
  postActivateSacco,
} from "../../state/reducers/user/user.thunk";

const FifthOccupationInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isNext, setIsNext] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({});

  const saccoState = useSelector((state) => state.sacco);
  const userAccount = useSelector((state) => state.account);
  const balanceState = useSelector((state) => {
    return state.balance;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    dispatch(getUserData(params, userAccount.userDetails.token));

    return () => {};
  }, []);

  useEffect(() => {
    if (balanceState.errorMessage === 403) {
      navigate("/");
    }
    return () => {};
  }, [balanceState]);

  useEffect(() => {
    if (saccoState.errorMessage.status === 403) {
      dispatch(saccoActions.saccoActivateError(""));
      navigate("/");
    }
    return () => {};
  }, [saccoState]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (saccoState.saccoDetails) {
      setValue("mca", saccoState.saccoDetails.monthly_contribution);
      setValue("modeOfRemittance", saccoState.saccoDetails.mode_of_remittance);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (saccoState.results.success && isNext) {
      navigate("/sixthForm");
      dispatch(saccoActions.saccoActivateSuccess({}));
      setIsNext(false);
    }
    return () => {};
  }, [saccoState, isNext, dispatch, navigate]);

  const handleFirstPersonalInfo = (data) => {
    let payload = {
      monthly_contribution: data.mca,
      mode_of_remittance: data.modeOfRemittance,
    };

    dispatch(saccoActions.updateSaccoDetails(payload));
    setIsNext(true);
    handleSavePartially(data);
  };

  const handleSavePartially = (data) => {
    let formDetails = saccoState.saccoDetails;
    let payload = {
      ...formDetails,
      monthly_contribution: data.mca,
      mode_of_remittance: data.modeOfRemittance,
      kyc_stage: 5,
    };
    let payloadData = new FormData();
    for (const key in payload) {
      if (
        key === "kra_pin_photo" ||
        key === "passport_photo" ||
        key === "id_passport_photo" ||
        key === "e_signature_photo" ||
        key === "id_passport_back_photo"
      ) {
        // payloadData.append(key, payload[key][0]?.file);
      } else if (
        key.includes("primary_") ||
        key.includes("dob") ||
        key === "guardian_mn" ||
        key === "user_count" ||
        key === "is_under_eighteen"
      ) {
      } else {
        payloadData.append(key, payload[key]);
      }
    }

    dispatch(postActivateSacco(payloadData, userAccount.userDetails.token));
  };

  const SaccoActivationError = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateError(""));
        }}
      >
        X
      </button>
      {saccoState.errorMessage && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {typeof saccoState.errorMessage === "XMLHttpRequest" && (
        <p className="justify-self-center	text-base text-center py-2">
          CORS error
        </p>
      )}
      {saccoState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage.msg}
        </p>
      )}
      {saccoState.errorMessage.status === 400 && (
        <p className="justify-self-center	text-base text-center py-2">
          {Object.entries(saccoState.errorMessage.data.data)[0]}
        </p>
      )}
      {saccoState.errorMessage.status === 500 && (
        <p className="justify-self-center	text-base text-center py-2">
          System downtime. Try again in few moments
        </p>
      )}
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back To Dashboard
      </button>
    </div>
  );

  const SuccessActivation = () => (
    <div className="my-auto px-4 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          // navigate("/");
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-28" />
      <p className="justify-self-center	text-lg text-center py-2">
        {saccoState.results.success ? "Submission Successful!" : ""}
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.results.success
          ? "Your activation details have been partially submitted"
          : ""}
      </p>
      {isNext ? (
        <></>
      ) : (
        <button
          className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
          type="submit"
          onClick={() => {
            dispatch(saccoActions.saccoActivateSuccess({}));
            navigate("/");
          }}
        >
          Home
        </button>
      )}
    </div>
  );

  return (
    <div
      className={`
        ${saccoState.isLoading ? "relative" : ""}
        ${saccoState.errorMessage ? "relative" : ""}
        ${isLoading ? "relative" : ""} w-screen flex flex-col h-screen`}
    >
      <div
        className={`
        ${saccoState.isLoading ? "blur-sm" : ""}
      ${saccoState.errorMessage ? "blur-sm" : ""}
          ${isLoading ? "blur-sm" : ""}
          mx-auto h-full w-full text-black
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6 flex flex-row items-center justify-between">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={fiveOfTen}
            alt="five of ten"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-4 h-full bg-white rounded-t-3xl px-6">
          <h2 className="text-2xl text-primary-green">
            Tell us about your contribution
          </h2>
          <p className="text-base my-1">
            This details will be used for your SACCO account and linked to your
            Shukran account.
          </p>
          <form
            className="flex flex-col justify-between h-4/5 mt-3"
            onSubmit={handleSubmit(handleFirstPersonalInfo)}
          >
            <div>
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Monthly Contribution Amount *
                </label>
                <select
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  {...register("mca", {
                    required: true,
                  })}
                  defaultValue={1000}
                >
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                  <option value="3000">3000</option>
                  <option value="4000">4000</option>
                </select>
                {errors.mca && (
                  <p className="text-red-600 text-sm">
                    Please select a Monthly contribution amount
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Mode of Remittance *
                </label>
                <select
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  {...register("modeOfRemittance", {
                    required: true,
                  })}
                  defaultValue="M-pesa"
                >
                  <option value="M-pesa">MPESA</option>
                  <option value="card">Card</option>
                </select>
                {errors.modeOfRemittance && (
                  <p className="text-red-600 text-sm">
                    Please select a mode of remittance
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between my-4 pt-3">
              <div
                className={`flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <button type="submit" className="flex flex-row items-center">
                <span className="mr-2">Next</span>
                <img
                  src={ArrowButtonNext}
                  className="w-12 h-12"
                  alt="Next Page"
                  type="button"
                />
              </button>
            </div>
            <button
              className="rounded-full py-2 mb-2 bg-primary-green text-white text-base w-full"
              type="button"
              onClick={handleSubmit(handleSavePartially)}
            >
              SAVE AND CONTINUE LATER
            </button>
          </form>
        </div>
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SaccoActivationError />
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SuccessActivation />
      </div>
      <div
        className={`
          ${saccoState.isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="bg-white py-5 w-4/5 rounded-md drop-shadow-2xl flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-20 mx-auto text-sm mt-1 z-10`}
          />
          <span>Processing submitted details</span>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default FifthOccupationInfo;
