import React, { useState, useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import cross from "../../assets/Icons/Tipping/cross.svg";
import hourGlass from "../../assets/Icons/Referrals/hour_glass.svg";
import reserveIcon from "../../assets/Icons/Reservations/reservation.svg";
import reservationLocation from "../../assets/Icons/reservation_location.svg";
import reservationPeople from "../../assets/Icons/reservation_people.svg";
import reservationClock from "../../assets/Icons/reservation_time.svg";
import reservationDate from "../../assets/Icons/reservation_schedule.svg";
import prevIcon from "../../assets/Icons/Reservations/backBut.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import WastingGlass from "../../assets/Icons/wasting_glass.svg";
import { getUserData, makeReservation } from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { getFromSessionStorage } from "../../Services/Storage";
import { reservationActions } from "../../state/reducers/reservation/reservation.actions";
import { Calendar } from "react-calendar";

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { useForm } from "react-hook-form";
import { DUARA_KEY } from "../../helper";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function SubmitReservation() {
  const [cardOption,] = useState(false);
  const [accessTipError, setAccessTipError] = useState(false);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [payload, setPayload] = useState({});
  const [selectedTime, setSelectedTime] = useState('');
  const [windowSize, setWindowSize] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userAccount = useSelector((state) => state.account);
  const balanceState = useSelector((state) => {
    return state.balance;
  });

  const reservationState = useSelector((state) => state?.reservations);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({});

  //Handle enter key input

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);
    let params = {
      shukran_id: shukran_id,
    };
    if (isLoggedIn) {
      dispatch(getUserData(params, userAccount.userDetails.token));
    }
    return () => { };
  }, []);

  useEffect(() => {
    if (balanceState.errorMessage === 403) {
      navigate("/");
    }
    return () => {};
  }, [balanceState]);

  useEffect(() => {
    if (reservationState.errorMessage.status === 403) {
      navigate("/login");
      dispatch(reservationActions.getReservationHistoryError(''))
    }
    return () => { };
  }, [reservationState]);

  useEffect(() => {
    if (balanceState.userInfo) {
      let formattedUser =  balanceState.userInfo?.first_name + ' ' + balanceState.userInfo?.last_name;
      let depositPhone = balanceState.userInfo?.phone;
      if (depositPhone) {
        depositPhone = "0" + depositPhone.slice(3);
      }
      setValue("firstName", balanceState.userInfo?.first_name);
      setValue("lastName", balanceState.userInfo?.last_name);
      setValue('primaryNumber', depositPhone)
    }
    return () => {};
  }, []);


  useEffect(() => {
    function handleWindowResize() {
      // setWindowSize(getWindowSize());
      let res = getWindowSize();
      setWindowSize(res);
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize, { passive: false });

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleReservationInfo = (data) => {
     let payload = {
        google_restaurant: reservationState.restaurants.id,
        number_of_people: Number(data.chosenPeople)?  Number(data.chosenPeople):1,
        reservation_time: reservationState.payloadDetails.reservation_time,
        name: data.firstName + ' ' + data.lastName,
        phone_number: data.primaryNumber,
        email: balanceState.userInfo.email
      }

      setPayload(payload)
      setSelectedTime(data.chosenTime)
      setToggleConfirm(true)

  }

  const makeReservationSubmit = () => {
    dispatch(makeReservation(payload, userAccount.userDetails.token))
  }

  const ConfirmTipPopup = () => (
    <div className="py-8 px-6 drop-shadow-lg w-4/5 bg-white flex flex-col rounded-3xl z-50 mt-32">
      <div className="flex flex-row items-center justify-between">
        <span className="text-base">Confirm Reservation</span>
        <div
          onClick={() => {
            setToggleConfirm(false)
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-12">
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-sm text-black">
            Name
          </div>
          <div className="text-sm text-primary-pwa">{balanceState.userInfo?.first_name + ' ' + balanceState.userInfo?.last_name}</div>
        </div>

        <div className="flex flex-row items-center justify-between mb-3">
          <div className="text-sm text-black">
            Phone
          </div>
          <div className="text-sm text-primary-pwa">{balanceState.userInfo?.phone}</div>
        </div>
      <div className="flex flex-row justify-between items-center mb-3">
        <div className="text-sm text-black">
          Day
        </div>
        <div className="text-sm text-primary-pwa">
          {reservationState.payloadDetails.chosen_day}
        </div>
      </div>
      <div className="flex flex-row items-center justify-between mb-3">
        <div className="text-sm text-black">
          Time
        </div>
        <div className="text-sm text-primary-pwa">
          {reservationState.payloadDetails.chosen_time}
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm text-black">
          People
        </div>
        <div className="text-sm text-primary-pwa">
          {reservationState.payloadDetails.number_of_people}
        </div>
      </div>
      </div>
      <div className="mt-6">
        {!reservationState?.reservationResponse.success && (
          <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            onClick={() => {
              setToggleConfirm(false)
              makeReservationSubmit()
            }}
          >
            Make Reservation
          </button>
        )}
      </div>
    </div>
  );

  const ReservationTransactionCard = () => (
    <div className="mt-32 px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(reservationActions.makeReservationSuccess(""));
            // addShukranId({})
            navigate("/");
          }}
        >
          X
        </button>
      {reservationState?.reservationResponse?.created_at &&
        !reservationState?.isLoading && (
        <p className="justify-self-center	text-lg text-center py-2 px-12">
          Reservation Sent!
        </p>
      )}
      {reservationState?.reservationResponse?.created_at &&
        !reservationState?.isLoading && (
          <p className="justify-self-center	text-base text-primary-pwa text-center py-2 px-3">
           You will receive an SMS confirmation in  30 minutes
          </p>
        )}
         {reservationState?.reservationResponse?.created_at &&
        !reservationState?.isLoading && (
          <img src={hourGlass} alt="error-img" className="h-24 mt-3" />
        )}
    </div>
  );

  const ReservationTransactionError = () => (
    <div className="mt-32 px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col items-center relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.tippingError(""));
        }}
      >
        X
      </button>
      <img src={WastingGlass} alt="time clock" className="w-20 h-20" />
      {reservationState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {reservationState?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {reservationState?.errorTransactionStatusMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
         Reservation pending. Kindly be patient as we process it
        </p>
      )}
      {reservationState?.errorTransactionStatusMessage?.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2">
          Reservation request cancelled
        </p>
      )}
      {reservationState?.errorTransactionStatusMessage?.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {reservationState?.errorTransactionStatusMessage?.detail}
        </p>
      )}
    </div>
  );


  return (
    <div
      className={`
        ${reservationState?.errorMessage?.error ? "relative" : ""}
        ${reservationState?.errorMessage?.msg ? "relative" : ""}
        ${reservationState?.reservationResponse?.created_at ? "relative" : ""}
        ${reservationState?.isLoading ? "relative" : ""}
        ${reservationState?.errorTransactionStatusMessage ? "relative" : ""}
        ${toggleConfirm ? "relative" : ""}
        ${cardOption ? "relative" : ""}
        ${accessTipError ? "relative" : ""}
         w-full flex flex-col h-screen`}
    >
      <div
        className={` ${reservationState?.errorMessage?.error ? "blur-sm" : ""}
          ${reservationState?.errorMessage?.msg ? "blur-sm" : ""}
          ${reservationState?.errorMessage?.detail ? "blur-sm" : ""}
          ${reservationState?.reservationResponse?.created_at ? "blur-sm" : ""}
          ${reservationState?.errorTransactionStatusMessage ? "blur-sm" : ""}
          ${reservationState?.isLoading ? "blur-sm" : ""}
          ${toggleConfirm ? "blur-sm" : ""}
          ${cardOption ? "blur-sm" : ""}
           ${accessTipError ? "blur-sm" : ""}
          flex flex-col w-full h-screen`}
      >
        <div className="">
          {/* <span className="text-sm font-bold mt-8">{reservationState?.placeDetails.name}</span> */}
          <div
            className={`flex flex-row items-center absolute top-10 left-3`}
            onClick={() => handleBack()}
          >
            <img src={prevIcon} className="text-primary-pwa drop-shadow-xl" alt="rate-star" />
          </div>
          {
          windowSize.innerWidth && !!reservationState.restaurants?.photos?.length &&
            <img
              alt="google map"
              className="object-cover h-40 w-full"
              src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=200&photo_reference=${reservationState.restaurants.photos[0].photo_reference}&key=${DUARA_KEY}`}
            />
          }
          {/* <iframe
            width={'auto'}
            height={156}
            style={{ border: 0 }}
            className="w-full"
            loading="lazy"
            title="google"
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAP_KEY}
            &q=${reservationState.placeDetails.address}`}
          >
          </iframe> */}
        </div>
        <div className="rounded-t-lg bg-white -mt-4 relative z-10">
          <div className="text-xl text-primary-pwa font-bold mt-4 mx-3">Contact details</div>
          <div className="text-base font-bold mt-5 mx-3">Reservation for:</div>
          <div className="flex flex-col mx-4 mt-3 text-base">
            <div className="flex flex-row items-center my-1">
              <img src={reservationLocation} alt="cross-icon" className="h-5" />
              <div className="ml-2">{reservationState.placeDetails.name}</div>
            </div>
            <div className="flex flex-row items-center my-1">
              <img src={reservationPeople} alt="cross-icon" className="h-5" />
              <div className="ml-2">{reservationState.payloadDetails.number_of_people} {reservationState.payloadDetails.number_of_people > 1 ?' people':'person'}</div>
            </div>
            <div className="flex flex-row items-center my-1">
              <img src={reservationDate} alt="cross-icon" className="h-5" />
              <div className="ml-2">{reservationState.payloadDetails.chosen_day}</div>
            </div>
            <div className="flex flex-row items-center my-1">
              <img src={reservationClock} alt="cross-icon" className="h-5" />
              <div className="ml-2">{reservationState.payloadDetails.chosen_time}</div>
            </div>
          </div>
          <div className="my-3 mx-3">
            <form
              className="pt-3 rounded-md"
              onSubmit={handleSubmit(handleReservationInfo)}
            >
              <div className="flex flex-row mt-1">
                <div className="w-1/2 mr-2 ">
                  <label className="mt-2 text-label text-base">First Name *</label>
                  <input
                    className="rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="Joe"
                    {...register("firstName", {
                      required: true,
                    })}
                  />
                  {errors.firstName && (
                    <p className="text-red-600 text-sm">First Name required</p>
                  )}
                </div>
                <div className="w-1/2">
                  <label className="mt-2 text-label text-base">
                    Last Name *
                  </label>
                  <input
                    className="rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="Doe"
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && (
                    <p className="text-red-600 text-sm">Last Name required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-3">
                <label className="mt-2 text-label text-base">
                  Phone Number *
                </label>
                <input
                  className="rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="number"
                  placeholder="0700000000"
                  {...register("primaryNumber", {
                    required: true,
                    // maxLength: 10
                  })}
                />
                {errors.primaryNumber && (
                  <p className="text-red-600 text-sm">
                   Phone number is required
                  </p>
                )}
              </div>
              <div className="mt-6 flex flex-row items-center justify-between bg-white">
                <button
                  type="submit"
                  className="rounded-lg py-2 my-4 bg-accent text-white text-base w-full flex flex-row justify-center items-center"
                >
                  Confirm Reservation  <img
                  src={reserveIcon}
                  alt="reserve-icon"
                  className={`w-4 h-4 ml-1`}
                />
                </button>
              </div>
            </form>
          </div>
          {/* <div className="mt-6 px-6 flex flex-col items-center">
             <button
                className="rounded-full p-2 my-4 bg-red-300 text-white text-base"
                type="button"
                onClick={() => {
                  navigate('/searchRestaurant')
                  dispatch(reservationActions.updatePlaceDetails({}))
                  dispatch(reservationActions.searchRestaurantSuccess({}))
                }}
              >
                <img src={circleCross} alt="cross-icon" className="h-12" />
              </button>
              <span className="text-red-300 pb-8">Cancel Reservation</span>
          </div> */}
        </div>
      </div>

      <div
        className={`${toggleConfirm ? "absolute blur-none" : "hidden"}
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ConfirmTipPopup />
      </div>
      <div
        className={`${reservationState?.isLoading ? "absolute blur-none" : "hidden"
          } flex flex-col justify-center items-center h-full w-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-20 mx-auto text-sm mb-2`}
        />
      </div>

      <div
        className={`${reservationState?.reservationResponse.created_at
          ? "absolute blur-none"
          : "hidden"
          } flex flex-col items-center w-full h-full justify-center`}
      >
        <ReservationTransactionCard />
      </div>
      <div
        className={`${reservationState?.errorTransactionStatusMessage ||
          reservationState?.errorMessage?.msg ||
          reservationState?.errorMessage?.error ||
          reservationState?.errorMessage?.detail
          ? "absolute blur-none"
          : "hidden"
          } flex justify-center items-center w-full h-full`}
      >
        <ReservationTransactionError />
      </div>
    </div>
  );
}
