import React, { useState } from "react";
import LargeQr from "../../assets/Images/largeQr.png";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import "./share.css";
import { RWebShare } from "react-web-share";
import { qrcodeEndpoint, stagingUrl, testUrl } from "../../helper";
import { useTipJarLocalState } from "../../../src/components/Tipjar/state/local-state/useTipjarLocalState";

export default function ShareQrCode({ setQrCode }) {
  const { activeEntity } = useTipJarLocalState();
  console.log("STAGING ENDPOI --> ", stagingUrl);
  const balanceState = useSelector((state) => state.balance);
  const [copyState, setCopyState] = useState({ value: "", copied: false });
  return (
    <div className="px-8  w-full h-10/12 bg-white mt-4 pt-2 flex flex-col relative pb-4 rounded-3xl z-50">
      {/* <img src={inviteSacco} alt="error-img" className="h-28 pb-4" /> */}
      {/* <p className="justify-self-center text-lg text-center py-2">
        Referral
      </p> */}
      {/* <p className="justify-self-center text-lg text-center py-2">
        Scan me to tip
      </p> */}
      <div className="flex justify-center">
        <QRCode
          value={`${stagingUrl}${activeEntity?.shukran_id}`}
          size={200}
          fgColor="#E75581"
          style={{ margin: "20px" }}
        />

        {/* <img src={LargeQr} style={{ width: "80%", height: "120px" }} /> */}
      </div>
      <div className="flex flex-col items-center justify-center mt-2">
        <p className="mb-4 shukranID">SHUKRAN ID: {activeEntity?.shukran_id}</p>
        <RWebShare
          data={{
            text: "Tip me using this link, Shukran, Tipping changes lives!",
            url: `${stagingUrl}${activeEntity?.shukran_id}`,
            title: "Tip Me",
          }}
        >
          <button className="share_btn">SHARE</button>
        </RWebShare>

        <CopyToClipboard
          text={`${stagingUrl}${activeEntity?.shukran_id}`}
          onCopy={() => setCopyState({ copied: true })}
        >
          {copyState?.copied ? (
            <button className="share_btn2">
              {" "}
              <i class="fas fa-check-circle"></i>&nbsp;LINK COPIED
            </button>
          ) : (
            <button className="share_btn2">
              {" "}
              <i class="fa fa-clipboard" aria-hidden="true"></i> &nbsp;COPY LINK
            </button>

            // <button className="w-65 h-5 bg-[] text-white rounded-lg">
            //   copy to clipboard
            // </button>
          )}
        </CopyToClipboard>
      </div>
    </div>
  );
}
