import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import loader from "../../assets/Icons/Registration/loader.svg";
import InputComp from "../../components/GeneralUtils/input.js";
import logo from "../../assets/Logos/shukran_logo.svg";
import {
  saveToSessionStorage,
  getFromSessionStorage,
} from "../../Services/Storage";
import { useSelector, useDispatch } from "react-redux";

import eye from "../../assets/Icons/Dashboard/eye_open.svg";
import eyeClosed from "../../assets/Icons/Registration/eye_closed.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import cross from "../../assets/Icons/Rating/not_found.svg";
import {
  forgotPassword,
  resetPassword,
  verifyOtpCode,
} from "../../state/reducers/user/user.thunk";
import { resetActions } from "../../state/reducers/reset/reset.actions";
import { formatPhoneNumber } from "../../helper";

const VerifyCode = () => {
  const navigate = useNavigate();
  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [thirdCode, setThirdCode] = useState("");
  const [fourthCode, setFourthCode] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const [isError, setIsError] = useState(false);
  const [errorBody, setErrorBody] = useState();
  const [isClickedNew, setIsClickedNew] = useState(false);
  const [isClickedConfirm, setIsClickedConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); //Error Handling

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const resetState = useSelector((state) => state.reset);
  const userState = useSelector((state) => state.reset);

  // resend code
  function resendCode() {
    let params = {
      phone: `${resetState.phone}`,
    };
    dispatch(forgotPassword(params));
  }

  useEffect(() => {
    return () => {
      dispatch(resetActions.resetPasswordError([]));
      dispatch(resetActions.forgotPasswordError([]));
      dispatch(resetActions.forgotPasswordSuccess([]));
      dispatch(resetActions.verifyOtpSuccess([]));
      dispatch(resetActions.resetPasswordSuccess({}));
    };
  }, [dispatch]);

  // //Combine the 4 inputs into an otp code
  const getOTP = () => {
    return {
      otp: firstCode + secondCode + thirdCode + fourthCode,
    };
  };

  // useEffect(() => {
  //   if (firstCode && secondCode && thirdCode && fourthCode) {
  //     SubmitOTP();
  //   }
  // }, [firstCode, secondCode, thirdCode, fourthCode]);

  const handleOTPSubmit = (data) => {
    if (data.otpCode) {
      let params = {
        phone: formatPhoneNumber(resetState.phone),
        nonce: data.otpCode,
      };

      dispatch(verifyOtpCode(params));

      saveToSessionStorage("token", resetState.token);
      saveToSessionStorage("ShukranId", resetState.shukran_id);
    }
  };

  const handlePasswordSave = (data) => {
    if (data.newPass !== data.confirmPass) {
      setConfirmError("New and Confirm values don't match");
    } else {
      let params = {
        password: data.newPass,
      };

      dispatch(resetPassword(params, resetState.verifyResponse.data.token));
    }
  };

  const OtpForm = () => (
    <form className="flex flex-col" onSubmit={handleSubmit(handleOTPSubmit)}>
      <label className="text-subtle text-center mb-1 italic text-sm">
        OTP code
      </label>
      <input
        className="border-solid border border-[#95BD75] rounded-md focus:border-[#50A2A7] placeholder:text-base text-black outline-none text-xl h-12 placeholder:text-center w-20 mx-auto px-3"
        type="number"
        placeholder="XXXX"
        {...register("otpCode", {
          required: true,
          maxLength: 4,
        })}
      />
      {errors.otpCode?.type === "maxLength" && (
        <p className="text-red-600 text-sm text-center">
          OTP code has to be 4 numbers
        </p>
      )}
      {errors.otpCode?.type === "required" && (
        <p className="text-red-600 text-sm text-center">OTP code required</p>
      )}
      <button
        type="submit"
        className="rounded-full bg-[#64C8CE] text-white text-base w-full mt-7 px-10 py-4"
      >
        SUBMIT OTP CODE
      </button>
    </form>
  );

  const PasswordResetForm = () => (
    <form
      className="mt-2 bg-white flex flex-col pb-4 w-full"
      onSubmit={handleSubmit(handlePasswordSave)}
    >
      {/* <div className="flex flex-row items-center justify-between mt-2">
        <h2 className="text-center text-base">Enter new password</h2>{" "}
        <button
          className="text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(resetActions.verifyOtpSuccess([]));
            dispatch(resetActions.resetPasswordSuccess({}));
          }}
        >
          X
        </button>
      </div> */}
      {resetState.verifyResponse.success && !resetState.results.success && (
        <div className="rounded-sm bg-primary-pwa p-2 text-primary">
          <span className="px-2 text-white">
            {resetState.verifyResponse.msg}
          </span>
        </div>
      )}
      <div className="flex flex-col mt-3 h-14 mb-2">
        <label className="text-subtle italic text-sm">New Password</label>
        <div className="flex flex-row items-center">
          <input
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            type={isClickedNew ? "text" : "password"}
            placeholder={isClickedNew ? "password123" : "*******"}
            {...register("newPass", {
              required: true,
            })}
          />
          <div
            className="text-l -ml-6"
            onClick={() => setIsClickedNew(!isClickedNew)}
          >
            {isClickedNew ? (
              <img src={eye} alt="eye" className="h-6" />
            ) : (
              <img src={eyeClosed} alt="eye" className="h-6" />
            )}
          </div>
        </div>
        {errors.newPass && (
          <p className="text-red-600 text-sm mt-2">This field is required</p>
        )}
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Confirm Password</label>
        <div className="flex flex-row items-center">
          <input
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            // ref={confirmPasswordRef}
            // onChange={(e) => setConfirmPass(e.target.value)}
            onFocus={() => setConfirmError("")}
            type={isClickedConfirm ? "text" : "password"}
            placeholder={isClickedConfirm ? "password123" : "*******"}
            {...register("confirmPass", {
              required: true,
            })}
          />
          <div
            className="text-l -ml-6"
            onClick={() => setIsClickedConfirm(!isClickedConfirm)}
          >
            {isClickedConfirm ? (
              <img src={eye} alt="eye" className="h-6" />
            ) : (
              <img src={eyeClosed} alt="eye" className="h-6" />
            )}
          </div>
        </div>
        {errors.confirmPass && (
          <p className="text-red-600 text-sm mt-2">
            This field doesn't match new password
          </p>
        )}
        {confirmError && (
          <p className="text-red-600 text-sm mb-1 w-full">{confirmError}</p>
        )}
      </div>
      <button
        type="submit"
        // onClick={handlePasswordSave}
        className="rounded-full bg-[#64C8CE] text-white text-base w-full mt-7 px-10 py-4"
      >
        Reset Password
      </button>
    </form>
  );

  useEffect(() => {
    if (resetState.errorMessage.msg) {
      setTimeout(() => {
        dispatch(resetActions.resetPasswordError([]));
        dispatch(resetActions.forgotPasswordError([]));
        // navigate("/login");
      }, 4000);
    }
    if (resetState.forgotResponse.success) {
      setTimeout(() => {
        dispatch(resetActions.forgotPasswordSuccess([]));
      }, 3000);
    }
    if (resetState.results.success) {
      setTimeout(() => {
        dispatch(resetActions.resetPasswordSuccess([]));
        dispatch(resetActions.verifyOtpSuccess([]));
        navigate("/login", { replace: true });
      }, 4000);
    }
    return () => {};
  }, [resetState.errorMessage, resetState.forgotResponse, resetState.results]);

  return (
    <div
      className={`${resetState.errorMessage.error ? "relative" : ""}
        ${resetState.results.success ? "relative" : ""}
        ${resetState.isLoading ? "relative" : ""}
        ${resetState.errorMessage.msg ? "relative" : ""}
        w-full flex flex-col
    `}
    >
      {/* ${resetState.verifyResponse.success ? "blur-sm" : ""} */}
      <div
        className={`${resetState.errorMessage.error ? "blur-sm" : ""}
          ${resetState.forgotResponse.success ? "blur-sm" : ""}
          ${resetState.results.success ? "blur-sm" : ""}
          ${resetState.errorMessage.msg ? "blur-sm" : ""}
          ${
            resetState.isLoading ? "blur-sm" : ""
          } flex flex-col items-center h-full mt-40 px-6 w-full`}
      >
        <img src={logo} alt="Shukran Logo" className="size mx-auto mb-7" />
        {resetState.verifyResponse.success && !resetState.results.success ? (
          <h2 className="text-center text-base">Enter new password</h2>
        ) : (
          <>
            <h3 className="text-lg text-black mb-4 text-center">
              {" "}
              Verify your phone number
            </h3>
            <p className="text-base mb-4 text-black text-center">
              Enter sms verification code sent to 07********{" "}
            </p>
          </>
        )}
        {/* <div className="flex flex-row justify-between pt-1 w-fit"> */}
        {resetState.verifyResponse.success && !resetState.results.success ? (
          <PasswordResetForm />
        ) : (
          <OtpForm />
        )}
        {/* </div> */}
        <div className="mt-2 pt-4">
          {errorMessage && (
            <p className="text-red-600 text-sm text-center"> {errorMessage} </p>
          )}
          {resetState.isLoading && (
            <span className="flex">
              <img src={loader} alt="Loader" className="rounded animate-spin" />
              <p className="pl-3">Confirming code</p>
            </span>
          )}
        </div>
        <div className="pt-4 flex flex-row mx-auto">
          <span className="text-primary">Didn't receive code? </span>
          <div
            className="border-none underline uppercase text-primary-pwa ml-3"
            onClick={(e) => {
              resendCode();
            }}
          >
            Resend Code
          </div>
        </div>
      </div>
      <div
        className={`${
          resetState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          resetState.forgotResponse.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="p-4 bg-white pt-10 w-4/5 drop-shadow-xl flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              dispatch(resetActions.forgotPasswordSuccess([]));
            }}
          >
            X
          </button>
          <img src={checkCircle} alt="error-img" className="h-12" />

          <h2
            className={
              resetState.forgotResponse.success
                ? "text-center font-bold text-lg"
                : "hidden"
            }
          >
            Success
          </h2>
          <p
            className={
              resetState.forgotResponse.msg
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {resetState.forgotResponse.msg}
          </p>
        </div>
      </div>
      <div
        className={`${
          resetState.resetError.detail ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="p-4 bg-white pt-10 w-4/5 drop-shadow-xl flex flex-col relative pb-4 rounded-3xl z-10">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(resetActions.resetPasswordError([]));
              navigate("/password/forgot");
            }}
          >
            X
          </button>
          <img src={cross} alt="error-img" className="h-12" />
          <h2
            className={
              resetState.resetError.detail
                ? "text-center font-bold pt-2 text-lg"
                : "hidden"
            }
          >
            Fail
          </h2>
          <p
            className={
              resetState.resetError.detail
                ? "justify-self-center	text-lg text-center py-1"
                : "hidden"
            }
          >
            {resetState.resetError.detail}
          </p>
        </div>
      </div>
      <div
        className={`${
          resetState.results.success ? "absolute blur-none" : "hidden"
        } drop-shadow-2xl flex justify-center items-center w-full h-full`}
      >
        <div className="p-4 bg-white pt-10 w-4/5 drop-shadow-xl flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              dispatch(resetActions.resetPasswordSuccess([]));
              navigate("/login");
            }}
          >
            X
          </button>
          <img src={checkCircle} alt="error-img" className="h-12" />

          <h2
            className={
              resetState.results.msg
                ? "text-center font-bold text-lg"
                : "hidden"
            }
          >
            Success
          </h2>
          <p
            className={
              resetState.results.msg
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {resetState.results.msg}
          </p>
        </div>
      </div>
      <div
        className={`${
          resetState.errorMessage.msg ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="p-4 bg-white pt-10 w-4/5 drop-shadow-xl flex flex-col relative pb-4 rounded-3xl z-10">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(resetActions.resetPasswordError([]));

              dispatch(resetActions.forgotPasswordError([]));
              navigate("/login");
            }}
          >
            X
          </button>
          <img src={cross} alt="error-img" className="h-12" />
          <h2
            className={
              resetState.errorMessage.msg
                ? "text-center font-bold pt-2 text-lg"
                : "hidden"
            }
          >
            Fail
          </h2>
          <p
            className={
              resetState.errorMessage.msg
                ? "justify-self-center text-lg text-center py-1"
                : "hidden"
            }
          >
            {resetState.errorMessage.msg}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyCode;
