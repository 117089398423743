import React, { useRef, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import loader from "../../assets/Icons/Registration/loader.svg";

import { getFromSessionStorage } from "../../Services/Storage";
import actions from "../../state/index";
import logoutIcon from "../../assets/Icons/Profile/logOut.svg";
import ReviewCard from "../../components/Card/Reviews/Index";
import squareEdit from "../../assets/Icons/Profile/square_edit.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  changePassword,
  getRatings,
  getUserData,
  getUserSubscription,
  updateProfile,
} from "../../state/reducers/user/user.thunk";

import ArrowBackBtn from "../../components/Buttons/ArrowBackBtn";
import eye from "../../assets/Icons/Dashboard/eye_open.svg";
import eyeClosed from "../../assets/Icons/Registration/eye_closed.svg";
import { resetActions } from "../../state/reducers/reset/reset.actions";
import useIsIOS from "../../components/popups/useIsIos";
import SafariNotificationPrompt from "../../components/popups/SafariNotificationPrompt";
import NotificationPrompt from "../../components/popups/NotificationPrompt";

let sampleReviews = [];

const ProfilePage = () => {
  const [isReview, setIsReview] = useState(true);
  const [isPasswordForm, setIsPasswordForm] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [confirmError, setConfirmError] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedNew, setIsClickedNew] = useState(false);
  const [isClickedConfirm, setIsClickedConfirm] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState();
  const [isIOS] = useIsIOS();
  const navigate = useNavigate();

  const subscriptionState = useSelector((state) => state.subscription);
  const userAccount = useSelector((state) => state.account);
  const userInfo = useSelector((state) => state.balance.userInfo);
  const passwordReset = useSelector((state) => state.reset);
  const profileState = useSelector((state) => state.profile);
  let ratingsState = useSelector((state) => state.rating);
  let ratings =
    ratingsState.ratings?.rateddata?.length > 0
      ? ratingsState.ratings.rateddata.slice(0, 6)
      : sampleReviews;

  const { isAuthenticated } = userAccount;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // password form state
  const currPasswordRef = useRef();

  const dispatch = useDispatch();
  const { logOut } = bindActionCreators(actions, dispatch);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }

    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    let hasSubscribedBool = localStorage.getItem("hasSubscribed");

    if (isAuthenticated) {
      dispatch(getUserData(params, userAccount.userDetails.token));
      dispatch(getRatings(params, userAccount.userDetails.token));
      setHasSubscribed(!!hasSubscribedBool);
      dispatch(getUserSubscription(userAccount.userDetails.token));
    }
  }, []);

  const logout = () => {
    sessionStorage.clear();
    logOut();
  };

  useEffect(() => {
    if (profileState.errorMessage === 403) {
      navigate("/login");
    }
    return () => {};
  }, [profileState]);

  const handleProfileSave = (data) => {
    let payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      role: data.role,
      email: data.email,
      sacco_type: userInfo.sacco_type,
      user_type: userInfo.user_type,
    };
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };

    dispatch(updateProfile(payload, userAccount.userDetails.token));
    dispatch(getUserData(params, userAccount.userDetails.token));
  };

  const handlePasswordSave = (data) => {
    if (data.newPass !== data.confirmPass) {
      setConfirmError("New and Confirm values don't match");
    } else {
      let params = {
        // shukran_id: getFromSessionStorage("ShukranId"),
        old_password: data.currPass,
        new_password: data.newPass,
        // token: getFromSessionStorage("token"),
      };

      dispatch(changePassword(params, userAccount.userDetails.token));
    }
  };

  const PasswordForm = () => (
    <form
      onSubmit={handleSubmit(handlePasswordSave)}
      className={`
    ${isPasswordForm ? "absolute blur-none" : "hidden"} mt-8
    w-72 bg-white rounded-md shadow-sm py-5 px-8
    `}
    >
      <div className="flex flex-row justify-between items-center">
        <span className="text-base">Change Password</span>
        <div
          className=""
          onClick={() => {
            setIsPasswordForm(false);
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="flex justify-center items-center">
        {passwordReset.isLoading && (
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-6 mx-auto text-sm mb-1`}
          />
        )}
        {passwordReset.results?.msg && (
          <div className="px-7 py-2 bg-green-600 rounded-sm text-white text-xs">
            {passwordReset.results?.msg}
          </div>
        )}
        {passwordReset.errorMessage.msg && (
          <div className="px-7 py-1 bg-red-400 text-white">
            {passwordReset.errorMessage.msg}
          </div>
        )}
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Current Password</label>
        <div className="flex flex-row items-center">
          <input
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            ref={currPasswordRef}
            type={isClicked ? "text" : "password"}
            placeholder={isClicked ? "password123" : "*******"}
            {...register("currPass", {
              required: true,
            })}
          />
          <div
            className="text-l -ml-6"
            onClick={() => setIsClicked(!isClicked)}
          >
            {isClicked ? (
              <img src={eye} alt="eye" className="h-6" />
            ) : (
              <img src={eyeClosed} alt="eye" className="h-6" />
            )}
          </div>
        </div>
        {errors.currPass && (
          <p className="text-red-600 text-sm mt-2">This field is required</p>
        )}
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">New Password</label>
        <div className="flex flex-row items-center">
          <input
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            type={isClickedNew ? "text" : "password"}
            placeholder={isClickedNew ? "password123" : "*******"}
            {...register("newPass", {
              required: true,
            })}
          />
          <div
            className="text-l -ml-6"
            onClick={() => setIsClickedNew(!isClickedNew)}
          >
            {isClickedNew ? (
              <img src={eye} alt="eye" className="h-6" />
            ) : (
              <img src={eyeClosed} alt="eye" className="h-6" />
            )}
          </div>
        </div>
        {errors.newPass && (
          <p className="text-red-600 text-sm mt-2">This field is required</p>
        )}
      </div>
      <div className="flex flex-col mt-4 h-18 mb-2">
        <label className="text-subtle italic text-sm">Confirm Password</label>
        <div className="flex flex-row items-center">
          <input
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            // ref={confirmPasswordRef}
            // onChange={(e) => setConfirmPass(e.target.value)}
            onFocus={() => setConfirmError("")}
            type={isClickedConfirm ? "text" : "password"}
            placeholder={isClickedConfirm ? "password123" : "*******"}
            {...register("confirmPass", {
              required: true,
            })}
          />
          <div
            className="text-l -ml-6"
            onClick={() => setIsClickedConfirm(!isClickedConfirm)}
          >
            {isClickedConfirm ? (
              <img src={eye} alt="eye" className="h-6" />
            ) : (
              <img src={eyeClosed} alt="eye" className="h-6" />
            )}
          </div>
        </div>
        {errors.confirmPass && (
          <p className="text-red-600 text-sm mt-2">
            This field doesn't match new password
          </p>
        )}
        {confirmError && (
          <p className="text-red-600 text-sm mb-1 w-full">{confirmError}</p>
        )}
      </div>
      <button
        type="submit"
        // onClick={handlePasswordSave}
        className="rounded-full bg-[#64C8CE] text-white text-base w-full mt-7 px-10 py-4"
      >
        Change Password
      </button>
    </form>
  );

  const EditForm = () => (
    <form onSubmit={handleSubmit(handleProfileSave)} className="mt-8">
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">First Name</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("first_name", {
              required: true,
              value: userInfo.first_name,
            })}
            placeholder="Jane"
          />
        </div>
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Last Name</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("last_name", {
              required: true,
              value: userInfo.last_name,
            })}
            placeholder="Doe"
          />
        </div>
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Role</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("role", {
              required: true,
              value: userInfo.user_type,
            })}
            placeholder="Tipper"
          />
        </div>
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Email</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("email", {
              required: true,
              value: userInfo.email,
            })}
            placeholder="joe@gmail.com"
          />
        </div>
      </div>
      <button
        type="submit"
        className="rounded-full bg-[#64C8CE] text-white text-lg w-full mt-7 px-20 py-4"
      >
        Save
      </button>
    </form>
  );

  const SuccessRating = () => (
    <div className="  my-auto p-7 shadow bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(resetActions.updateProfileSuccess({}));
        }}
      >
        X
      </button>

      <img src={checkCircle} alt="error-img" className="h-20" />

      <p className="justify-self-center	text-lg text-center py-2">
        {profileState.results.msg}
      </p>
    </div>
  );

  return (
    <div
      className={`
      ${isPasswordForm ? "relative" : ""}
      ${profileState.isLoading ? "relative" : ""}
      ${profileState.results.success ? "relative" : ""}
      ${
        !subscriptionState.subscriptionRes?.subscription_status
          ? ""
          : "relative"
      }
      w-full flex flex-col justify-center items-center
    `}
    >
      <div
        className={`S
        ${isPasswordForm ? "blur-sm" : ""}
        ${profileState.isLoading ? "blur-sm" : ""}
        ${profileState.results.success ? "blur-sm" : ""}
        ${
          !subscriptionState.subscriptionRes?.subscription_status
            ? "blur-sm"
            : ""
        }
        w-full px-5 h-screen
      `}
      >
        <div className="flex flex-row justify-between mt-8 items-center">
          <Link to="/">
            <ArrowBackBtn />
          </Link>
          <p className="text-primary text-lg">Profile</p>
          <Link to="/login">
            <img
              onClick={() => logout()}
              src={logoutIcon}
              alt="Logout"
              className="iconSize"
            />
          </Link>
        </div>
        {/* User Details */}
        <div className="flex flex-row items-start justify-between mt-7">
          <div className="h-32 w-36 rounded-lg bg-primary-pwa flex flex-col justify-center items-center">
            <p className="text-white">
              {" "}
              {userInfo.shukran_id ? userInfo.shukran_id.split("", 2) : ""}{" "}
            </p>
          </div>
          <div className="flex flex-col items-start justify-between text-sm">
            <span className="pb-1">
              {userInfo.first_name} {userInfo.last_name}
            </span>
            <span className="pb-1">{userInfo.shukran_id}</span>
            <span className="pb-1">{userInfo.phone}</span>
            <span className="pb-1">{userInfo.user_type}</span>
          </div>
          <div
            className=""
            onClick={() => {
              setIsReview(!isReview);
              setIsEditForm(!isEditForm);
            }}
          >
            <img src={squareEdit} alt="Star" className="pr-3" />
          </div>
        </div>
        {/* Reviews */}
        {isReview && (
          <>
            <p className="text-header text-sm mt-3">REVIEWS</p>
            <div className="">
              {ratings?.map((review, idx) => (
                <ReviewCard key={review.id} data={review} />
              ))}
            </div>
            <button
              onClick={() => setIsPasswordForm(true)}
              className="rounded-full bg-[#64C8CE] text-white text-lg w-full mt-28 px-20 py-4"
            >
              Change Password
            </button>
          </>
        )}
        {isEditForm && <EditForm />}
      </div>
      {isPasswordForm && <PasswordForm />}
      {!subscriptionState.subscriptionRes?.subscription_status && (
        <SafariNotificationPrompt
          isSubscribed={subscriptionState.subscriptionRes?.subscription_status}
          hasSubscribed={hasSubscribed}
          setHasSubscribed={setHasSubscribed}
        />
      )}
      <div
        className={`${
          profileState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-4/5 h-full`}
      >
        <SuccessRating />
      </div>
      <div
        className={`${
          profileState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mb-1`}
        />
      </div>
    </div>
  );
};

export default ProfilePage;
