import React, { useState, useEffect } from "react";
import { Input } from "../../../components-shadcn/ui/input";
import { Button } from "../../../components-shadcn/ui/button";
import { FaCircleCheck } from "react-icons/fa6";
import { CircleCheck } from "lucide-react";
import { MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { X, Check } from "lucide-react";
import BackButton from "../../globals/BackButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components-shadcn/ui/dialog";
import { toast } from "sonner";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import { useDisbursePointSystem } from "../../TipGroups/state/server-state/useTipGroupsServerState";
import { useGetDistributionPointsData } from "../state/server-state/useTipJarServerState";

const ConfirmTipRecipients = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    activeEntityJAR,
    amountToDisburseFromDefaultJar,
    setAmountToDisburseFromDefaultJar,
  } = useTipJarLocalState();

  // Initialize members with default points if is_default_entity_jar is true
  const [tipJARMembers, setTipJARMembers] = useState(() => {
    return (activeEntityJAR?.members || []).map((member) => ({
      ...member,
      group_disbursement_points: activeEntityJAR.is_default_entity_jar
        ? member.group_disbursement_points
        : member.group_disbursement_points,
    }));
  });

  const [filteredMembers, setFilteredMembers] = useState(tipJARMembers);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [customPoints, setCustomPoints] = useState(0);
  const [distributionPointsData, setDistributionPointsData] = useState(null);
  const [disbursementPayload, setDisbursementPayload] = useState(null);
  const [jarBalance, setJarBalance] = useState(0);
  const navigate = useNavigate();
  const disbursePointSystem = useDisbursePointSystem();
  const isDisbursingPointSystem = disbursePointSystem.isLoading;
  const getDistributionPointsData = useGetDistributionPointsData();

  useEffect(() => {
    if (amountToDisburseFromDefaultJar) {
      setJarBalance(amountToDisburseFromDefaultJar);
    } else {
      setJarBalance(activeEntityJAR?.jar_balance || 0);
    }
  }, [activeEntityJAR?.jar_balance, amountToDisburseFromDefaultJar]);

  const handleGetDistributionPointsData = async (payload) => {
    try {
      const response = await getDistributionPointsData.mutateAsync(payload);
      setDistributionPointsData(response.data);
    } catch (error) {
      toast.error("An unexpected error occurred");
    }
  };

  useEffect(() => {
    if (activeEntityJAR && activeEntityJAR.id) {
      const payload = { entity_jar: activeEntityJAR.id };
      handleGetDistributionPointsData(payload);
    }
  }, [activeEntityJAR]);

  const calculateTotalPoints = () => {
    return tipJARMembers.reduce((sum, member) => {
      // Use customized points if available, otherwise use appropriate default
      const points =
        member.customized_disbursement_points ||
        (activeEntityJAR.is_default_entity_jar
          ? member.group_disbursement_points
          : member.group_disbursement_points);
      return sum + points;
    }, 0);
  };

  const [totalPoints, setTotalPoints] = useState(calculateTotalPoints());
  const [valuePerPoint, setValuePerPoint] = useState(
    totalPoints > 0 ? jarBalance / totalPoints : 0
  );

  useEffect(() => {
    const newTotalPoints = calculateTotalPoints();
    setTotalPoints(newTotalPoints);
    setValuePerPoint(newTotalPoints > 0 ? jarBalance / newTotalPoints : 0);
  }, [tipJARMembers, jarBalance]);

  useEffect(() => {
    const filtered = tipJARMembers.filter(
      (member) =>
        member?.user?.first_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        member.user?.last_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMembers(filtered);
  }, [searchTerm, tipJARMembers]);

  const handleBack = () => {
    setAmountToDisburseFromDefaultJar(null);
    navigate(-1);
  };

  const handleProceed = () => {
    if (!activeEntityJAR) {
      toast.error("Entity JAR not found");
      return;
    }
    if (jarBalance === 0) {
      toast.error("Your Tipjar balance is KES 0/=");
      return;
    }

    const payload = {
      entity_jar: activeEntityJAR.id,
      distribution_amounts: tipJARMembers
        .map((member) => {
          const distributionUser = distributionPointsData.find(
            (data) => data.user.shukran_id === member.user.shukran_id
          );

          // Calculate points based on whether it's a default jar
          const points =
            member.customized_disbursement_points ||
            (activeEntityJAR.is_default_entity_jar
              ? member.group_disbursement_points
              : member.group_disbursement_points);

          return {
            user_id: distributionUser ? distributionUser.user.id : null,
            amount: Math.floor(points * valuePerPoint),
          };
        })
        .filter((item) => item.user_id !== null),
    };

    setDisbursementPayload(payload);
    setIsConfirmModalOpen(true);
  };

  const handleSendRequestClick = async () => {
    try {
      await disbursePointSystem.mutateAsync(disbursementPayload);
      setIsConfirmModalOpen(false);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        navigate("/tipjar/tipjar-group/");
      }, 3000);
    } catch (error) {
      toast.error("Failed to send request");
    }
  };

  const openCustomizeModal = (member) => {
    setSelectedMember(member);
    setCustomPoints(
      member.customized_disbursement_points || member.group_disbursement_points
    );
    setIsModalOpen(true);
  };

  const handleCustomPointsChange = (value) => {
    setCustomPoints(Math.max(0, Math.min(10, value)));
  };

  const saveCustomPoints = () => {
    const updatedMembers = tipJARMembers.map((member) => {
      if (member.id === selectedMember.id) {
        return {
          ...member,
          customized_disbursement_points: customPoints,
        };
      }
      return member;
    });
    setTipJARMembers(updatedMembers);
    setIsModalOpen(false);
    toast.success("Points customized successfully");
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2">
      <BackButton onPress={handleBack} />

      <h1 className="text-2xl font-bold mt-6 mb-2" style={{ color: "#50A2A7" }}>
        Confirm tip recipients
      </h1>
      <p className="text-gray-500 mb-4">
        {`Customize members to include in the Group TipJar distribution of KES ${
          jarBalance?.toLocaleString() || 0
        }`}
      </p>

      <Input
        placeholder="Search name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 h-[55px] rounded-full shadow-md"
        style={{ borderColor: "#6CCBD1" }}
      />

      <div className="flex justify-between items-center mb-2">
        <span className="font-semibold">
          Tip JAR members ({tipJARMembers.length})
        </span>
      </div>

      <div className="flex-1 overflow-auto">
        {filteredMembers.map((member) => (
          <div
            key={member.id}
            className="flex items-center justify-between mb-4 p-3 rounded-lg"
            style={{ border: "1px solid #F5F5F5" }}
          >
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full mr-3">
                <p className="text-sm font-semibold text-white">
                  {member?.user?.first_name[0]}
                </p>
              </div>
              <div>
                <p className="font-semibold">{`${member?.user?.first_name} ${member?.user?.last_name}`}</p>
                <p className="text-sm text-gray-500">
                  {`${
                    member.customized_disbursement_points ||
                    member.group_disbursement_points
                  } points - KES ${Math.floor(
                    (member.customized_disbursement_points ||
                      member.group_disbursement_points) * valuePerPoint
                  ).toLocaleString("en-US")}`}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Button
                variant="outline"
                size="sm"
                className="mr-2 flex items-center"
                style={{ borderColor: "#E9B44C", color: "#E9B44C" }}
                onClick={() => openCustomizeModal(member)}
              >
                Customize
                <MdOutlineEdit className="ml-1" style={{ color: "red" }} />
              </Button>
              <CircleCheck size={24} color="#6CCBD1" />
            </div>
          </div>
        ))}
      </div>

      <Button
        className="mt-4 mb-7 bg-[#F2C773] text-white font-bold rounded-full h-[60px]"
        onClick={handleProceed}
        disabled={isDisbursingPointSystem}
      >
        {isDisbursingPointSystem ? "Processing..." : "PROCEED"}
      </Button>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="sm:max-w-[425px] rounded-3xl">
          <DialogHeader>
            <DialogTitle className="flex justify-between items-center">
              <span
                className="text-lg font-semibold"
                style={{ color: "#50A2A7" }}
              >
                Customize points
              </span>
              {/* <X className="cursor-pointer" onClick={() => setIsModalOpen(false)} /> */}
            </DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <div className="flex items-center mb-4">
              <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full mr-3">
                <p className="text-sm font-semibold text-white">
                  {selectedMember?.user?.first_name[0]}
                </p>
              </div>
              <div>
                <p className="font-semibold">{`${selectedMember?.user?.first_name} ${selectedMember?.user?.last_name}`}</p>
                <p className="text-sm text-gray-500">
                  {selectedMember?.user?.shukran_id}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between mb-4">
              <Button
                onClick={() => handleCustomPointsChange(customPoints - 1)}
                className="bg-[#F2C773B2]"
              >
                -
              </Button>
              <Input
                type="number"
                value={customPoints}
                onChange={(e) =>
                  handleCustomPointsChange(parseInt(e.target.value))
                }
                className="mx-2 text-center"
              />
              <Button
                onClick={() => handleCustomPointsChange(customPoints + 1)}
                className="bg-[#F2C773B2]"
              >
                +
              </Button>
            </div>
            <p className="text-center mb-4">
              Tip amount: KES {(customPoints * valuePerPoint).toFixed(2)}
            </p>
            <Button
              className="w-full h-[60px] rounded-full font-bold text-white"
              style={{ backgroundColor: "#F2C773" }}
              onClick={saveCustomPoints}
            >
              SAVE CHANGES
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Confirmation Modal */}
      <Dialog open={isConfirmModalOpen} onOpenChange={setIsConfirmModalOpen}>
        <DialogContent className="sm:max-w-[425px] rounded-3xl">
          <DialogHeader>
            <DialogTitle
              className="text-lg font-semibold text-center"
              style={{ color: "#50A2A7" }}
            >
              Confirm details
            </DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-center mb-2">
              Amount: KES {jarBalance.toLocaleString("en-US")}
            </p>
            <p className="text-center mb-2">
              {`Tip distribution: Manager and ${
                tipJARMembers.length - 1
              } individual(s)`}
            </p>
            {/* <p className="text-center mb-2">
              Total individuals: {tipJARMembers.length}
            </p> */}
            <p className="text-center mb-4">From: {activeEntityJAR.name}</p>
            <Button
              className="w-full h-[60px] rounded-full font-bold text-white"
              style={{ backgroundColor: "#F2C773" }}
              onClick={handleSendRequestClick}
              disabled={isDisbursingPointSystem}
            >
              {isDisbursingPointSystem ? "PROCESSING..." : "SEND REQUEST"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Success Modal */}
      <Dialog open={isSuccessModalOpen} onOpenChange={setIsSuccessModalOpen}>
        <DialogContent className="sm:max-w-[425px] rounded-3xl">
          <div className="py-4 text-center">
            <Check className="mx-auto mb-4 h-12 w-12 text-green-500" />
            <h2 className="text-lg font-semibold mb-2">
              Request sent successfully!
            </h2>
            <p className="text-sm text-gray-500">
              Your disbursement will be processed by your manager for approval
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmTipRecipients;
