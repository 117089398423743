import { BASE_URL } from "./constants/network";
import config from "./config/environments";

import axios from "axios";
export const instance = axios.create({});

export const apiClient = () => {
  const { REACT_APP_TEST_URL } = process.env;

  const axiosInstance = axios.create({
    baseUrl: REACT_APP_TEST_URL,
    responseType: "json",
  });
  return axiosInstance;
};

export const testUrl = config.apiEndpoint;
export const qrcodeEndpoint = config.qrCodeEndpoint;
console.log("TEST URL ---> ", testUrl);
console.log("BASE URL ---> ", BASE_URL);
export const stripeTest =
  "pk_test_51OeJHtChcCBTvsVbej9xpYQCYk2zGQxRGIJClgkXo5kdmPeCNWgOp7ujDMMrH4PBCWrajBSuiRH0OwFZrczclHsN001XgwXxAe";
export const stripeLive =
  "pk_live_51OeJHtChcCBTvsVbEfyttS3i0GjI2aIVTpfWPr14DJvvVuqvqPnonHeLgUrITNPSh01ecgyIPlLR8qfAOvlVTkvu00rDi2NJh9";
// export const baseUrl = "https://app.shukran.co/";
// export const stagingUrl = "https://beta.shukran.co/";
export const baseUrl = qrcodeEndpoint;
export const stagingUrl = qrcodeEndpoint;
// export const testUrl = "https://staging.shukran.co/";
export const DUARA_KEY = "AIzaSyCHHzlPfuUD6JyzylB84QHbeoe6iVIgrxA";
// export const testUrl = `${process.env.REACT_APP_PRODUCTION_URL}`;
// export const testUrl = "https://306c-41-80-97-238.in.ngrok.io/";

export const formatPhoneNumber = (phone) => {
  if (phone[0] === "0") {
    return phone.replace("0", "254");
  }
};

export const format254No = (phone) => {
  if (phone[0] !== "0") {
    return phone.replace("254", "0");
  } else {
    return phone;
  }
};
