import { useTipJarLocalState } from "../../../src/components/Tipjar/state/local-state/useTipjarLocalState";

import { useTipGroupsLocalState } from "../../../src/components/TipGroups/state/local-state/useTipGroupsLocalState";

const useResetZustandState = () => {
  useTipGroupsLocalState.getState().reset();
  useTipJarLocalState.getState().reset();
};

export default useResetZustandState;
