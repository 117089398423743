import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import tipper from "../../assets/Images/tipper.svg";
import receiver from "../../assets/Images/receiver.svg";
import { balanceActions } from "../../state/reducers/user/user.actions";
import useIsIOS from "../popups/useIsIos";
import avatar from "../../assets/onboarding/onboardingAvatar.png";
import champion from "../../assets/onboarding/champion.png";
import "./usertype.css";

const NewUserType = () => {
  const [isIOS, setIsIOS] = useIsIOS();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem("hasSeenOnboarding", JSON.stringify(true));
  }, []);
  const handleSelectCard = (userType) => {
    dispatch(balanceActions.setUserType(userType));
    localStorage.setItem("hasSeenOnboarding", true);
    if (userType === "tipper") {
      if (isIOS.platform === "web") {
        navigate("/search");
      } else {
        navigate("/registration");
      }
    } else if (userType === "service worker") {
      if (isIOS.platform === "web") {
        navigate("/onboardingSw");
      } else {
        navigate("/registration");
      }
    } else {
    }
  };

  return (
    <div className="w-full h-screen  flex flex-col items-center relative">
      <div className="w-full flex flex-row items-center justify-between h-12">
        <div className=" -top-8 w-1/2 flex items-start justify-start text-center ml-4">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-12 mb-4 flex items-start justify-start"
          />
        </div>
        <div className=" -top-8 w-1/3 flex items-end justify-end text-center mr-4">
          <img
            src={avatar}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-12 mb-4 flex items-end justify-end"
          />
        </div>
      </div>
      <div className="flex flex-col  w-11/12 p-2 h-full mt-10 items-center">
        <span
          style={{
            fontSize: "30px",
            color: "#50A2A7",
            alignSelf: "flex-start",
          }}
          className="flex align-start"
        >
          Hello there,
        </span>
        <span>
          Want to receive and save your tips, get started with Shukran today
        </span>
        <div className="sm:w-11/12 lg:w-6/12 md:6/12  h-8/12 mt-6 flex flex-col items-center justify-center there drop-shadow-xl">
          <img src={champion} className="w-full  object-contain h-40 -top-10" />
          <div className="p-2 flex flex-col">
            <span className="mt-2 ml-2 mr-2" style={{ fontStyle: "italic" }}>
              "Tips have helped me support my family, it has allowed me to
              educate my 2 siblings and support my mom"
            </span>
            <span
              style={{
                color: "#929FB1",
                fontSize: "14px",
                display: "flex",
                alignSelf: "flex-end",
              }}
              className="mr-2 mt-4"
            >
              ~Fenny from Ocean Basket
            </span>
          </div>
        </div>
        <button
          onClick={() => handleSelectCard("service worker")}
          className="mt-8 rounded-3xl py-4 bg-[#F2C773] text-white text-base uppercase w-full "
        >
          Join Us
        </button>
      </div>
      {/*
      <div className="absolute inset-x-0 bottom-16">
        <div className="relative w-screen flex flex-col items-center justify-between h-72">
          <div
            onClick={() => handleSelectCard("service worker")}
            className="w-10/12 drop-shadow-2xl bg-white rounded-lg flex flex-col items-center absolute bottom-4 h-44"
          >
            <img src={receiver} alt="logo of shukran" className="mt-5 h-24" />
            <span className="text-lg mt-5">Join Sacco</span>
          </div>
        </div>
        <div
          onClick={() => {
            localStorage.setItem("hasSeenOnboarding", true);
            navigate("/login");
          }}
          className="rounded-full bg-[#64C8CE] text-white text-lg w-10/12 my-2 py-4 text-center mx-auto cursor-pointer"
        >
          Sign In Instead
        </div>
      </div> */}
    </div>
  );
};

export default NewUserType;
