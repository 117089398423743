import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PageImage from "../../../assets/Images/onboarding/dividends.svg";
import BaseOnboard from "../BoilerPlate/BaseOnboard";

export default function OnboardingPage() {
  const [firstTimeLogIn, setFirstTimeLogIn] = useState("");
  const textContent =
    "Enjoy membership benefits by receiving dividends on your savings";
  const contentTitle = "DIVIDENDS";

  // const balance = userAccount.balance;
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const { userType, saccoType } = balanceState;

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");

    setFirstTimeLogIn(isLoggedIn);
    return () => {};
  }, []);

  return (
    <BaseOnboard
      pageImage={PageImage}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext={!firstTimeLogIn ? "/registration" : "/"}
      navigatePrev="/getLoans"
    />
  );
}
