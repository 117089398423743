import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useCalculateTipCharge } from "./state/server-state/useTipGroupsServerState";
import { Skeleton } from "../../../src/components-shadcn/ui/skeleton";
import { toast } from "sonner";

const StripeConfirmationDialog = ({ onClose, onSendTip, email }) => {
  const [transactionFee, setTransactionFee] = useState(0);
  const {
    entityToTip,
    entityJARToTip,
    selectedIndividualsToTip,
    entityTipAmount,
    entityJARTipAmount,
    selectedIndividualsTipAmount,
    selectedCurrency,
  } = useTipJarLocalState();

  const calclateTipCharge = useCalculateTipCharge();
  const isCalculatingTipCharge = calclateTipCharge.isLoading;
  const error = calclateTipCharge.error;

  const [totalTip, setTotalTip] = useState(0);
  const [calculateTipPayload, setCalculateTipPayload] = useState(null);

  const handleCalculateTransactionFee = async (payload) => {
    try {
      const response = await calclateTipCharge.mutateAsync(payload);
      if (!response || !response.data) {
        return;
      }

      const feeObject = response.data;
      const fee = feeObject.reduce((total, item) => total + item.amount, 0);
      setTransactionFee(fee);
    } catch (error) {
      toast.error("An unexpected error occurred");
    }
  };

  useEffect(() => {
    const calculateTotalTip = () => {
      let total = 0;
      let tipAmounts = [];

      if (entityTipAmount) {
        const amount = parseFloat(entityTipAmount[entityToTip?.id] || 0);
        total += amount;
        if (amount > 0) {
          tipAmounts.push({
            amount: amount.toFixed(2),
            currency: selectedCurrency,
          });
        }
      }

      if (entityJARTipAmount) {
        const amount = parseFloat(entityJARTipAmount[entityJARToTip?.id] || 0);
        total += amount;
        if (amount > 0) {
          tipAmounts.push({
            amount: amount.toFixed(2),
            currency: selectedCurrency,
          });
        }
      }

      if (selectedIndividualsTipAmount) {
        Object.values(selectedIndividualsTipAmount).forEach((amount) => {
          const parsedAmount = parseFloat(amount || 0);
          total += parsedAmount;
          if (parsedAmount > 0) {
            tipAmounts.push({
              amount: parsedAmount.toFixed(2),
              currency: selectedCurrency,
            });
          }
        });
      }

      return { total, tipAmounts };
    };

    const { total, tipAmounts } = calculateTotalTip();

    setTotalTip(total);

    setCalculateTipPayload(tipAmounts);
  }, [
    entityTipAmount,
    entityJARTipAmount,
    selectedIndividualsTipAmount,
    entityToTip,
    entityJARToTip,
    selectedCurrency,
  ]);

  useEffect(() => {
    if (calculateTipPayload && calculateTipPayload.length > 0) {
      handleCalculateTransactionFee(calculateTipPayload);
    }
  }, [calculateTipPayload]);

  const handleSendTip = () => {
    const receivers = [];

    if (entityToTip) {
      receivers.push({
        receiver_shukran_id: entityToTip.shukran_id,
        amount: parseFloat(entityTipAmount[entityToTip.id] || 0),
      });
    }

    if (entityJARToTip) {
      receivers.push({
        receiver_shukran_id: entityJARToTip.shukran_id,
        amount: parseFloat(entityJARTipAmount[entityJARToTip.id] || 0),
      });
    }

    selectedIndividualsToTip.forEach((individual) => {
      const shukranId = individual.user.shukran_id;
      const amount = parseFloat(selectedIndividualsTipAmount[shukranId] || 0);
      if (amount > 0) {
        receivers.push({
          receiver_shukran_id: shukranId,
          amount: amount,
        });
      }
    });

    const payload = {
      receivers: receivers,
      sender: email, // Replace with actual sender email
      method: "stripe",
      currency: selectedCurrency,
      charge_tipper: true,
    };

    onSendTip(payload);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-6 w-11/12 max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Confirm tips</h2>
          <button onClick={onClose}>
            <X className="h-6 w-6 text-gray-500" />
          </button>
        </div>
        <div className="mb-4">
          <h3 className="font-medium mb-2">Send Tip:</h3>
          {entityToTip && (
            <div className="flex justify-between mb-2">
              <span>{entityToTip.name}</span>
              <span>
                {selectedCurrency} {entityTipAmount[entityToTip.id]}
              </span>
            </div>
          )}
          {entityJARToTip && (
            <div className="flex justify-between mb-2">
              <span>{entityJARToTip.name}</span>
              <span>
                {selectedCurrency} {entityJARTipAmount[entityJARToTip.id]}
              </span>
            </div>
          )}
          {selectedIndividualsToTip.map((individual) => (
            <div key={individual.id} className="flex justify-between mb-2">
              <span>{`${individual.user.first_name} ${individual.user.last_name}`}</span>
              <span>
                {selectedCurrency}{" "}
                {selectedIndividualsTipAmount[individual.user.shukran_id]}
              </span>
            </div>
          ))}
        </div>
        <div className="border-t pt-4 mb-4">
          <div className="flex justify-between font-semibold">
            <span>Total Tip:</span>
            <span>
              {selectedCurrency} {totalTip.toFixed(2)}
            </span>
          </div>
          <div className="flex justify-between text-sm text-gray-600 mt-2">
            <span>Transaction Fee:</span>
            {isCalculatingTipCharge ? (
              <Skeleton className="h-[20px] w-[40px]" />
            ) : (
              <span>{`${selectedCurrency} ${transactionFee}`}</span>
            )}
          </div>
          <div className="flex justify-between font-semibold mt-2">
            <span>Total Payment:</span>
            <span>
              {selectedCurrency} {(totalTip + transactionFee).toFixed(2)}
            </span>
          </div>
        </div>
        <button
          onClick={handleSendTip}
          className="w-full bg-[#6CCBD1] text-white font-semibold py-3 rounded-md"
        >
          SEND TIP
        </button>
      </div>
    </div>
  );
};

export default StripeConfirmationDialog;
