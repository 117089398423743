import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { Button } from "../../../src/components-shadcn/ui/button";
import { toast } from "sonner";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import {
  useAddMemeberToEntity,
  useAddMemebersToEntity,
} from "../Tipjar/state/server-state/useTipJarServerState";
import { CircleMinusIcon, CirclePlus } from "lucide-react";

const Confirm = ({ setAddNewMember }) => {
  const [members, setMembers] = useState([]);
  const [memberDepartments, setMemberDepartments] = useState({});
  const [defaultDepartmentId, setDefaultDepartmentId] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const navigate = useNavigate();
  const { entityDepartments, activeEntity } = useTipJarLocalState();
  const addMultipleMembersToEntity = useAddMemebersToEntity();
  const isAddingMultipleMembersToEntity = addMultipleMembersToEntity.isLoading;

  useEffect(() => {
    const membersL = localStorage.getItem("members");
    const expressMembersL = localStorage.getItem("express_members");
    const departmentsL = localStorage.getItem("memberDepartments");

    const shukranMembers = JSON.parse(membersL) || [];
    const expressMembers = expressMembersL
      ? JSON.parse(expressMembersL).express_members || []
      : [];
    const departmentMappings = JSON.parse(departmentsL) || {};

    const defaultDept = entityDepartments.find(
      (dept) => dept.name === "Default"
    );
    if (defaultDept) {
      setDefaultDepartmentId(defaultDept.id);
    }

    const combinedMembers = [
      ...shukranMembers.map((member) => ({
        ...member,
        isExpress: false,
      })),
      ...expressMembers.map((member) => ({
        ...member,
        isExpress: true,
      })),
    ];

    setMembers(combinedMembers);

    if (isInitialLoad) {
      const updatedDepartments = combinedMembers.reduce((acc, member) => {
        const id = member.isExpress ? member.phone : member.id;
        if (departmentMappings[id]) {
          acc[id] = departmentMappings[id];
        } else if (member.isExpress) {
          acc[id] = member.department_id;
        } else if (defaultDepartmentId) {
          acc[id] = defaultDepartmentId;
        }
        return acc;
      }, {});

      setMemberDepartments(updatedDepartments);
      setIsInitialLoad(false);
    }
  }, [entityDepartments]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleDepartmentChange = (memberId, departmentId) => {
    const updatedDepartments = {
      ...memberDepartments,
      [memberId]: departmentId,
    };
    setMemberDepartments(updatedDepartments);
    localStorage.setItem(
      "memberDepartments",
      JSON.stringify(updatedDepartments)
    );
  };

  const handleRemoveMember = (member) => {
    const updatedMembers = members.filter((m) =>
      member.isExpress ? m.phone !== member.phone : m.id !== member.id
    );
    setMembers(updatedMembers);

    if (member.isExpress) {
      const expressMembers = updatedMembers.filter((m) => m.isExpress);
      localStorage.setItem(
        "express_members",
        JSON.stringify({ express_members: expressMembers })
      );
    } else {
      const shukranMembers = updatedMembers.filter((m) => !m.isExpress);
      localStorage.setItem("members", JSON.stringify(shukranMembers));
    }

    const updatedDepartments = { ...memberDepartments };
    delete updatedDepartments[member.isExpress ? member.phone : member.id];
    localStorage.setItem(
      "memberDepartments",
      JSON.stringify(updatedDepartments)
    );
  };

  const createGroup = async (event) => {
    event.preventDefault();

    if (members.length === 0) {
      toast.error("Please add at least one member before saving.");
      return;
    }

    // Separate members by type
    const shukranMembers = members.filter((m) => !m.isExpress);
    const expressMembers = members.filter((m) => m.isExpress);

    // Create base payload with empty arrays
    const payload = {
      entity_id: activeEntity.id,
      users_ids: [],
      express_members: [],
    };

    // Only add users_ids if there are Shukran members
    if (shukranMembers.length > 0) {
      payload.users_ids = shukranMembers.map((member) => ({
        user_id: member.id,
        department_id: memberDepartments[member.id] || defaultDepartmentId,
        can_join_entity_jar: true,
      }));
    }

    // Only add express_members if there are express members
    if (expressMembers.length > 0) {
      payload.express_members = expressMembers.map((member) => ({
        first_name: member.first_name,
        last_name: member.last_name,
        phone: member.phone,
        department_id: memberDepartments[member.phone] || member.department_id,
        can_join_entity_jar: true,
      }));
    }

    try {
      await addMultipleMembersToEntity.mutateAsync(payload);
      toast.success(`Members added successfully`);

      // Clean up localStorage
      localStorage.removeItem("members");
      localStorage.removeItem("express_members");
      localStorage.removeItem("memberDepartments");

      handleBack();
    } catch (error) {
      toast.error("Failed to add members");
    }
  };

  return (
    <form
      className="flex flex-col px-3 w-full h-screen mb-6 justify-between"
      onSubmit={createGroup}
    >
      <div className="flex flex-col w-full">
        <h2 className="text-3xl font-semibold text-primary-pwa mt-6">
          Confirm details for new members
        </h2>

        <div className="mt-2 w-full px-3" style={{ overflow: "scroll" }}>
          <div className="mb-3">
            {members.map((member) => (
              <div
                key={member.isExpress ? member.phone : member.id}
                className="flex flex-row items-center justify-between mt-2"
              >
                <button
                  type="button"
                  onClick={() => handleRemoveMember(member)}
                  className="mr-2"
                >
                  <CircleMinusIcon color="red" size={24} />
                </button>
                <div className="border-2 border-[#F5F5F5] w-3/5 h-[60px] flex items-center justify-center rounded-[8px]">
                  <div className="flex flex-row items-center w-full px-4">
                    <div className="w-[30px] h-[30px] rounded-[15px] bg-[#50A2A7] flex items-center justify-center">
                      <p className="text-xs font-semibold text-white text-center">
                        {member?.first_name[0]}
                      </p>
                    </div>
                    <div className="ml-2">
                      <p className="text-[#404B5A] text-[12px]">
                        {member.first_name} {member.last_name}
                      </p>
                      <p className="text-[#929FB1] text-[10px]">
                        {member.isExpress ? "N/A" : member.shukran_id}
                      </p>
                    </div>
                  </div>
                </div>
                <select
                  className="border-2 border-[#F5F5F5] w-1/3 h-[60px] ml-2 rounded-[8px] text-[12px] text-[#BCBCBC]"
                  onChange={(e) =>
                    handleDepartmentChange(
                      member.isExpress ? member.phone : member.id,
                      e.target.value
                    )
                  }
                  value={
                    memberDepartments[
                      member.isExpress ? member.phone : member.id
                    ] ||
                    defaultDepartmentId ||
                    ""
                  }
                >
                  {entityDepartments
                    ?.filter((el) => el?.active === true)
                    .map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                </select>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mb-2 mt-6">
        <button
          className="rounded-full bg-white text-[#6CCBD1] border-2 border-[#6CCBD1] text-m mx-auto w-full flex flex-row items-center justify-center h-[60px] mb-2"
          onClick={() => setAddNewMember(true)}
          type="button"
        >
          ADD NEW
          <CirclePlus color={"#6CCBD1"} className="ml-2" />
        </button>

        <Button
          className="w-full bg-[#F2C773] hover:bg-yellow-500 text-white rounded-full h-[60px]"
          type="submit"
          disabled={isAddingMultipleMembersToEntity}
        >
          {isAddingMultipleMembersToEntity ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            "CONFIRM"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Confirm;
