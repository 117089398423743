import React, { useState, useEffect, useCallback } from "react";
import { Input } from "../../components-shadcn/ui/input";
import { Button } from "../../components-shadcn/ui/button";

import { toast } from "sonner";
import { Card } from "../../components-shadcn/ui/card";
import { ChevronLeft, X, Hotel, Users2, OctagonAlert } from "lucide-react";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import BackButton from "../globals/BackButton";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useNavigate } from "react-router-dom";
import SelectCurrency from "./SelectCurrency";
import EntityJarIcon from "../../assets/Images/entity-jar-icon.png";
import IndividualJarIcon from "../../assets/Images/individual-jar-icon.png";
import GroupJarIcon from "../../assets/Images/group-tipjar-icon.png";

const TipAmountCalculator = () => {
  const { selectedCurrency } = useTipJarLocalState();
  const [tipAmounts, setTipAmounts] = useState({});
  const {
    entityToTip,
    entityJARToTip,
    selectedIndividualsToTip,
    setEntityToTip,
    setEntityJARToTip,
    setSelectedIndividualsToTip,
    setEntityTipAmount,
    setEntityJARTipAmount,
    setSelectedIndividualsTipAmount,
  } = useTipJarLocalState();
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleAmountChange = (id, value) => {
    setTipAmounts((prev) => ({ ...prev, [id]: value }));
  };

  const removeRecipient = (id, type) => {
    const newTipAmounts = { ...tipAmounts };
    delete newTipAmounts[id];
    setTipAmounts(newTipAmounts);

    if (type === "entity") {
      setEntityToTip(null);
    } else if (type === "entityJAR") {
      setEntityJARToTip(null);
    } else if (type === "individual") {
      setSelectedIndividualsToTip(
        selectedIndividualsToTip.filter((ind) => ind.user.shukran_id !== id)
      );
    }
  };

  const calculateTotal = useCallback(() => {
    const sum = Object.values(tipAmounts).reduce(
      (acc, amount) => acc + (parseFloat(amount) || 0),
      0
    );
    setTotal(sum);

  }, [tipAmounts]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal, tipAmounts]);

  const handleTip = () => {
    const entityTip = {};
    const entityJARTip = {};
    const individualsTip = {};

    if (entityToTip && tipAmounts[entityToTip.id]) {
      entityTip[entityToTip.id] = parseFloat(tipAmounts[entityToTip.id]) || 0;
    }

    if (entityJARToTip && tipAmounts[entityJARToTip.id]) {
      entityJARTip[entityJARToTip.id] =
        parseFloat(tipAmounts[entityJARToTip.id]) || 0;
    }

    let allIndividualsHaveAmount = true;
    selectedIndividualsToTip.forEach((individual) => {
      const amount = parseFloat(tipAmounts[individual.user.shukran_id]) || 0;
      if (amount > 0) {
        individualsTip[individual.user.shukran_id] = amount;
      } else {
        allIndividualsHaveAmount = false;
      }
    });

    // Check for entityToTip
    if (entityToTip && Object.keys(entityTip).length === 0) {
      toast.error("Please enter a tip amount for the entity.");
      return;
    }

    // Check for entityJARToTip
    if (entityJARToTip && Object.keys(entityJARTip).length === 0) {
      toast.error("Please enter a tip amount for the entity JAR.");
      return;
    }

    // Check for individuals
    if (selectedIndividualsToTip.length > 0 && !allIndividualsHaveAmount) {
      toast.error("Please enter tip amounts for all selected individuals.");
      return;
    }

    setEntityTipAmount(entityTip);
    setEntityJARTipAmount(entityJARTip);
    setSelectedIndividualsTipAmount(individualsTip);

    // If all checks pass, you can proceed with sending the tip data
    // sendTipData({ entityTip, entityJARTip, individualsTip });
    navigate("/confirm-group-tip-amount/");
  };

  const renderRecipient = (recipient, type) => {
    let avatar, name, code, id, color;
    if (type === "entity") {
      avatar = <img src={EntityJarIcon} alt="icon" />;
      name = recipient.name;
      code = recipient.shukran_id || recipient.name; // Use name as fallback for code
      id = recipient.id;
      color = "#F5E1BA";
    } else if (type === "entityJAR") {
      avatar = <img src={GroupJarIcon} alt="icon" />;
      name = recipient.name;
      code = recipient.shukran_id || recipient.name; // Use name as fallback for code
      id = recipient.id;
      color = "#C5B7DF";
    } else {
      avatar = recipient.user.first_name[0] + recipient.user.last_name[0];
      name = `${recipient.user.first_name} ${recipient.user.last_name}`;
      code = recipient.user.shukran_id;
      color = "#ABDADD";
    }

    return (
      <div key={code} className="mb-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <div
              className="w-10 h-10 rounded-full bg-[#6CCBD1] text-white flex items-center justify-center mr-3"
              style={{ backgroundColor: color }}
            >
              {avatar}
            </div>
            <div>
              <p className="font-semibold">{name}</p>
              <p className="text-sm text-gray-500">{code}</p>
            </div>
          </div>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => removeRecipient(code, type)}
          >
            <X className="h-4 w-4 text-red-500" />
          </Button>
        </div>
        {type == "entity" || type == "entityJAR" ? (
          <Input
            type="number"
            placeholder="Enter amount"
            value={tipAmounts[id] || ""}
            onChange={(e) => handleAmountChange(id, e.target.value)}
            className="w-full border-2 border-[#6CCBD1] rounded-full h-[60px] shadow-md outline-none"
          />
        ) : (
          <Input
            type="number"
            placeholder="Enter amount"
            value={tipAmounts[code] || ""}
            onChange={(e) => handleAmountChange(code, e.target.value)}
            className="w-full border-2 border-[#6CCBD1] rounded-full h-[60px] shadow-md outline-none"
          />
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2 w-full">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>

      <h1 className="text-2xl font-semibold text-[#6CCBD1] mb-4">
        Enter tip amounts
      </h1>

      <SelectCurrency />
      {!entityToTip && !entityJARToTip && !selectedIndividualsToTip.length && (
        <div className="flex justify-center items-center h-full">
          <div className="w-full flex flex-col gap-2 items-center">
            <OctagonAlert className="w-10 h-10" />
            <h1 className="font-semibold text-[#6CCBD1] mb-2">
              No tip recipient(s)
            </h1>
          </div>
        </div>
      )}

      <div className="flex-grow overflow-auto w-full">
        {entityToTip && renderRecipient(entityToTip, "entity")}
        {entityJARToTip && renderRecipient(entityJARToTip, "entityJAR")}
        {selectedIndividualsToTip.map((individual) =>
          renderRecipient(individual, "individual")
        )}
      </div>

      <div className="mt-6 mb-4">
        <p className="text-lg font-medium text-[#6CCBD1]">
          Total: {selectedCurrency} {total.toLocaleString("en-US")}
        </p>
      </div>

      <Button
        className="w-full bg-[#F2C773] hover:bg-yellow-500 text-white py-4 rounded-full mb-6 h-[60px]"
        onClick={handleTip}
      >
        TIP {selectedCurrency} {total.toLocaleString("en-US")}
      </Button>
    </div>
  );
};

export default TipAmountCalculator;
