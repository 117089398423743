import React, { useState } from "react";
import { Input } from "../../../src/components-shadcn/ui/input";
import { Checkbox } from "../../../src/components-shadcn/ui/checkbox";
import { Button } from "../../../src/components-shadcn/ui/button";
import { ArrowLeft, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import BackButton from "../globals/BackButton";
import { useTipJarLocalState } from "./state/local-state/useTipjarLocalState";
import { toast } from "sonner";

const DisburseFromDefault = () => {
  const [amount, setAmount] = useState("");
  const [useFullAmount, setUseFullAmount] = useState(false);
  const navigate = useNavigate();
  const { activeEntityJAR, setAmountToDisburseFromDefaultJar } =
    useTipJarLocalState();

  const handleBack = () => {
    navigate(-1);
  };

  const handleAmountChange = (value) => {
    if (!isNaN(value) && value >= 0) {
      setAmount(value);
      setUseFullAmount(false);
    }
  };

  const handleFullAmountChange = (checked) => {
    setUseFullAmount(checked);
    if (checked) {
      setAmount(activeEntityJAR.jar_balance.toString());
    }
  };

  const handleNextClick = () => {
    if (!amount && !useFullAmount) {
      toast.error("Please enter an amount or select full amount");
      return;
    }

    const numericAmount = parseInt(amount);
    setAmountToDisburseFromDefaultJar(numericAmount);
    navigate("/tipjar/confirm-tip-recepients");
  };

  const isAmountExceedsBalance =
    parseFloat(amount) > activeEntityJAR.jar_balance;

  return (
    <div className="flex flex-col w-full h-screen px-4">
      <div className="w-full pt-4">
        <BackButton onPress={handleBack} />
      </div>

      <h1 className="text-2xl font-medium text-[#6CCBD1] mt-6">
        {activeEntityJAR.name}
      </h1>

      <div className="bg-[#F0FEFF] rounded-lg p-4 mt-4 mb-6">
        <p className="text-sm text-center text-gray-500">Current Balance</p>
        <p className="text-2xl font-semibold text-center text-[#6CCBD1]">
          KSH{" "}
          {activeEntityJAR.jar_balance.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>

      <p className="text-lg text-[#6CCBD1] text-center mb-4">
        How much would you like to pay out?
      </p>

      <div className="relative">
        <Input
          type="number"
          value={amount}
          onChange={(e) => handleAmountChange(e.target.value)}
          placeholder="Enter amount"
          className="pl-3 h-[55px] shadow-md border-2 border-[#6CCBD1] rounded-full w-full flex items-center pr-16"
        />
        <button
          className={`absolute right-2 top-2 w-10 h-10 ${
            isAmountExceedsBalance ? "bg-gray-400" : "bg-[#F2C773]"
          } rounded-full flex items-center justify-center`}
          onClick={handleNextClick}
          disabled={isAmountExceedsBalance}
        >
          <ChevronRight color="white" size={24} />
        </button>
      </div>

      <div className="flex items-center justify-center space-x-2 mt-6">
        <Checkbox
          id="fullAmount"
          checked={useFullAmount}
          onCheckedChange={handleFullAmountChange}
          className="border-[#F2C773] data-[state=checked]:bg-[#F2C773]"
        />
        <label htmlFor="fullAmount" className="text-sm text-[#F2C773]">
          FULL AMOUNT KSH{" "}
          {activeEntityJAR.jar_balance.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </label>
      </div>
    </div>
  );
};

export default DisburseFromDefault;
