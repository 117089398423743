import React, { useState, useRef, useEffect } from "react";
import DashBoard from "../Dashboard/DashBoardHeader";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import logo from "../../assets/Icons/Dashboard/edit.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import { useNavigate } from "react-router-dom";
import popup from "../../assets/Icons/Popups/check_circle.svg";
import {
  loadBalanceResults,
  sendTip,
} from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { searchActions } from "../../state/reducers/search/search.actions";
import { getFromSessionStorage } from "../../Services/Storage";

export default function SendTip() {
  const [isShowed, setShowPin] = useState(false);
  const pinRef = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const [changedReceiver, setChangedReceiver] = useState("");

  //   for SuccessPop
  const [isClicked, setIsclicked] = useState(false);

  //   navigate
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { addShukranId, addNonShukranId } = bindActionCreators(
    actions,
    dispatch
  );

  // fetch information from tipping data
  const tipData = useSelector((state) => state.tipData);
  let receiverShukranId = tipData.shukranId;
  let amount = tipData.tipAmount;
  let receiverName = tipData.receiverName;
  let nonShukranId = tipData.nonShukranId;

  //   fetch information of the sender
  const senderData = useSelector((state) => state.account.userDetails);
  const balanceState = useSelector((state) => state.balance);
  const tippingState = useSelector((state) => state.tipping);

  const { tippingResponse } = tippingState;

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };

    dispatch(loadBalanceResults(params, senderData.token));
  }, []);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (balanceState.errorMessage === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (balanceState.errorMessage === 403 && !isLoggedIn) {
      navigate("/registration");
    }
    return () => {};
  }, [balanceState, navigate]);

  //   handleChangePin
  function hasTipped(balance) {
    return {
      type: "hasTipped",
      payload: balance,
    };
  }

  const submitTip = () => {
    if (nonShukranId) {
      let payload = {
        sender: senderData.shukranId,
        // sender_token: senderData.token,

        receivers: [
          {
            receiver_shukran_id: nonShukranId,
            amount: amount,
          },
        ],
        amount: amount,
        method: "mpesa",
        currency: tipData.currency === "USD" ? "USD" : "KES",
        charge_tipper: true,

        // method: "pesapal",
      };
      dispatch(sendTip(payload, senderData.token));
    } else {
      let payload = {
        sender: senderData.shukranId,
        // sender_token: senderData.token,
        receivers: [
          {
            receiver_shukran_id: receiverShukranId,
            amount: amount,
          },
        ],
        receiver_shukran_id: receiverShukranId,
        amount: amount,
        currency: tipData.currency === "USD" ? "USD" : "KES",
        charge_tipper: true,
      };
      dispatch(sendTip(payload, senderData.token));
    }
  };

  let title = "You`ve tipped successfully !";
  let message = `ksh.${amount} sent to ${receiverName}`;
  return (
    <div
      className={`
        ${tippingState.errorMessage.error ? "relative" : ""}
        ${tippingState.errorMessage.msg ? "relative" : ""}
        ${tippingResponse.success ? "relative" : ""}
        ${tippingState.isLoading ? "relative" : ""}
         w-full flex flex-col`}
    >
      <div
        className={`
          ${tippingState.errorMessage.error ? "blur-sm" : ""}
          ${tippingState.errorMessage.msg ? "blur-sm" : ""}
          ${tippingResponse.success ? "blur-sm" : ""}
          ${tippingState.isLoading ? "blur-sm" : ""}
          w-full flex flex-col bg-imag-dash`}
      >
        <DashBoard navigateLink={"tipAmount"} />
        <div className="bg-white rounded-t-3xl flex flex-col px-8">
          <div className="mt-5 h-12">
            <label className="text-subtle text-sm">Tip Recipient</label>
            <div className="flex flex-row justify-between">
              {nonShukranId ? (
                <div className="flex flex-row items-center justify-between">
                  <span className="text-base text-black w-36">
                    {tipData.nonShukranId}
                  </span>
                </div>
              ) : (
                <div className="flex flex-row items-center justify-between">
                  <span className="text-base text-black w-36">
                    {tipData.receiverName + " " + tipData.receiverLastName}
                  </span>
                  <span className="text-base text-gray-300">
                    {tipData.shukranId}
                  </span>
                </div>
              )}
              <button>
                <Link to="/tip" className="">
                  <img src={logo} className="" alt="icon" />
                </Link>
              </button>
            </div>
          </div>
          <div className="mt-4 h-12">
            <label className="text-subtle text-sm">Tip Amount</label>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <span className="text-base text-black">
                  Ksh {tipData.tipAmount}
                </span>
              </div>
              <Link to="/tipAmount" className="">
                <button>
                  <img src={logo} className="" alt="icon" />
                </button>
              </Link>
            </div>
          </div>

          <div className="mt-8">
            {!tippingResponse.success && (
              <button
                className="mt-28 rounded-3xl py-4 bg-accent text-white text-base uppercase w-full "
                onClick={submitTip}
              >
                Send Tip
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className={`
        ${tippingResponse.success ? "absolute blur-none" : "hidden"}
        w-full flex flex-col justify-center items-center
        `}
      >
        <div className="shadow pb-8 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
          <div
            className="self-end mt-4 mr-4"
            onClick={() => {
              dispatch(balanceActions.tippingSuccess({}));
              dispatch(searchActions.searchLoadSuccess([]));
              addShukranId({});
              addNonShukranId({});
              navigate("/rate");
            }}
          >
            <img src={cross} alt="cross-icon" className="h-6" />
          </div>
          <img src={popup} alt="error-img" className="h-32 mt-8" />
          <h2 className="text-center text-black	text-lg mt-10">
            Tipping successful!
          </h2>
          {nonShukranId ? (
            <p className="text-base text-primary text-center mt-3 bg px-8">
              {`Ksh. ${tipData.tipAmount} credited to ${tipData.nonShukranId}`}
            </p>
          ) : (
            <p className="text-base text-primary text-center mt-3 bg px-8">
              {`Ksh. ${tipData.tipAmount} credited to ${
                tipData.receiverName + " " + tipData.receiverLastName
              }`}
            </p>
          )}
        </div>
      </div>
      <div
        className={`${tippingState.isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center h-full w-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mb-1`}
        />
      </div>
      <div
        className={`${
          tippingState.errorMessage.error ? "absolute blur-none" : "hidden"
        }
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="p-4 bg-white pt-10  drop-shadow-lg w-4/5 flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(
                balanceActions.tippingError({
                  error: false,
                  msg: "",
                  data: "",
                })
              );
              dispatch(searchActions.searchLoadSuccess([]));
              addNonShukranId({});
              addShukranId({});
              // navigate("/rate");
            }}
          >
            X
          </button>
          <img src={NotFoundIcon} alt="error-img" className="h-12" />

          <h2
            className={
              tippingState.errorMessage.data
                ? "text-center font-bold mt-2 text-lg"
                : "hidden"
            }
          >
            {tippingState.errorMessage.data}
          </h2>
          <p
            className={
              tippingState.errorMessage.error
                ? "justify-self-center text-lg text-center py-1"
                : "hidden"
            }
          >
            {tippingState.errorMessage.msg}
          </p>
        </div>
      </div>
      <div
        className={`${
          tippingState.errorMessage.msg ? "absolute blur-none" : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <div className="p-4 bg-white pt-10 drop-shadow-lg w-4/5 flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(
                balanceActions.tippingError({
                  error: false,
                  msg: "",
                  data: "",
                })
              );
              dispatch(searchActions.searchLoadSuccess([]));
              addNonShukranId({});
              addShukranId({});
              navigate("/tip");
            }}
          >
            X
          </button>
          <img src={NotFoundIcon} alt="error-img" className="h-12" />

          <h2
            className={
              tippingState.errorMessage.msg
                ? "text-center font-bold text-lg mt-2"
                : "hidden"
            }
          >
            Tipping Error
          </h2>
          <p
            className={
              tippingState.errorMessage.msg
                ? "text-lg text-center py-1"
                : "hidden"
            }
          >
            {tippingState.errorMessage.msg}
          </p>
        </div>
      </div>
    </div>
  );
}
