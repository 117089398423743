import React, { useState } from "react";
import { ChevronRight, ChevronLeft } from "lucide-react";
import OnBoardWelcome from "../../assets/Images/onboarding/Welcome.png";
import Payments from "../../assets/Images/onboarding/Payments.png";
import Find from "../../assets/Images/onboarding/Find.png";
import Tipping from "../../assets/Images/onboarding/Tipping.png";
import Tip from "../../assets/Images/onboarding/Tip.png";

const TipJarOnboardingScreens = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "HELLO",
      description: "Welcome to Shukran Business!\nYour Digital Tipping Partner",
      imageSrc: OnBoardWelcome,
    },
    {
      title: "MANAGE",
      description: "Set up teams and individuals to receive group tips",
      imageSrc: Payments,
    },
    {
      title: "CUSTOMIZE",
      description:
        "Set up and manage custom distribution rules for your organization",
      imageSrc: Find,
    },
    {
      title: "SEPARATE",
      description:
        "Distinctively separate business funds from tips using the digital TipJAR",
      imageSrc: Tipping,
    },
    {
      title: "DISTRIBUTE",
      description: "Quickly and easily distribute of tips",
      imageSrc: Tip,
    },
  ];

  const handleNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const isLastSlide = currentSlide === slides.length - 1;

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Main content area with image */}
      <div className="relative flex-1">
        {/* Image container that fills width and starts from top */}
        <div className="w-full h-3/5">
          <img
            src={slides[currentSlide].imageSrc}
            alt={slides[currentSlide].title}
            className="w-full h-full object-cover"
          />
        </div>

        {/* Skip button overlay */}
        <button className="absolute top-4 right-4 px-4 py-1 rounded-full bg-pink-500 text-white text-sm z-10">
          Skip
        </button>
      </div>

      {/* Bottom content area */}
      <div className="flex flex-col items-center px-6 pb-8 mt-3">
        {/* Text content */}
        <div className="text-center mb-8">
          <h1 className="text-2xl font-bold mb-4">
            {slides[currentSlide].title}
          </h1>
          <p className="text-gray-600 whitespace-pre-line">
            {slides[currentSlide].description}
          </p>
        </div>

        {/* Navigation dots */}
        <div className="flex gap-2 mb-8">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full transition-all duration-300 ${
                index === currentSlide ? "bg-[#E9B44C] w-4" : "bg-gray-300"
              }`}
            />
          ))}
        </div>

        {/* Navigation buttons */}
        <div className="flex items-center justify-center w-full gap-8">
          {currentSlide > 0 && (
            <button
              onClick={handlePrevious}
              className="w-14 h-14 rounded-full flex items-center justify-center border border-[#E9B44C]"
            >
              <ChevronLeft className="w-6 h-6 text-[#E9B44C]" />
            </button>
          )}

          {isLastSlide ? (
            <button className="w-full py-3 bg-[#E9B44C] text-white rounded-full font-medium">
              GET STARTED
            </button>
          ) : (
            <button
              onClick={handleNext}
              className="w-14 h-14 rounded-full bg-[#E9B44C] flex items-center justify-center"
            >
              <ChevronRight className="w-6 h-6 text-white" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TipJarOnboardingScreens;
