import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import VisaIcon from "../../assets/Icons/Tipping/visa.svg";
import MpesaIcon from "../../assets/Icons/Tipping/mpesa.svg";
import RightIcon from "../../assets/Icons/Tipping/right_icon.svg";
import WastingGlass from "../../assets/Icons/wasting_glass.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import wallet from "../../assets/Icons/wallet.svg";
import BinIcon from "../../assets/Icons/Tipping/delete.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { searchActions } from "../../state/reducers/search/search.actions";
import {
  getCardTransactions,
  getUserData,
  initDeactivateCard,
} from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { getFromSessionStorage } from "../../Services/Storage";

export default function CreditOption() {
  const [cardEmail, setCardEmail] = useState("");
  const [cardId, setCardId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [cardOption, setCardOption] = useState(false);
  const [accessTipError, setAccessTipError] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [amounts] = useState([
    {
      option: "card",
      label: "Add New Card",
    },
  ]);
  const [usersCards] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.balance.userInfo);
  let searchState = useSelector((state) => state.search);
  const senderData = useSelector((state) => state.account.userDetails);
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const tipData = useSelector((state) => state.tipData);
  let receiverShukranId = tipData.shukranId;
  let amount = tipData.tipAmount;
  let nonShukranId = tipData.nonShukranId;
  let queryShukranId = tipData.queryShukranId;
  let queryName = tipData.queryName;
  const tippingState = useSelector((state) => state.tipping);
  const transactionState = useSelector((state) => {
    let uniqueIds = [];
    let arrFil = state.transactions?.cardTransactions?.data
      ? state.transactions?.cardTransactions?.data
      : [];
    let unique = arrFil.filter((element) => {
      let isDuplicate = uniqueIds.includes(element.authorization_json.last4);

      if (!isDuplicate) {
        uniqueIds.push(element.authorization_json.last4);
        return true;
      }

      return false;
    });

    return {
      cardTransactions: unique,
    };
  });
  const userAccount = useSelector((state) => state.account);

  useEffect(() => {
    if (tipData) {
      let newName = tipData.first_name + " " + tipData.last_name;
      setName(newName);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (tippingState.errorMessage === 403) {
      navigate("/login");
      dispatch(balanceActions.tippingError(""));
    }
    return () => {};
  }, [tippingState, dispatch, navigate]);

  useEffect(() => {
    if (tippingState.navigate.navigate === "/") {
      setTimeout(() => {
        navigate("/");
        dispatch(balanceActions.checkTippingRedirect(""));
      }, 1000);
    }
    return () => {};
  }, [tippingState]);
  //Handle enter key input

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);

    let params = {
      shukran_id: shukran_id,
    };
    if (isLoggedIn) {
      dispatch(getUserData(params, userAccount.userDetails.token));
      dispatch(getCardTransactions(userAccount.userDetails.token));
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (tippingState.cardResponse.success) {
      dispatch(getCardTransactions(userAccount.userDetails.token));
    }

    return () => {};
  }, [tippingState, dispatch, userAccount]);

  useEffect(() => {
    if (balanceState.errorMessage === 403) {
      navigate("/");
    }
    return () => {};
  }, [balanceState]);

  useEffect(() => {
    if (searchState.errorMessage === 403) {
      navigate("/login");
      dispatch(searchActions.searchLoadError(""));
    }
    if (tippingState.errorMessage === 403) {
      navigate("/login");
      dispatch(balanceActions.tippingError(""));
    }

    return () => {};
  }, [searchState, tippingState, navigate, dispatch]);

  const handleSetOption = (otpGroup, card) => {
    let depositPhone = userInfo.phone;
    if (depositPhone) {
      depositPhone = "0" + depositPhone.slice(3);
    }

    setCardEmail(card.customer_json.email);
    setCardId(card.id);
    if (otpGroup === "card") {
      // handleCardRemoval()
      setCardOption(true);
    }
  };

  const handleAddOption = (option) => {
    if (option.option === "card") {
      // setAccessTipError(true);
      navigate("/search");
    } else {
      navigate("/mpesaNo");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCardRemoval = () => {
    dispatch(initDeactivateCard(cardId, senderData.token));
    setCardOption("");
  };

  const ConfirmCardDelete = () => (
    <div className="px-4 mt-28 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <div className="flex flex-row-reverse items-center justify-between">
        <div
          onClick={() => {
            setPhoneNumber("");
            setCardOption(false);
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
        <p className="justify-self-center	text-base text-center py-2 px-12">
          Confirm removal of this card
        </p>
      </div>
      <div className="mt-2">
        {!tippingState.cardResponse.success && (
          <button
            className="rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            onClick={handleCardRemoval}
          >
            Remove Card
          </button>
        )}
      </div>
    </div>
  );

  const DeactivateCardProcess = () => (
    <div className="mt-28 px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {!tippingState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(balanceActions.deactivateCardSuccess({}));
            // navigate("/");
          }}
        >
          X
        </button>
      )}
      {tippingState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
      )}
      {tippingState.cardResponse.success && (
        <img src={checkCircle} alt="error-img" className="h-24" />
      )}
      {tippingState.cardResponse?.success && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {"Card removed successfully!"}
        </p>
      )}
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState?.errorMessage?.msg}
        </p>
      )}
    </div>
  );

  const DeactivateCardError = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col items-center relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.deactivateCardError(""));
        }}
      >
        X
      </button>
      <img src={WastingGlass} alt="Not found icon" className="w-20 h-20" />
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState?.errorMessage?.msg}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
        ${tippingState.errorMessage.error ? "relative" : ""}
        ${tippingState.tippingResponse.success ? "relative" : ""}
        ${tippingState.cardResponse.success ? "relative" : ""}
        ${tippingState.isLoading ? "relative" : ""}
        ${cardOption ? "relative" : ""}
         w-full flex flex-col`}
    >
      <div
        className={` ${tippingState.errorMessage.error ? "blur-sm" : ""}
          ${tippingState.errorMessage.detail ? "blur-sm" : ""}
          ${tippingState.tippingResponse.success ? "blur-sm" : ""}
          ${tippingState.cardResponse.success ? "blur-sm" : ""}
          ${tippingState.isLoading ? "blur-sm" : ""}
          ${cardOption ? "blur-sm" : ""}
          flex flex-col px-3 w-full h-screen`}
      >
        <div className="flex flex-row items-center mt-9">
          <div
            className={`flex flex-row items-center py-2 pl-2 pr-6`}
            onClick={() => handleBack()}
          >
            <img src={back} className="text-slate-400" alt="rate-star" />
          </div>
          <span className="text-base">back</span>
        </div>
        <div className="mx-2 mt-11 h-full">
          <div className="text-base font-semibold text-black">
            {!transactionState.cardTransactions.length && "Payment Methods"}
          </div>
          {/* <span className="text-sm text-slate-400">
            How would you like to send Ksh {tipData.tipAmount} tip?
          </span> */}
          <ul className="mt-4">
            {!transactionState.cardTransactions.length &&
              amounts.map((amount, idx) => (
                <li
                  className={`flex flex-row justify-between items-center bg-white p-4 mb-2 drop-shadow-xl`}
                  key={idx}
                >
                  <div
                    onClick={() => handleAddOption(amount)}
                    className="flex flex-row items-center"
                  >
                    <img
                      className="h-8 w-8"
                      src={idx === 0 ? wallet : MpesaIcon}
                      alt="icon"
                    />
                    <span className="ml-4">{amount.label}</span>
                  </div>
                  <div className="flex justify-content items-center">
                    <img src={RightIcon} alt="icon" />
                  </div>
                </li>
              ))}
          </ul>
          <ul className="mt-4 bg-white">
            <li className="text-base font-semibold text-black mb-2">
              Credit Cards
            </li>
            {!!transactionState.cardTransactions.length &&
              transactionState.cardTransactions.map((card, idx) => (
                <li
                  className={`flex flex-row justify-between items-center p-4 bg-accent-light-2 ${
                    idx === 0
                      ? "rounded-t-md border-2"
                      : "rounded-b-md border-2 border-t-0"
                  } border-gray-100`}
                  key={idx}
                  onClick={() => handleSetOption("card", card)}
                >
                  <div className="flex flex-row items-center">
                    <img src={VisaIcon} alt="icon" />
                    <div className="ml-4 flex flex-col">
                      <span className="text-sm">
                        XXXX XXXX XXXX {card.authorization_json.last4}
                      </span>
                      <span className="text-xs text-gray-500">
                        {card.authorization_json.brand}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <img src={BinIcon} alt="icon" />
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div
        className={`${cardOption ? "absolute blur-none" : "hidden"}
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ConfirmCardDelete />
      </div>
      <div
        className={`${
          tippingState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-20 mx-auto text-sm mb-2`}
        />
      </div>
      <div
        className={`${
          tippingState.cardResponse.success ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center w-full h-full`}
      >
        <DeactivateCardProcess />
      </div>
      <div
        className={`${
          tippingState.errorMessage?.msg ||
          tippingState.errorMessage?.error ||
          tippingState.errorMessage?.detail
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DeactivateCardError />
      </div>
    </div>
  );
}
