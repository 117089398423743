import React, { useEffect, useState, useRef } from "react";
import { Loader2, CirclePlus, RefreshCcw } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import user from "../../assets/Icons/Dashboard/Members.png";
import "./members.css";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import BackButton from "../globals/BackButton";
import { toast } from "sonner";
import { Button } from "../../../src/components-shadcn/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../src/components-shadcn/ui/select";
import {
  Dialog,
  DialogContent,
} from "../../../src/components-shadcn/ui/dialog";
import { useFetchDepartments } from "./state/server-state/useDepartmentServerState";
import { MoonLoader } from "react-spinners";
import { useAddMemeberToEntity } from "../Tipjar/state/server-state/useTipJarServerState";
import { useFetchEntityMembers } from "../Tipjar/state/server-state/useTipJarServerState";
import { set } from "lodash";

export default function EditGroup() {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const navigate = useNavigate();

  const [selectedMember, setSelectedMember] = useState({});
  const [memberToAdd, setMemberToAdd] = useState(null);

  const [changeGroup, setChangeGroup] = useState(false);
  const [selectFrom, setSelectFrom] = useState(false);
  const [proceed, setProceed] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const changeMemberDepartment = useAddMemeberToEntity();
  const isChangingMEmberDepartment = changeMemberDepartment.isLoading;

  const [changeDepartmentDialogOpen, setChangeDepartmentDialogOpen] =
    useState(false);

  const { activeDepartment, entityDepartments, activeEntity } =
    useTipJarLocalState();
  const [currentDepartment, setCurrentDepartment] = useState(null);
  const { data: depts, refetch } = useFetchDepartments(activeEntity?.id);

  if (depts === 403) {
    navigate("/login/");
  }

  const { data: entityMembers } = useFetchEntityMembers(activeEntity?.id);

  if (entityMembers === 403) {
    navigate("/login/");
  }

  useEffect(() => {
    if (depts && activeDepartment) {
      const current = depts?.find((dept) => dept.id === activeDepartment.id);
      setCurrentDepartment(current);
    }
  }, [depts, activeDepartment]);

  const handleBack = () => {
    navigate(-1);
  };

  const openChangeDepartmentDialog = (member) => {
    setSelectedMember(member);
    setSelectedDepartment(member.department);
    setChangeDepartmentDialogOpen(true);
  };

  const handleChangeDepartment = async (userId, newDepartmentId) => {
    const payload = {
      entity_id: activeEntity.id,
      user_id: userId,
      department_id: newDepartmentId,
      can_join_entity_jar: true,
    };

    try {
      await changeMemberDepartment.mutateAsync(payload);
      refetch();
      setChangeDepartmentDialogOpen(false);
    } catch (error) {
      throw error;
    }
  };

  const filteredAllMembers = entityMembers?.data?.filter((member) => {
    // Check if the member is not in the current department
    return !currentDepartment?.members?.some(
      (deptMember) => deptMember.user.id === member.user.id
    );
  });

  const handleAddToGroup = async (member) => {
    const payload = {
      entity_id: activeEntity.id,
      user_id: member.user.id,
      department_id: currentDepartment.id,
      can_join_entity_jar: true,
    };

    setMemberToAdd(member);

    try {
      await changeMemberDepartment.mutateAsync(payload);
      refetch();
      toast.success("Member added to department successfully");
    } catch (error) {
      toast.error("Failed to add member to department");
    }
  };

  const ConfirmChange = ({
    selectedMember,
    entityDepartments,
    onChangeDepartment,
  }) => {
    const [departmentChange, setDepartmentChange] = useState(
      selectedMember?.department?.id
    );
    const [isChangingDepartment, setIsChangingDepartment] = useState(false);
    const hasUserChangedDepartment = useRef(false);
    const activeDepartments = entityDepartments?.filter(
      (el) => el?.active === true
    );

    useEffect(() => {
      if (
        selectedMember &&
        entityDepartments &&
        !hasUserChangedDepartment.current
      ) {
        const memberDepartment = entityDepartments.find(
          (el) => el?.id === selectedMember?.department?.id
        );
        if (memberDepartment) {
          setDepartmentChange(memberDepartment?.id);
        }
      }
    }, [selectedMember, entityDepartments]);

    const handleDepartmentChange = (value) => {
      setDepartmentChange(value);
      hasUserChangedDepartment.current = true;
    };

    const handleChangeDepartment = async () => {
      setIsChangingDepartment(true);
      try {
        await onChangeDepartment(selectedMember.user.id, departmentChange);
        toast.success("Department changed successfully");
      } catch (error) {
        toast.error("Failed to change department");
      } finally {
        setIsChangingDepartment(false);
        hasUserChangedDepartment.current = false;
      }
    };

    return (
      <section id="confirmchange" className="w-full flex flex-col gap-4">
        <Select value={departmentChange} onValueChange={handleDepartmentChange}>
          <SelectTrigger className="w-full mt-5">
            <SelectValue placeholder="Select department" />
          </SelectTrigger>
          <SelectContent>
            {activeDepartments.map((department) => (
              <SelectItem key={department?.id} value={department?.id}>
                {department.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Button
          className="bg-accent text-white w-fit mt-3"
          disabled={isChangingDepartment}
          onClick={handleChangeDepartment}
        >
          {isChangingDepartment && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          Change department
        </Button>
      </section>
    );
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2 w-full items-center">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
        </div>
      </div>
      <div className="mx-4 mt-2 mb-6 h-full w-full">
        <div className="">
          <h2 className="text-3xl font-semibold text-primary-pwa">
            {`Manage ${activeDepartment?.name} department`}
          </h2>
          <p className="text-md font-semibold text-gray-400 w-full">
            Easily add and move members to the department from here
          </p>
        </div>

        <div className="mt-6">
          <div className="w-full flex flex-row items-center justify-between font-semibold">
            <h5>
              {currentDepartment?.name}{" "}
              {currentDepartment?.name &&
                `(${currentDepartment?.members?.length || 0})`}
            </h5>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              className="px-2 cursor-pointer"
              onClick={(e) => {
                navigate("/tipjar/add-members/");
              }}
            >
              {currentDepartment?.active ? (
                <p className="text-sm text-white bg-[#50A2A7] px-2 py-1">
                  Add new members
                </p>
              ) : (
                <p className="text-sm text-white bg-red-500 px-2 py-1">
                  Inactive
                </p>
              )}
            </div>
          </div>

          {currentDepartment?.members?.length > 0 && !selectFrom ? (
            <div className="mt-6 empty">
              {currentDepartment?.members?.map((member) => (
                <div
                  key={member?.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="w-full"
                >
                  <div
                    className="border-2 border-[#F5F5F5] min-h-[65px] mb-3 p-2 w-full"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      className="w-fit px-2"
                    >
                      <div className="flex w-[30px] h-[30px] rounded-full mr-2 items-center justify-center bg-[#50A2A7]">
                        <p className="text-xs font-semibold text-white text-center ">
                          {member?.user?.first_name[0]}
                        </p>
                      </div>

                      <div className="w-full flex flex-col">
                        <p
                          style={{ color: "#404B5A", fontSize: "14px" }}
                          className="font-semibold"
                        >
                          {`${member?.user?.first_name} ${member?.user?.last_name}`}
                        </p>

                        <p style={{ color: "#404B5A", fontSize: "12px" }}>
                          {" "}
                          {`Shukran ID: ${member?.user?.shukran_id}`}
                        </p>
                      </div>
                    </div>
                    {currentDepartment?.active ? (
                      <Button
                        style={{ fontSize: "12px" }}
                        className="text-sm text-white px-2 py-1 rounded-sm flex items-center h-[28px] bg-accent"
                        onClick={() => openChangeDepartmentDialog(member)}
                      >
                        <div className="flex items-center gap-2">
                          <span className="text-white">Change</span>
                          <RefreshCcw
                            className=" mr-1"
                            color="white"
                            size={15}
                          />
                        </div>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-6 empty">
              <img src={user} alt="user" />
              <p className="w-40 mt-6 text-center desctxt">
                Department has no members yet.
              </p>
            </div>
          )}
        </div>
        <div className="mt-6 w-full font-semibold">
          <h5>
            All Individuals in {activeEntity.name} (
            {filteredAllMembers?.length || 0})
          </h5>
          {filteredAllMembers?.length > 0 && !selectFrom ? (
            <div className="mt-6 empty">
              {filteredAllMembers?.map((member) => (
                <div
                  key={member?.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="w-full"
                >
                  <div
                    className="border-2 border-[#F5F5F5] min-h-[px] mb-3 p-2 w-full"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      className="w-1/2 px-2"
                    >
                      <div className="flex w-[30px] h-[30px] rounded-full mr-2 items-center justify-center bg-[#50A2A7]">
                        <p className="text-xs font-semibold text-white text-center">
                          {member?.user?.first_name[0]}
                        </p>
                      </div>

                      <div className="w-full flex flex-col">
                        <p
                          style={{ color: "#404B5A", fontSize: "14px" }}
                          className="font-semibold"
                        >
                          {`${member?.user?.first_name} ${member?.user?.last_name}`}
                        </p>

                        <p style={{ color: "#404B5A", fontSize: "12px" }}>
                          {`Shukran ID: ${member?.user?.shukran_id}`}
                        </p>
                        <p style={{ color: "#404B5A", fontSize: "12px" }}>
                          {`Department: ${member?.department?.name}`}
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      className="px-2"
                    >
                      {currentDepartment?.active ? (
                        <p
                          style={{ fontSize: "12px", borderRadius: "10px" }}
                          className="text-sm text-white bg-[#6CCBD1] px-2 py-1"
                          onClick={() => handleAddToGroup(member)}
                        >
                          {isChangingMEmberDepartment &&
                          memberToAdd?.id === member?.id ? (
                            <Loader2 className="h-4 w-4 animate-spin" />
                          ) : (
                            <div className="flex items-center gap-2 cursor-pointer">
                              <span className="text-white">Add</span>
                              <CirclePlus
                                className="h-4 w-4 mr-1"
                                color="white"
                              />
                            </div>
                          )}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {!entityMembers?.data || entityMembers?.data?.length === 0 ? (
                <div className="mt-6 empty">
                  <img src={user} alt="user" />
                  <p className="w-40 mt-6 text-center desctxt">
                    Entity has no individuals yet
                  </p>
                </div>
              ) : (
                <div className="mt-6 empty">
                  <img src={user} alt="user" />
                  <p className="w-40 mt-6 text-center desctxt">
                    All individuals are in this department
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Dialog
        open={changeDepartmentDialogOpen}
        onOpenChange={setChangeDepartmentDialogOpen}
      >
        <DialogContent className="rounded-md">
          <ConfirmChange
            selectedMember={selectedMember}
            entityDepartments={entityDepartments}
            onChangeDepartment={handleChangeDepartment}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
