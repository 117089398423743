import axios, { AxiosInstance, ResponseType } from "axios";

import { BASE_URL } from "../constants/network";
import { getFromSessionStorage } from "../Services/Storage";
import config from "../config/environments";
const useAxios = (responseType?: ResponseType) => {
  const baseURL = config.apiEndpoint;

  let token = getFromSessionStorage("token");
  const $http: AxiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType,
  });
  // $http.interceptors.request.use(
  //   async (config: InternalAxiosRequestConfig) => {
  //     const decodedTkn: DecodedToken = jwtDecode(authStore.token);
  //     const isExpired =
  //       dayjs.unix(decodedTkn.exp).diff(dayjs().second(1800)) < 1;
  //     if (!isExpired) return config;
  //     const refreshResponse = await axios.post(
  //       `${import.meta.env.VITE_BASE_URL}/auth/jwt/refresh/`,
  //       {
  //         refresh: authStore.refreshToken,
  //       }
  //     );
  //     authStore.setToken(refreshResponse.data.access);
  //     config.headers['Authorization'] = `JWT ${refreshResponse.data.access}`;
  //     return config;
  //   },
  //   (error) => Promise.reject(error)
  // );
  return $http;
};

export default useAxios;
