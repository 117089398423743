import { create, StateCreator } from "zustand";
import { createJSONStorage, persist, PersistOptions } from "zustand/middleware";

interface InitialStripePaymentDetailsSlice {
  initialStipeDetails: any;
  setInitialStripeDetails: (details: any) => void;
}

interface InitialMpesaPaymentDetailsSlice {
  initialMpesaDetails: any;
  setInitialMpesaDetails: (details: any) => void;
}
interface MpesaPaymentStateSlice {
  mpesaPaymentState: boolean;
  setMpesaPaymentState: (state: boolean) => void;
}

interface ResetSlice {
  reset: () => void;
}

// Combined type for the store
type TipGroupsStore = InitialStripePaymentDetailsSlice &
  InitialMpesaPaymentDetailsSlice &
  MpesaPaymentStateSlice &
  ResetSlice;

const initialState = {
  initialStipeDetails: null,
  initialMpesaDetails: null,
  mpesaPaymentState: false,
};

const createResetSlice: StateCreator<TipGroupsStore, [], [], ResetSlice> = (
  set
) => ({
  reset: () => {
    localStorage.removeItem("tipgroups-storage");
    set(initialState);
  },
});

const createMpesaPaymentStateSlice: StateCreator<
  TipGroupsStore,
  [],
  [],
  MpesaPaymentStateSlice
> = (set) => ({
  mpesaPaymentState: false,
  setMpesaPaymentState: (state: boolean) => set({ mpesaPaymentState: state }),
});

const createInitialStripePaymentDetailsSlice: StateCreator<
  TipGroupsStore,
  [],
  [],
  InitialStripePaymentDetailsSlice
> = (set) => ({
  initialStipeDetails: null,
  setInitialStripeDetails(details) {
    set({ initialStipeDetails: details });
  },
});

const createInitialMpesaPaymentSlice: StateCreator<
  TipGroupsStore,
  [],
  [],
  InitialMpesaPaymentDetailsSlice
> = (set) => ({
  initialMpesaDetails: null,
  setInitialMpesaDetails: (details: any) =>
    set({ initialMpesaDetails: details }),
});

// Persist configuration
const persistOptions: PersistOptions<TipGroupsStore> = {
  name: "tipgroups-storage",
  storage: createJSONStorage(() => localStorage),
  version: 1,
  partialize: (state: any) => ({
    ...state,
    reset: undefined,
  }),
  onRehydrateStorage: (state) => {
    return (state, error) => {
      if (error) {
        console.log("an error happened during hydration", error);
      }
    };
  },
};

// Create the persisted store
export const useTipGroupsLocalState = create<TipGroupsStore>()(
  persist(
    (...a) => ({
      ...createResetSlice(...a),
      ...createMpesaPaymentStateSlice(...a),
      ...createInitialStripePaymentDetailsSlice(...a),
      ...createInitialMpesaPaymentSlice(...a),
    }),
    persistOptions
  )
);

// Optional: Export a method to clear persisted data
export const clearPersistedTipGroupsState = () => {
  localStorage.removeItem("tipgroups-storage");
};
