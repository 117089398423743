import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import actions from "../../state/index";
import { useForm } from "react-hook-form";
import mapIcon from "../../assets/Icons/google_map_pin.svg";
import clearIcon from "../../assets/Icons/Rating/clear.svg";
import searchIcon from "../../assets/Icons/Rating/search.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import locationIcon from "../../assets/Icons/location.svg";
import clockIcon from "../../assets/Icons/clock.svg";
import savedIcon from "../../assets/Icons/save_icon.svg";
import saveIcon from "../../assets/Icons/Reservations/save.svg";
import starIcon from "../../assets/Icons/Reservations/star.svg";
import forkIcon from "../../assets/Icons/Reservations/fork_spoon.svg";
import historyIcon from "../../assets/Icons/Reservations/history.svg";
import prevIcon from "../../assets/Icons/Reservations/backBut.svg";
import noReservationHistory from "../../assets/Illustrations/ErrorPages/no_reservation_history.svg";
import { searchActions } from "../../state/reducers/search/search.actions";
import AutoComplete from "./Autocomplete";
import { reservationActions } from "../../state/reducers/reservation/reservation.actions";
import { getReservationHistory, searchRestaurant } from "../../state/reducers/user/user.thunk";
import { DUARA_KEY } from "../../helper";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function SearchRestaurant() {
  const [defaultView] = useState(true);
  const [windowSize, setWindowSize] = useState({})
  const inputRef = useRef();
  const [historyView, setHistoryView] = useState(false)
  const [savedView, setSavedView] = useState(false)
  const [recommendedView, setRecommendedView] = useState(false)
  const [searchId, setSearchId] = useState("");
  const [businessError, setBusinessError] = useState("");
  const [place, setPlace] = useState({
    address: 'Fogo gauchi',
    lat: '-1.2641535',
    lng: '36.800827'
  })

  const [autocompleteVal, setAutocompleteVal] = useState()

  const dispatch = useDispatch();
  const {
    watch,
    setValue,
  } = useForm();

  const { addShukranId, addNonShukranId } = bindActionCreators(
    actions,
    dispatch
  );
  const navigate = useNavigate();

  let searchState = useSelector((state) => state.search);
  const userAccount = useSelector((state) => state.account);
  const reservationState = useSelector((state) => state.reservations);
  const nonShukranId = useSelector((state) => state.tipData.nonShukranId);

  useEffect(() => {

    dispatch(getReservationHistory(userAccount.userDetails.token));

  }, []);

  useEffect(() => {
    function handleWindowResize() {
      // setWindowSize(getWindowSize());
      let res = getWindowSize();
      setWindowSize(res);
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize, { passive: false });

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // user input handler
  useEffect(() => {

    return () => { };
  }, [searchState.isLoading]);

  useEffect(() => {
    if (nonShukranId) {
      setValue("phoneNumber", nonShukranId);
    } else if (searchState.searchId) {
      setSearchId(searchState.searchId);
    } else {
    }
    return () => { };
  }, []);

  useEffect(() => {
    if (reservationState.errorMessage.status === 403) {
      navigate("/login");
      dispatch(reservationActions.searchRestaurantError(""));
    }
    return () => { };
  }, [reservationState, dispatch, navigate]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        name === "phoneNumber" &&
        searchState.errorMessage?.msg
      ) {

        dispatch(searchActions.searchLoadError(""));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, searchState, dispatch]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleRestaurantSelect = () => {
    dispatch(reservationActions.updatePlaceDetails(place))
    navigate("/makeReservation");
  };

  const handleHistorySelect = (placeData) => {
    let formatData = {
      address: placeData.google_restaurant.address,
      photo: placeData.google_restaurant.photos[0],
      name: placeData.google_restaurant.name,
      lat: placeData.google_restaurant.latitude,
      lng: placeData.google_restaurant.longitude
    };
    dispatch(reservationActions.updatePlaceDetails(formatData))
    dispatch(searchRestaurant(placeData.google_restaurant.place_id, userAccount.userDetails.token))
    // setTimeout(() => {
      navigate("/makeReservation");
    // }, 1000);
  };

  return (
    <div
      className={`
        ${searchState.isLoading ? "relative" : ""}
        w-full flex flex-col justify-center items-center h-screen
      `}
    >
      <div className="absolute top-0 h-100 z-50 flex flex-col justify-between">
        <div
          className={`flex flex-row items-center absolute top-10 left-3`}
          onClick={() => handleBack()}
        >
          <img src={prevIcon} className="text-primary-pwa drop-shadow-xl" alt="rate-star" />
        </div>
        {
          windowSize.innerWidth && windowSize.innerHeight &&

            <img
              width={windowSize.innerWidth}
              height={windowSize.innerHeight}
              alt="static-place-map"
              style={{
                width: 'auto',
                height: '100vh',
                objectFit: 'cover',
                marginTop: '-2rem'
              }}
              loading="lazy"
              title="google"
              referrerPolicy="no-referrer-when-downgrade"
              src={
                'https://maps.googleapis.com/maps/api/staticmap?' +
                `center=${Number(place.lat) - 0.0025},${place.lng}&` +
                `size=${windowSize.innerWidth}x${windowSize.innerHeight}&` +
                'format=jpg&' +
                'scale=2&' +
                `markers=color:red|${place.lat},${place.lng}|size:small&` +
                'zoom=14&' +
                `key=${DUARA_KEY}`
              }
            >
            </img>

        }
        <div className={`
          w-full rounded-t-lg absolute drop-t-shadow-2xl bg-white h-full
          ${(place.photo||!!reservationState.restaurants?.photos?.length||!historyView)? 'hidden':'top-3/4'}
        `}>
          <div className="flex flex-col px-4 pb-3">
            {reservationState.isHistoryLoading ? (
              <div className="flex flex-col h-full bg-transparent justify-center items-center">
                <img
                  src={loader}
                  alt="loader"
                  className="animate-spin h-8 text-base mt-7 mx-auto"
                />
              </div>
            ) : !!reservationState.reservations?.data?.length ? (
              <div className="pt-1 mb-2 rounded-md">
                {reservationState.reservations?.data?.map((result, idx) => (
                  <div
                    className="flex flex-row items-center mx-2 h-24 border-b-2 border-b-[#B9F5F947]"
                    key={idx}
                    onClick={() => handleHistorySelect(result)}
                  >
                    <img
                      alt="google map"
                      className="w-12 h-12 rounded-lg"
                      src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=200&photo_reference=${result.google_restaurant.photos[0].photo_reference}&key=${DUARA_KEY}`}
                    />
                    <div className="flex flex-col justify-between ml-2 w-2/3 pt-2">
                      <span className="text-lg font-bold leading-tight">
                        {result.google_restaurant.name}
                      </span>
                      {result.google_restaurant.opening_hours.periods[0].hasOwnProperty('close') ? (
                        <span className="text-sm my-2">
                          {/* {result.google_restaurant.opening_hours.periods[0].open.time} - {result.google_restaurant.opening_hours.periods[0]?.close?.time} hrs
                           </span> */}
                          {result.reservation_status}
                        </span>
                      ) : (
                        <span className="text-sm mt-2">

                        </span>
                      )}
                      {/* <span className="text-xs mt-2">
                          reservation status ~  {result.reservation_status}
                          </span> */}
                    </div>
                    <span className="text-xs flex flex-col items-center p-2 absoloute left-2 top-auto">
                      <img
                        src={savedIcon}
                        alt="saved"
                        className={`w-4 h-4`}
                      />{'Save'}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full flex flex-col justify-center items-center">
                <img
                  src={noReservationHistory}
                  alt="no_history"
                  className="w-56 h-44"
                />
                <span className="text-sm text-gray-400 text-center mx-4">Oops! You have no reservation records.
                  Search to make your first one!</span>
              </div>
            )}
          </div>
        </div>
        <div
          className="bg-dark-green w-full text-white fixed bottom-0 flex flex-row py-2 px-4 items-center justify-between"
        >
          <div
            className="flex flex-col items-center cursor-pointer w-1/3"
            onClick={() => {
              setRecommendedView(false)
              setSavedView(false)
              setHistoryView(!historyView)
            }}
          >
            <img
              src={historyIcon}
              alt="no_history"
              className="w-6 h-6"
            />
            <span className={`
              text-sm
              ${historyView? 'text-primary-green':'text-white'}
            `}>History</span>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-1/3"
            onClick={() => {
              setRecommendedView(false)
              setSavedView(!savedView)
              setHistoryView(false)}}
          >
            <img
              src={saveIcon}
              alt="no_history"
              className="w-6 h-6"
            />
            <span className={`
              text-white text-sm
              ${savedView? 'text-primary-green':'text-white'}
            `}>Saved</span>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-1/3"
            onClick={() => {
              setRecommendedView(!recommendedView)
              setSavedView(false)
              setHistoryView(false)
            }}
          >
            <img
              src={starIcon}
              alt="no_history"
              className="w-6 h-6 text-white"
            />
            <span className={`
              text-white text-sm
              ${recommendedView? 'text-primary-green':'text-white'}
            `}>Editor's Picks</span>
          </div>
        </div>
      </div>
      <div className={`
        ${searchState.results.success ? "blur-sm" : ""}
        mx-auto w-full flex flex-col z-50
      `}>
        <div
          className={`
          flex flex-row justify-between relative mx-4
          ${defaultView ? "focus:outline-primary-pwa" : ""}
          `}
        >
          {!place.photo ? (
            <div className="flex justify-center items-center absolute inset-y-0 left-2 pointer-events-none z-50 ">
              <img src={mapIcon} alt="search icon" className="h-8" />
            </div>
          ) : place.photo && place.address ? (
            <></>
            // <div className="flex justify-center items-center absolute inset-y-0 left-3 cursor-pointer z-50" onClick={() => handleBack()}>
            //   <img src={backIcon} alt="Success Icon" className="" />
            // </div>
          ) : (
            ""
          )}
          <div className="w-full">
            <AutoComplete
              setPlace={setPlace}
              place={place}
              inputRef={inputRef}
              isReservation={true}
              setBusinessError={setBusinessError}
              setAutocompleteVal={setAutocompleteVal}
              search
            />
          </div>
          {(place.photo||!!reservationState.restaurants?.photos?.length) ? (
            <div className="flex justify-center items-center absolute inset-y-0 right-3 cursor-pointer z-50 bg-primary-green rounded-r-full">
              <div
                onClick={() => {
                  setPlace({
                    address: 'Fogo gauchi',
                    lat: '-1.2641535',
                    lng: '36.800827'
                  });
                  dispatch(reservationActions.searchRestaurantSuccess({}))
                  inputRef.current.value = ''
                }}
                className="p-4"
              >
                <img src={clearIcon} alt="Clear Icon" className="h-5" />
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center absolute inset-y-0 right-3 cursor-pointer z-50">
              <img src={searchIcon} alt="search icon" className="h-5" />
            </div>
          )}
          <div className="top-16 w-full absolute">
            {
              reservationState.isSearchLoading ? (
                <div className="flex flex-col w-5/6 mx-auto justify-center items-center bg-white p-12">
                  <img
                    src={loader}
                    alt="loader"
                    className="animate-spin h-8 text-base mt-7 mx-auto"
                  />
                </div>
              ) : !!reservationState.restaurants?.photos?.length ? (
                <div
                  className="flex flex-col justify-between h-auto bg-white w-full mx-auto rounded-lg drop-shadow-2xl"
                  onClick={() => handleRestaurantSelect()}
                >
                  <img
                    alt="google map"
                    className="object-cover h-28 w-full rounded-t-lg"
                    src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=200&photo_reference=${reservationState.restaurants.photos[0].photo_reference}&key=${DUARA_KEY}`}
                  />
                  <div className="flex flex-row justify-between items-end p-3">
                    <div className="flex flex-col items-start justify-between w-3/5">
                      <span className="text-lg font-bold">
                        {reservationState.restaurants.name}
                      </span>
                      <span className="text-xs text-primary-pwa flex flex-row items-start">
                        <img
                          src={locationIcon}
                          alt="clock-icon"
                          className={`w-4 h-4 mr-4`}
                        />{reservationState.restaurants.address}
                      </span>
                      {reservationState.restaurants?.opening_hours?.periods[0].hasOwnProperty('close') ? (
                        <div className="text-xs text-primary-pwa flex flex-row items-center">
                          <img
                            src={clockIcon}
                            alt="clock-icon"
                            className={`w-4 h-4 mr-4`}
                          />{reservationState.restaurants.opening_hours.periods[0].open.time} - {reservationState.restaurants.opening_hours.periods[0]?.close?.time} hrs
                        </div>
                      ) : (
                        <span className="text-xs text-primary-pwa pt-2">

                        </span>
                      )}
                    </div>
                    <div className="text-xs flex flex-row items-center p-2 bg-accent rounded-md">
                      <img
                        src={forkIcon}
                        alt="fork-icon"
                        className={`w-3 h-3`}
                      />
                      <div className="ml-2 w-full text-white">{'Make Reservation'}</div>
                    </div>
                  </div>
                </div>
              ) : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
}
