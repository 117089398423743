import React from "react";

import { ArrowLeft, ChevronRight } from "lucide-react";
import { Button } from "../../components-shadcn/ui/button";
import { Card, CardContent } from "../../components-shadcn/ui/card";
import { Avatar } from "../../components-shadcn/ui/avatar";

const ManageAndDistributeTipJarHome = () => {
  return (
    <div className="flex flex-col h-screen bg-white p-4">
      <Button variant="ghost" className="self-start mb-4">
        <ArrowLeft className="h-6 w-6 text-[#64C8CE]" />
      </Button>

      <h1 className="text-2xl font-bold text-[#64C8CE] mb-1">
        Welcome to TipJar
      </h1>
      <p className="text-sm text-gray-500 mb-6">
        Manage members and choose how tips are divided across individuals
      </p>

      <Section title="Departments" count={3} />
      <ListItem
        icon={<div className="w-8 h-8 rounded-full bg-[#64C8CE]" />}
        title="Waiters"
        subtitle="17 members"
        action={
          <Button
            size="sm"
            variant="outline"
            className="text-[#64C8CE] border-[#64C8CE]"
          >
            Edit info
          </Button>
        }
      />

      <Section title="Individuals" count={27} />
      <ListItem
        icon={<Avatar src="https://github.com/shadcn.png" />}
        title="Victoria Chebe"
        subtitle="SRW3457"
      />

      <Section title="Groups" count={3} />
      <ListItem
        icon={
          <div className="w-8 h-8 rounded-full bg-purple-600 flex items-center justify-center text-white font-bold">
            G
          </div>
        }
        title="Gavin's Safari"
        action={
          <Button size="sm" variant="destructive">
            Action needed
          </Button>
        }
      />

      <Button className="mt-auto mb-4 bg-yellow-400 hover:bg-yellow-500 text-white">
        PAY OUT TIPS
      </Button>
    </div>
  );
};

const Section = ({ title, count }) => (
  <div className="flex justify-between items-center mb-4">
    <h2 className="text-lg font-semibold">
      {title} ({count})
    </h2>
    <Button variant="ghost" className="text-[#64C8CE]">
      View all <ChevronRight className="ml-1 h-4 w-4" />
    </Button>
  </div>
);

const ListItem = ({ icon, title, subtitle, action }) => (
  <Card className="mb-4">
    <CardContent className="flex items-center p-4">
      {icon}
      <div className="ml-3 flex-grow">
        <p className="font-medium">{title}</p>
        {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
      </div>
      {action}
    </CardContent>
  </Card>
);

export default ManageAndDistributeTipJarHome;
