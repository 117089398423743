import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components-shadcn/ui/select";

const SelectCurrency = () => {
  const { setSelectedCurency } = useTipJarLocalState();
  const [currency, setCurrency] = useState("KES");
  useEffect(() => {
    setSelectedCurency(currency);
  }, [currency]);

  return (
    <Select value={currency} onValueChange={setCurrency}>
      <SelectTrigger className="w-[100px] mb-4">
        <SelectValue placeholder="Currency" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="KES">KES</SelectItem>
        <SelectItem value="USD">USD</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default SelectCurrency;
