import React, { useEffect, useState } from "react";
import logo from "../../assets/Logos/ShukranIcon.png";
import loader from "../../assets/Icons/Registration/loader.svg";
import { useNavigate } from "react-router-dom";
import cross from "../../assets/Icons/Rating/not_found.svg";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import {
  generateTokenOtp,
  sendTip,
  verifyTokenOtp,
} from "../../state/reducers/user/user.thunk";
import { useForm } from "react-hook-form";
import { balanceActions } from "../../state/reducers/user/user.actions";
import useIsIOS from "../popups/useIsIos";

export default function MpesaNoOtp() {
  const [isIOS, setIsIOS] = useIsIOS();
  const [phoneNumber, setPhoneNumber] = useState();
  // navigate function
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginUser } = bindActionCreators(actions, dispatch);

  const userDetails = useSelector((state) => state.account.userDetails);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const balanceState = useSelector((state) => state.balance);
  const tipData = useSelector((state) => state.tipData);
  const senderData = useSelector((state) => state.account.userDetails);

  let receiverShukranId = tipData.shukranId;
  let amount = tipData.tipAmount;
  let nonShukranId = tipData.nonShukranId;
  let queryShukranId = tipData.queryShukranId;
  let queryName = tipData.queryName;

  const tippingState = useSelector((state) => state.tipping);

  function resendCode() {
    let params = {
      phone: `${userDetails.phoneNumber}`,
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
    };
    setValue("otpCode", "");
    dispatch(generateTokenOtp(params));
  }

  // set token to local storage

  useEffect(() => {
    // localStorage.setItem("confirmCode", "pre-confirm");
    return () => {};
  }, []);

  useEffect(() => {
    return () => {
      dispatch(balanceActions.generateTokenSuccess([]));
      dispatch(balanceActions.verifyTokenSuccess({}));
      dispatch(balanceActions.generateTokenError(""));
      dispatch(balanceActions.verifyTokenError(""));
    };
  }, [dispatch]);

  const handleFormSubmit = (data) => {
    if (data.otpCode) {
      let params = {
        otp_code: data.otpCode,
        phone: userDetails?.phoneNumber ? userDetails?.phoneNumber : "0",
      };
      dispatch(verifyTokenOtp(params));
    }
  };

  useEffect(() => {
    if (balanceState.errorMessage.msg) {
      setTimeout(() => {
        dispatch(balanceActions.verifyTokenError(""));
        dispatch(balanceActions.generateTokenError(""));
      }, 4000);
    }
    if (balanceState.verifyResponse.success) {
      setTimeout(() => {
        // setToken(balanceState.verifyResponse.data);
        loginUser(balanceState.verifyResponse.data);
        dispatch(balanceActions.generateTokenSuccess([]));
        dispatch(balanceActions.verifyTokenSuccess([]));
        if (queryShukranId) {
          navigate("/paymentOpt");
        } else {
          // navigate("/search");
        }
      }, 3000);
    }
    if (balanceState.generateResponse.success) {
      setTimeout(() => {
        dispatch(balanceActions.generateTokenSuccess([]));
        dispatch(balanceActions.verifyTokenSuccess([]));
      }, 3000);
    }
    return () => {};
  }, [
    balanceState.errorMessage,
    balanceState.generateResponse,
    balanceState.verifyResponse,
    dispatch,
    loginUser,
    queryShukranId,
    navigate,
  ]);

  const submitTip = () => {
    let payload = {
      amount: tipData.tipAmount,
      tipper_phone_number: phoneNumber ? phoneNumber : "",
    };
    localStorage.setItem("token", senderData.token);
    if (phoneNumber && nonShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: nonShukranId,
        method: "mpesa",
      };
      dispatch(sendTip(payload, senderData.token));
      setPhoneNumber("");
    } else if (phoneNumber && receiverShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: receiverShukranId,
        method: "mpesa",
      };
      dispatch(sendTip(payload, senderData.token));
      setPhoneNumber("");
    } else if (phoneNumber && queryShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: queryShukranId,
        method: "mpesa",
      };
      dispatch(sendTip(payload, senderData.token));
      setPhoneNumber("");
    } else {
    }
  };

  const ConfirmTipPopup = () => (
    <div className="shadow py-8 px-6 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-base">Confirm Tip</span>
        <div
          onClick={() => {
            setPhoneNumber("");
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-fit">
        <label className="text-subtle text-sm">Tip Recipient</label>
        <div className="flex flex-row justify-between">
          {nonShukranId ? (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">
                {tipData.nonShukranId}
              </span>
            </div>
          ) : queryShukranId ? (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">{queryName}</span>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">
                {tipData.receiverName + " " + tipData.receiverLastName}
              </span>
              <span className="text-sm text-gray-300">{tipData.shukranId}</span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Tip Amount</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">Ksh {tipData.tipAmount}</span>
          </div>
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Payment Method</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">{"MPESA"}</span>
          </div>
        </div>
      </div>

      <div className="mt-6">
        {!tippingState.tippingResponse.success && (
          <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            onClick={submitTip}
          >
            Send Tip
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={`
      ${balanceState.errorMessage.error ? "relative" : ""}
      ${balanceState.isLoading ? "relative" : ""}
      ${balanceState.generateResponse.success ? "relative" : ""}
      ${balanceState.verifyResponse.success ? "relative" : ""}
      ${balanceState.errorMessage.msg ? "relative" : ""}
      w-full flex flex-col
  `}
    >
      <div
        className={`${balanceState.errorMessage.error ? "blur-sm" : ""}
        ${balanceState.generateResponse.success ? "blur-sm" : ""}
        ${balanceState.verifyResponse.success ? "blur-sm" : ""}
        ${balanceState.errorMessage.msg ? "blur-sm" : ""}
        ${
          balanceState.isLoading ? "blur-sm" : ""
        } flex flex-col items-center h-full mt-40 px-6 w-full`}
      >
        <img src={logo} className="size mx-auto mb-7 h-10" alt="logo" />
        <h3 className="text-lg text-black mb-4 text-center">
          {" "}
          Confirm your phone number
        </h3>
        <p className="text-base mb-4 text-black text-center">
          Enter sms verification code sent to 07********
        </p>

        <div className="flex flex-row justify-between pt-1 w-fit">
          <form className="flex flex-col">
            <input
              className="border-solid border border-[#95BD75] rounded-md focus:border-[#50A2A7] placeholder:text-base text-black outline-none text-xl h-12 placeholder:text-center w-20 mx-auto px-3"
              type="number"
              placeholder="XXXX"
              {...register("otpCode", {
                required: true,
                maxLength: 4,
              })}
            />
            {errors.otpCode?.type === "maxLength" && (
              <p className="text-red-600 text-sm text-center">
                OTP code has to be 4 numbers
              </p>
            )}
            {errors.otpCode?.type === "required" && (
              <p className="text-red-600 text-sm text-center">
                OTP code required
              </p>
            )}
            <button
              className="rounded-full bg-[#F2C773] p-2 text-white text-base w-full mx-auto mt-8"
              type="submit" // onClick={() => handleFormSubmit(e)}
              onClick={handleSubmit(handleFormSubmit)}
            >
              SUBMIT OTP CODE
            </button>
          </form>
        </div>
        <div className="mt-2 pt-4">
          {balanceState.isLoading && (
            <span className="flex">
              <img src={loader} alt="Loader" className="rounded animate-spin" />
              <p className="pl-3"> Confirming code</p>
            </span>
          )}
        </div>
        <p className="pt-4 flex flex-row mx-auto">
          <span className="text-primary">Didn't receive code? </span>
          <button
            className="border-none underline uppercase text-primary-pwa ml-3"
            onClick={(e) => {
              resendCode();
            }}
          >
            RESEND CODE
          </button>
        </p>
      </div>
      <div
        className={`${
          balanceState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          balanceState.generateResponse.success
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="p-4 bg-white pt-10 w-4/5 drop-shadow-xl flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              dispatch(balanceActions.generateTokenSuccess([]));
            }}
          >
            X
          </button>
          <img src={checkCircle} alt="error-img" className="h-12" />
          <h2
            className={
              balanceState.generateResponse.success
                ? "text-center font-bold	text-lg"
                : "hidden"
            }
          >
            Success
          </h2>
          <p
            className={
              balanceState.generateResponse.msg
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {balanceState.generateResponse.msg}
          </p>
        </div>
      </div>
      <div
        className={`${
          balanceState.verifyResponse.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          balanceState.errorMessage.msg ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="p-4 bg-white pt-10 w-4/5 drop-shadow-xl flex flex-col relative pb-4 rounded-3xl z-10">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              dispatch(balanceActions.generateTokenError(""));
              dispatch(balanceActions.verifyTokenError(""));
            }}
          >
            X
          </button>
          <img src={cross} alt="error-img" className="h-12" />
          <h2
            className={
              balanceState.errorMessage.msg
                ? "text-center font-bold	text-lg"
                : "hidden"
            }
          >
            Fail
          </h2>
          <p
            className={
              balanceState.errorMessage.msg
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {balanceState.errorMessage.msg}
          </p>
        </div>
      </div>
    </div>
  );
}
