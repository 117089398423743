export function constructApiUrl(
  base: string,
  params?: { [key: string]: string | number | boolean }
): string {
  let url = base;

  if (params) {
    const queryParams = Object.entries(params)
      .filter(
        ([, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParams) {
      url += `?${queryParams}`;
    }
  }

  return url;
}
