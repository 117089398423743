import axios from "axios";
import { getFromSessionStorage } from "./Services/Storage";
import { testUrl } from "./helper";
import moment from "moment";

export function post(path, body) {
  let token = getFromSessionStorage("token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .post(`${testUrl}${path}`, body)
    .then(function (response) {
      return response;
    })
    .then(function (data) {
      return data;
    });
}

function get(path) {
  return fetch(`${testUrl}${path}`, {
    credentials: "omit",
    headers: {
      "content-type": "application/json;charset=UTF-8",
      "sec-fetch-mode": "cors",
    },
    method: "GET",
    mode: "cors",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data;
    });
}

const http = {
  post: post,
  get: get,
};

export default http;

export const convertToAmPm = (hr, min) => {
  let amTimes = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
  ];
  let pmTimes = [
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  let shortTimes = [
    "12",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
  ];
  let convertedTime = "";
  if (amTimes.includes(hr)) {
    convertedTime = shortTimes[amTimes.indexOf(hr)] + ":" + min + " am";
  } else {
    convertedTime = shortTimes[pmTimes.indexOf(hr)] + ":" + min + " pm";
  }
  return convertedTime;
};

export const phoneLines = [
  "0100",
  "0101",
  "0102",
  "0103",
  "0104",
  "0105",
  "0106",
  "0107",
  "0108",
  "0109",
  "0110",
  "0111",
  "0112",
  "0113",
  "0114",
  "0115",
  "0116",
  "0117",
  "0118",
  "0119",
  "0700",
  "0701",
  "0702",
  "0703",
  "0704",
  "0705",
  "0706",
  "0707",
  "0708",
  "0709",
  "0710",
  "0711",
  "0712",
  "0713",
  "0714",
  "0715",
  "0716",
  "0717",
  "0718",
  "0719",
  "0720",
  "0721",
  "0722",
  "0723",
  "0724",
  "0725",
  "0726",
  "0727",
  "0728",
  "0729",
  "0730",
  "0731",
  "0732",
  "0733",
  "0734",
  "0735",
  "0736",
  "0737",
  "0738",
  "0739",
  "0740",
  "0741",
  "0742",
  "0743",
  "0744",
  "0745",
  "0746",
  "0747",
  "0748",
  "0750",
  "0751",
  "0752",
  "0753",
  "0754",
  "0755",
  "0756",
  "0757",
  "0758",
  "0759",
  "0760",
  "0761",
  "0762",
  "0763",
  "0764",
  "0765",
  "0766",
  "0767",
  "0768",
  "0769",
  "0770",
  "0771",
  "0772",
  "0773",
  "0774",
  "0775",
  "0776",
  "0777",
  "0778",
  "0779",
  "0780",
  "0781",
  "0782",
  "0783",
  "0784",
  "0785",
  "0786",
  "0787",
  "0788",
  "0789",
  "0790",
  "0791",
  "0792",
  "0793",
  "0794",
  "0795",
  "0796",
  "0797",
  "0798",
  "0799",
];

export function formatTransactions(stateArr) {
  const appendAdditionOperator = (amount) => {
    return "+ Ksh" + amount;
  };

  const appendSubtractionOperator = (amount) => {
    return "- Ksh" + amount;
  };
  const parseType = (type) => {
    let newType = type?.split("_");
    newType = newType.map((type, idx) => {
      return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
    });
    return newType.join(" ");
  };

  let arr = stateArr
    ? stateArr.map((trans) => {
        let dataFormat = {
          id: trans.id,
          amount: trans.amount,
          type: trans.type,
          date: moment(trans.created_at).format("D/M/Y"),
          status: trans.status,
          sender_name: trans.receiver_first_name,
          sender_id: trans.receiver_shukran_id,
          tip_type: trans.tip_type,
        };

        if (trans.type === "Tip") {
          dataFormat = {
            ...dataFormat,
            amount: appendSubtractionOperator(trans.amount),
            sender_name: trans.sender_first_name,
            receiver_shukran_id: trans.receiver_shukran_id,
          };
          return dataFormat;
        }

        if (trans.type === "sacco_tip") {
          dataFormat = {
            ...dataFormat,
            amount: appendSubtractionOperator(trans.amount),
            sender_name: trans.sender_first_name,
            sender_id: trans.recipient_id,
          };
          return dataFormat;
        }

        if (
          trans.type === "pay_membership_fee" ||
          trans.type === "pay_share_capital" ||
          trans.type === "contribution"
        ) {
          dataFormat = {
            ...dataFormat,
            sender_name: parseType(trans.type),
          };
          return dataFormat;
        }
        if (trans.type === "access_transfer") {
          dataFormat = {
            ...dataFormat,
            sender_name: parseType(trans.type),
            amount: appendSubtractionOperator(trans.amount),
          };
          return dataFormat;
        }
        if (trans.type === "Credit") {
          dataFormat = {
            ...dataFormat,
            amount: appendAdditionOperator(trans.amount),
            sender_name: "Deposit",
          };
          return dataFormat;
        }

        if (trans.type === "Debit") {
          dataFormat = {
            ...dataFormat,
            amount: appendSubtractionOperator(trans.amount),
            sender_name: "Withdrawal",
          };
          return dataFormat;
        }

        return dataFormat;
      })
    : [];
  const ids = arr.map((o) => o.id);
  arr = arr.filter(({ id }, index) => !ids.includes(id, index + 1));
  arr.sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );
  return arr;
}
