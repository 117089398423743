import React, { useEffect } from "react";
import { Button } from "../../../components-shadcn/ui/button";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import { Card, CardContent } from "../../../components-shadcn/ui/card";
import { useNavigate } from "react-router-dom";
import BackButton from "../../globals/BackButton";
import { useGetJars } from "../../TipGroups/state/server-state/useTipGroupsServerState";

const TeamCard = ({ name, disbursement_points, id, members }) => {
  return (
    <Card className="mb-4">
      <CardContent className="flex items-center justify-between p-4">
        <div className="flex items-center">
          <div className="w-10 h-10 rounded-full bg-gradient-to-b from-yellow-300 to-teal-300 mr-3"></div>
          <div>
            <p className="font-medium">{name}</p>
            <p className="text-sm text-gray-400">{members} members</p>
          </div>
        </div>
        <p className="text-[#64C8CE] font-medium">
          {disbursement_points} points
        </p>
      </CardContent>
    </Card>
  );
};

const ConfirmDistributionPoints = () => {
  const { activeEntityJAR, setActiveEntityJAR, activeEntity } =
    useTipJarLocalState();
  const navigate = useNavigate();

  const {
    data: entityJars,
    isLoading: isLoadingEntityJars,
    error: fetchEntityJarError,
    refetch,
  } = useGetJars(activeEntity?.id);

  if (entityJars === 403 || !activeEntityJAR || !activeEntityJAR.id) {
    navigate("/login/");
  }

  useEffect(() => {
    if (entityJars && entityJars.length > 0) {
      const currentEntityJAR = entityJars?.find(
        (el) => el.id === activeEntityJAR?.id
      );
      setActiveEntityJAR(currentEntityJAR);
    }
  }, [activeEntityJAR?.id, entityJars, setActiveEntityJAR]);

  const handleBack = () => {
    navigate(-1);
  };

  const navigateToChangePoints = () => {
    navigate("/tipjar/change-distribution-points");
  };

  // Count members for each group
  const groupMemberCounts = activeEntityJAR?.groups?.reduce((acc, group) => {
    acc[group.id] = activeEntityJAR.members.filter(
      (member) => member.group === group.id
    ).length;
    return acc;
  }, {});

  return (
    <div className="flex flex-col p-2 w-full h-screen ">
      <div className="w-full p-0">
        <BackButton onPress={handleBack} />
      </div>
      <h1 className="text-2xl font-bold mb-2 text-[#64C8CE] mt-6">
        Confirm group points distribution
      </h1>
      <p className="text-gray-400 mb-6">
        Change teams distribution for TipJar amount of KES.{" "}
        {activeEntityJAR?.jar_balance?.toLocaleString() || 0}
      </p>

      <h2 className="text-lg font-semibold mb-4">
        Teams ({activeEntityJAR?.groups?.length})
      </h2>

      {activeEntityJAR?.groups?.map((group) => (
        <TeamCard
          key={group.id}
          name={group.name}
          disbursement_points={group.disbursement_points}
          id={group.id}
          members={groupMemberCounts[group.id]}
        />
      ))}

      <div className="mt-auto mb-7 pb-2">
        <Button
          variant="outline"
          className="w-full mb-4 text-[#F2C773] border-[#F2C773] rounded-full h-[60px]"
          onClick={navigateToChangePoints}
        >
          CHANGE DISTRIBUTION POINTS
        </Button>
        <Button
          className="w-full bg-[#F2C773] hover:bg-yellow-500 text-white rounded-full h-[60px]"
          onClick={() => navigate(`/tipjar/confirm-tip-recepients`)}
        >
          PROCEED
        </Button>
      </div>
    </div>
  );
};

export default ConfirmDistributionPoints;
