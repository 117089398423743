import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components-shadcn/ui/button";
import { X } from "lucide-react";
import BackButton from "../../globals/BackButton";
import AddMember from "../../Members/AddMember";
import Confirm from "../../Members/Confirm";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../components-shadcn/ui/dialog";

const AddMembersToEntity = () => {
  const [addNewMember, setAddNewMember] = useState(true);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    // Check all relevant localStorage items
    const membersL = localStorage.getItem("members");
    const expressMembersL = localStorage.getItem("express_members");
    const departmentsL = localStorage.getItem("memberDepartments");

    const members = JSON.parse(membersL);
    const expressMembers = expressMembersL
      ? JSON.parse(expressMembersL).express_members
      : [];
    const departments = JSON.parse(departmentsL);

    // Show modal if any type of members or departments exist
    if (
      (members && members.length > 0) ||
      (expressMembers && expressMembers.length > 0) ||
      (departments && Object.keys(departments).length > 0)
    ) {
      setShowDiscardModal(true);
    } else {
      navigate(-1);
    }
  };

  const handleDiscardChanges = () => {
    // Clean up all localStorage items
    localStorage.removeItem("members");
    localStorage.removeItem("express_members");
    localStorage.removeItem("memberDepartments");
    setShowDiscardModal(false);
    navigate(-1);
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
        </div>
      </div>

      {addNewMember ? (
        <AddMember setAddNewMember={setAddNewMember} />
      ) : (
        <Confirm
          setAddNewMember={setAddNewMember}
          addNewMember={addNewMember}
        />
      )}

      <Dialog open={showDiscardModal} onOpenChange={setShowDiscardModal}>
        <DialogContent className="sm:max-w-[425px] rounded-lg">
          <DialogHeader>
            <DialogTitle className="text-center text-lg font-semibold text-[#50A2A7]">
              Unsaved Changes
            </DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-center text-gray-600">
              You have unsaved members. Are you sure you want to discard all
              changes?
            </p>
          </div>
          <DialogFooter className="flex flex-row justify-between gap-4 sm:justify-between">
            <Button
              type="button"
              variant="outline"
              className="w-fit rounded-md h-[60px] bg-[#F2C773] hover:bg-[#e0b665] text-white font-semibold rounded-none flex items-center h-[28px]"
              onClick={() => setShowDiscardModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="w-fit rounded-md h-[60px] bg-red-500 hover:bg-red-400  text-white font-semibold rounded-none flex items-center h-[28px]"
              onClick={handleDiscardChanges}
            >
              Discard Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AddMembersToEntity;
