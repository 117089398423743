// Environment types
const ENV_TYPES = {
  PRODUCTION: "production",
  STAGING: "staging",
  DEVELOPMENT: "development",
};

// API Endpoints
const API_ENDPOINTS = {
  [ENV_TYPES.PRODUCTION]: "https://api.shukran.co/",
  [ENV_TYPES.STAGING]: "https://staging.shukran.co/",
  [ENV_TYPES.DEVELOPMENT]: "https://staging.shukran.co/", // Default to staging for development
};

const QRCODE_ENDPOINTS = {
  [ENV_TYPES.PRODUCTION]: "https://app.shukran.co/",
  [ENV_TYPES.STAGING]: "https://beta.shukran.co/",
  [ENV_TYPES.DEVELOPMENT]: "https://beta.shukran.co/", // Default to staging for development
};

const STRIPE_API_KEYS = {
  [ENV_TYPES.PRODUCTION]: process.env.REACT_APP_STRIPE_PRODUCTION_KEY,
  [ENV_TYPES.STAGING]: process.env.REACT_APP_STRIPE_TEST_KEY,
  [ENV_TYPES.DEVELOPMENT]: process.env.REACT_APP_STRIPE_TEST_KEY, // Default to staging for development
};
/**
 * Determines the current environment based on environment variables and URL
 * @returns {string} The current environment type
 */
const getCurrentEnvironment = () => {
  // Check if running in development
  if (process.env.NODE_ENV === "development") {
    return ENV_TYPES.DEVELOPMENT;
  }

  // Check hostname to determine environment
  const hostname = window.location.hostname;

  if (hostname.includes("beta") || hostname.includes("beta.shukran")) {
    return ENV_TYPES.STAGING;
  }

  // Production domains
  if (
    hostname.includes("app.shukran") ||
    hostname.includes("app") ||
    hostname === "app.shukran.co"
  ) {
    return ENV_TYPES.PRODUCTION;
  }

  // Default to staging if environment cannot be determined
  return ENV_TYPES.STAGING;
};

/**
 * Gets the appropriate API endpoint for the current environment
 * @returns {string} The API endpoint URL
 */
export const getApiEndpoint = () => {
  const environment = getCurrentEnvironment();
  return API_ENDPOINTS[environment];
};

export const getQrCodeEndpoint = () => {
  const environment = getCurrentEnvironment();
  return QRCODE_ENDPOINTS[environment];
};

const getStripeKey = () => {
  console.log("STRIPE API KEY CALLED --> ", process.env);
  const environment = getCurrentEnvironment();
  return STRIPE_API_KEYS[environment];
};

/**
 * Environment configuration object
 */
const config = {
  apiEndpoint: getApiEndpoint(),
  qrCodeEndpoint: getQrCodeEndpoint(),
  environment: getCurrentEnvironment(),
  stripeKeys: getStripeKey(),
  isProduction: getCurrentEnvironment() === ENV_TYPES.PRODUCTION,
  isStaging: getCurrentEnvironment() === ENV_TYPES.STAGING,
  isDevelopment: getCurrentEnvironment() === ENV_TYPES.DEVELOPMENT,
};

export default config;
