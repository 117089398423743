import React, { useState, useEffect, useCallback, useRef } from "react";
import { Input } from "../../../components-shadcn/ui/input";
import { Button } from "../../../components-shadcn/ui/button";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import BackButton from "../../globals/BackButton";
import { useUpdateDistributionPoints } from "../state/server-state/useTipJarServerState";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import { ReloadIcon } from "@radix-ui/react-icons";

const ChangeDistributionPoints = () => {
  const { activeEntityJAR } = useTipJarLocalState();
  const updateGroupDistributionPoints = useUpdateDistributionPoints();
  const isUpdatingGroupDistributionPoints =
    updateGroupDistributionPoints.isLoading;
  const navigate = useNavigate();

  const [groups, setGroups] = useState([]);
  const timeoutRef = useRef({});

  useEffect(() => {
    if (activeEntityJAR && activeEntityJAR.groups) {
      setGroups(
        activeEntityJAR.groups.map((group) => ({
          id: group.id,
          name: group.name,
          points: group.disbursement_points,
        }))
      );
    }
  }, [activeEntityJAR]);

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      Object.values(timeoutRef.current).forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    };
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  // Custom debounce function for updates
  const debounceUpdate = useCallback(
    (groupId, updateData) => {
      if (timeoutRef.current[groupId]) {
        clearTimeout(timeoutRef.current[groupId]);
      }

      timeoutRef.current[groupId] = setTimeout(async () => {
        try {
          await updateGroupDistributionPoints.mutateAsync(updateData);
          toast.success(`Updated ${updateData.name}`);
        } catch (error) {
          toast.error(`Failed to update ${updateData.name}`);
        }
        delete timeoutRef.current[groupId];
      }, 2500);
    },
    [updateGroupDistributionPoints]
  );

  const findGroupById = (groupId) => {
    return groups.findIndex((group) => group.id === groupId);
  };

  const handlePointsChange = (groupId, value) => {
    const index = findGroupById(groupId);
    if (index === -1) return;

    const points = Math.min(Math.max(0, parseInt(value) || 0), 10);
    const newGroups = [...groups];
    newGroups[index].points = points;
    setGroups(newGroups);

    const updateData = {
      name: newGroups[index].name,
      disbursement_points: points.toString(),
      groupId: groupId,
    };

    debounceUpdate(groupId, updateData);
  };

  const adjustPoints = (groupId, amount) => {
    const index = findGroupById(groupId);
    if (index === -1) return;

    const newGroups = [...groups];
    const newPoints = Math.min(
      Math.max(0, newGroups[index].points + amount),
      10
    );
    newGroups[index].points = newPoints;
    setGroups(newGroups);

    const updateData = {
      name: newGroups[index].name,
      disbursement_points: newPoints.toString(),
      groupId: groupId,
    };

    debounceUpdate(groupId, updateData);
  };

  return (
    <div className="flex flex-col p-2 w-full h-screen">
      <div className="w-full p-0">
        <BackButton onPress={handleBack} />
      </div>

      <h1 className="text-2xl font-bold mb-2 text-[#64C8CE] mt-6">
        Group points distribution
      </h1>
      <p className="text-sm text-gray-500 mb-6">
        Change distribution points allocations
      </p>

      {groups.map((group) => (
        <div key={group.id} className="mb-6">
          <div className="flex gap-2 mb-2">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Department
              </label>
              <Input
                value={group.name}
                readOnly
                className="w-full border-[#C1F2F5] shadow-md h-[55px]"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Distribution points
              </label>
              <Input
                type="number"
                value={group.points}
                onChange={(e) => handlePointsChange(group.id, e.target.value)}
                min="0"
                max="10"
                className="w-full border-[#C1F2F5] shadow-md h-[55px]"
              />
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <Button
              onClick={() => adjustPoints(group.id, -1)}
              className="bg-[#F2C773] hover:bg-[#e0b665] text-white font-bold py-2 px-4 rounded shadow-md"
              disabled={group.points <= 0}
            >
              -
            </Button>
            <Button
              onClick={() => adjustPoints(group.id, 1)}
              className="bg-[#F2C773] hover:bg-[#e0b665] text-white font-bold py-2 px-4 rounded shadow-md"
              disabled={group.points >= 10}
            >
              +
            </Button>
          </div>
        </div>
      ))}

      <div className="w-full p-2">
        <Button
          className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white font-bold py-3 px-4 rounded-full shadow-md mt-6 h-[60px] mt-7 mb-3"
          onClick={handleBack}
          disabled={isUpdatingGroupDistributionPoints}
        >
          {isUpdatingGroupDistributionPoints ? (
            <>
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              UPDATING...
            </>
          ) : (
            "CONFIRM AND GO BACK"
          )}
        </Button>
      </div>
    </div>
  );
};

export default ChangeDistributionPoints;
